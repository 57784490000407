import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_error_block = _resolveComponent("c-error-block")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_c_card_list = _resolveComponent("c-card-list")!

  return (_openBlock(), _createBlock(_component_c_card_list, null, {
    default: _withCtx(() => [
      (_ctx.error)
        ? (_openBlock(), _createBlock(_component_c_error_block, {
            key: 0,
            message: _ctx.error,
            onReload: _ctx.loadCarInfo
          }, null, 8, ["message", "onReload"]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_ion_item, {
              button: "",
              class: "color-default",
              onClick: _ctx.toUserEdit
            }, {
              default: _withCtx(() => [
                (_ctx.loading)
                  ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                      key: 0,
                      animated: "",
                      style: {
          width: '130px',
          height: '1em',
        }
                    }))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(_ctx.car?.ownerName || _ctx.$t('components.client_info_card.client_name_empty')), 1)
                    ], 64))
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_ion_item, {
              button: "",
              class: "color-default",
              onClick: _ctx.toCarHistory
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('components.client_info_card.item_car_history')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('components.client_info_card.item_client_phone')), 1),
                _createVNode(_component_ion_note, {
                  slot: "end",
                  color: "light"
                }, {
                  default: _withCtx(() => [
                    (_ctx.loading)
                      ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                          key: 0,
                          animated: "",
                          style: {
            width: '100px',
            height: '1em',
          }
                        }))
                      : (_ctx.phoneLink)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 1,
                            class: "cursor-pointer reset-link",
                            href: _ctx.phoneLink
                          }, _toDisplayString(_ctx.phoneFormat), 9, _hoisted_1))
                        : (_openBlock(), _createElementBlock("span", {
                            key: 2,
                            class: "cursor-pointer user-select-none",
                            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toUserEdit && _ctx.toUserEdit(...args)))
                          }, _toDisplayString(_ctx.$t('components.client_info_card.client_phone_empty')), 1))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_ctx.showGroup)
              ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('components.client_info_card.item_group')), 1),
                    _createVNode(_component_ion_note, {
                      class: "cursor-pointer user-select-none",
                      slot: "end",
                      color: "light",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('choice-group')))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.group ? _ctx.group.name : 'Добавить'), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.loading)
              ? (_openBlock(), _createBlock(_component_ion_item, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_skeleton_text, {
                      animated: "",
                      style: {
          width: '150px',
          height: '1em',
        }
                    })
                  ]),
                  _: 1
                }))
              : (_ctx.car?.comment)
                ? (_openBlock(), _createBlock(_component_ion_item, { key: 2 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.car?.comment), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
          ], 64))
    ]),
    _: 1
  }))
}