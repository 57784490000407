import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_modal_action_header = _resolveComponent("c-modal-action-header")!
  const _component_c_modal_select_option = _resolveComponent("c-modal-select-option")!
  const _component_c_modal_select = _resolveComponent("c-modal-select")!
  const _component_c_content_loading = _resolveComponent("c-content-loading")!
  const _component_c_modal_content = _resolveComponent("c-modal-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_c_modal_action_header, { tag: "ion-header" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('components.add_category_modal.view_point_type_title')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_c_modal_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_c_content_loading, { action: _ctx.loadPointTypes }, {
          default: _withCtx(() => [
            _createVNode(_component_c_modal_select, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pointTypes, (pointTypeItem) => {
                  return (_openBlock(), _createBlock(_component_c_modal_select_option, {
                    key: pointTypeItem.id,
                    selected: !!_ctx.pointTypeSelectedList[pointTypeItem.id],
                    onClick: ($event: any) => (_ctx.selectPointType(pointTypeItem, $event))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(pointTypeItem.name), 1)
                    ]),
                    _: 2
                  }, 1032, ["selected", "onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["action"])
      ]),
      _: 1
    })
  ], 64))
}