import { BaseStore, BaseStoreContext } from "./BaseStore"
import { SuspectConfigItem } from "@/repositories/Models/Suspect"
import { reactive, readonly, UnwrapRef, DeepReadonly } from "vue"
import { PointStore } from "./PointStore"
import { keyBy } from "lodash"

export type SuspectsAssocItems = {
  [suspectName: string]: SuspectConfigItem;
};

export interface SuspectStoreState {
  points: {
    [pointId: number]: SuspectsAssocItems;
  };
}

export interface SuspectStoreContext extends BaseStoreContext {
  pointStore: PointStore;
}

export class SuspectStore extends BaseStore<SuspectStoreContext> {
  protected state: UnwrapRef<SuspectStoreState>;

  constructor(ctx: SuspectStoreContext) {
    super(ctx);

    this.state = reactive({
      points: {},
    });
  }

  get point() {
    return this.ctx.pointStore;
  }

  /**
   * Получить список конфигурации о подозрительных действиях.
   * 
   * CACHE: Данные автоматически кэшируются, и запрашиваются по
   * требованию только первый раз после запуска приложения
   * 
   * @throws
   * 
   * @param clientPointId идентификтаор пункта обслуживания
   * @returns 
   */
  async getConfig(clientPointId: number): Promise<DeepReadonly<SuspectsAssocItems>> {    
    if (this.state.points[clientPointId]) {
      return readonly(this.state.points[clientPointId]);
    }

    const cacheRelevantMS = 0;
    const key = ['suspect_config', clientPointId];

    const cache = await this.cacheQuery(key, async () => {
      const { data } = await this.repositories.suspect.getConfig({
        clientPointId,
        limit: 1000,
      });

      return data.items;
    }, cacheRelevantMS);

    this.state.points[clientPointId] = keyBy(cache.data, 'name');

    return readonly(this.state.points[clientPointId]);
  }

  /**
   * Получить список конфигурации для текущего пользователя
   * 
   * @returns 
   */
  async getThisPointConfig(): Promise<DeepReadonly<SuspectsAssocItems>> {
    const thisPoint = await this.point.getThisClient();
    return await this.getConfig(thisPoint.id);
  }

  /**
   * Вернет идентификатор suspect настройки или undefined,
   * если она не найдена, либо значение == false
   * 
   * @param name 
   * @param pointId 
   * @returns 
   */
  async getSuspectId(name: string, pointId?: number): Promise<SuspectConfigItem["id"]|undefined> {
    if (!pointId) { // Если не передан, берем у текущего
      const thisPoint = await this.point.getThisClient();
      pointId = thisPoint.id;
    }
    
    const suspects = await this.getConfig(pointId);
    const suspect = suspects[name];

    return (suspect && suspect.value) ? suspect.id : undefined;
  }
}