
import { computed, defineComponent, PropType, ref, inject, Ref } from 'vue';
import VisitCard from '@/components/visit/VisitCard.vue';
import VisitCardMini from '@/components/visit/VisitCardMini.vue';
import { VisitsArchiveGroupInfo } from '@/helpers/visit';
import { CarVisitCollectionItem } from '@/repositories/Models/CarVisit';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    VisitCard,
    VisitCardMini,
  },

  props: {
    group: {
      type: Object as PropType<VisitsArchiveGroupInfo>,
      required: true
    },
    colSizes: {
      type: Object as PropType<Record<string, string|number>>,
      default: () => {
        return {
          sizeXl: 3,
          sizeLg: 4,
          sizeSm: 6,
        }
      },
    }
  },

  setup(props) {
    const router = useRouter();
    const { t } = useI18n();

    const viewType = inject<Ref<string>|null>('viewType', null);
    const viewComponentName = computed<string>(() => {
      return (viewType && viewType.value === 'mini')
        ? 'VisitCardMini'
        : 'VisitCard';
    });

    const expand = ref(false);
    const itemsViewCount = getItemsViewCount();
    const isShowMore = computed(() => props.group.items.length > itemsViewCount);
    const showMoreBtnText = computed(() => {
      const showOrHideCount = props.group.items.length - itemsViewCount;
      return t(
        expand.value
          ? 'composables.visit_archive_group.action_hide_text'
          : 'composables.visit_archive_group.action_show_text',
        { count: showOrHideCount },
        { plural: showOrHideCount }
      );
    });

    /**
     * Количество отображаемых групп
     * Инициализируется при запуске и не будет пересчитываться при resize
     */
    function getItemsViewCount() {
      const iw = window.innerWidth;

      if (iw >= 1200) return 7; // xl
      if (iw >= 992) return 5;  // lg
      if (iw >= 768) return 3;  // md

      return 3; // phone
    }

    function onClickCard(visit: CarVisitCollectionItem) {
      router.push({
        name: 'visit-single',
        params: {
          visitId: visit.id
        }
      });
    }

    return {
      itemsViewCount,
      expand,
      isShowMore,
      showMoreBtnText,
      onClickCard,
      viewComponentName,
    };
  }
});
