import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "color-default",
  slot: "end"
}
const _hoisted_2 = {
  class: "color-default",
  slot: "end"
}
const _hoisted_3 = {
  class: "color-default",
  slot: "end"
}
const _hoisted_4 = {
  class: "color-default",
  slot: "end"
}
const _hoisted_5 = { class: "visit-pay-sum-card__price" }
const _hoisted_6 = { class: "visit-pay-sum-card__valute" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header = _resolveComponent("app-header")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_cc = _resolveComponent("cc")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_c_card_list = _resolveComponent("c-card-list")!
  const _component_input_pos = _resolveComponent("input-pos")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_pos_slim_layout = _resolveComponent("pos-slim-layout")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_app_header, { title: _ctx.viewTitle }, null, 8, ["title"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_pos_slim_layout, {
            class: _normalizeClass(["ion-padding", { 'block-deactivated': _ctx.initLoading }])
          }, {
            footer: _withCtx(() => [
              _createVNode(_component_ion_button, {
                color: "primary",
                expand: "block",
                shape: "round",
                class: "mt-2",
                onClick: _ctx.confirmAction,
                disabled: _ctx.buttonConfirmDisabled
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.buttonConfirmText), 1)
                ]),
                _: 1
              }, 8, ["onClick", "disabled"])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_c_card_list, { class: "ion-no-margin" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.labelTotal), 1),
                      _createElementVNode("div", _hoisted_1, [
                        (_ctx.initLoading)
                          ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                              key: 0,
                              animated: "",
                              style: {
                width: '60px',
                height: '1em',
              }
                            }))
                          : (_openBlock(), _createBlock(_component_cc, {
                              key: 1,
                              price: _ctx.totalVisit
                            }, null, 8, ["price"]))
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.labelPaymentType), 1),
                      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.paymentTypeTitle), 1)
                    ]),
                    _: 1
                  }),
                  (_ctx.showCalculator)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('views.visit_pay.label_cash_deposited')), 1),
                            _createVNode(_component_ion_note, {
                              color: _ctx.cashDepositedError ? 'danger' : 'light',
                              slot: "end"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_cc, { price: _ctx.cashDeposited }, null, 8, ["price"])
                              ]),
                              _: 1
                            }, 8, ["color"])
                          ]),
                          _: 1
                        }),
                        (_ctx.hasPoints)
                          ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('views.visit_pay.label_pay_points')), 1),
                                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.pointsFormatted), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('views.visit_pay.label_odd_money')), 1),
                            _createElementVNode("div", _hoisted_4, [
                              _createVNode(_component_cc, { price: _ctx.changeFromCash }, null, 8, ["price"])
                            ])
                          ]),
                          _: 1
                        })
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              (_ctx.showCalculator)
                ? (_openBlock(), _createBlock(_component_input_pos, {
                    key: 0,
                    class: "mt-2",
                    modelValue: _ctx.cashDeposited,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.cashDeposited) = $event))
                  }, null, 8, ["modelValue"]))
                : (_openBlock(), _createBlock(_component_ion_card, {
                    key: 1,
                    class: "visit-pay-sum-card"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_cc, {
                        price: _ctx.sum,
                        options: { currency: '' }
                      }, {
                        default: _withCtx(({ priceFormatted, currencyConfig }) => [
                          _createElementVNode("div", _hoisted_5, _toDisplayString(priceFormatted), 1),
                          _createElementVNode("div", _hoisted_6, _toDisplayString(currencyConfig.name), 1)
                        ]),
                        _: 1
                      }, 8, ["price"])
                    ]),
                    _: 1
                  }))
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}