
import { defineComponent, ref, provide, computed, nextTick } from 'vue';
import AppHeaderNewVisit from '@/components/layout/AppHeaderNewVisit.vue';
import CarSideInfo from '@/components/car/CarSideInfo.vue';
import { useApp } from '@/composables/useApp';
import ServicesProvideChoiceDiscounts from '@/components/service/provide-form/ServicesProvideChoiceDiscounts.vue';
import { useToast } from '@/composables/useToast';
import { useNavManager } from '@/composables/useNavManager';
import { useCreateVisitStep, useNewVisitPhoto } from '@/composables/visit';
import { base64ToBlob } from '@/helpers/image';
import { useMetrica } from '@/composables/useMetrica';
import { useI18n } from 'vue-i18n';
import { get } from 'lodash';
import { CarVisitBodyRequest } from '@/repositories/Models/CarVisit';

export default defineComponent({
  components: {
    AppHeaderNewVisit,
    CarSideInfo,
    ServicesProvideChoiceDiscounts,
  },
  setup() {
    const toast = useToast();
    const { store, repositories } = useApp();
    const navManager = useNavManager();
    const { emitEvent } = useMetrica();
    const { t } = useI18n();
    const {
      carId,
      isVisit,
      generateBodyVisit,
      clearVisitState,
      visitType,
      isPreentry,
      visitState,
      isUpdateVisitCounterparty,
    } = useCreateVisitStep({
      stateProvideKey: 'visitState'
    });

    const {
      removeNewVisitPhotoInDb,
      visitImageCover,
      visitPhoto,
    } = useNewVisitPhoto({ visitState, onlyMeta: isPreentry });

    provide('visitAction', visitType);

    const saveLoading = ref(false);
    const visitSaved = ref(false);

    async function uploadVisitPhoto(visitId: string) {
      if (!isVisit || !visitPhoto.value) return; // Без фото

      try {
        // fix: base64ToFileObject не работает на Capacitor Android
        // из-за какого-то бага. Он неправильно создает объект типа File
        const imageFile = base64ToBlob(visitPhoto.value);
        if (!imageFile) throw new Error('Error while transcoding image from base64');

        await repositories.visit.addPhoto(visitId, [ imageFile ]);
      } catch (e) {
        toast.error(t('views.visit_new_discounts.error_fail_upload_photo'));
      }
    }

    /**
     * Прикрепляет к пользователю категории, которые были выбраны в этом заказе
     */
    async function syncLastVisitCategories() {
      const catId = get(visitState.selectedTypeAndCategories, '0.catId', null);

      // Если категория совпадает с ранее выбранной, то обновлять значение не требуется
      if (!catId || catId === visitState.meta.lastCategoryId) {
        return;
      }
    
      return await store.car.update(visitState.body.car?.id as string, {
        id: visitState.body.car?.id as string,
        priceCategories: [ { id: catId } ]
      });
    }

    async function submitVisit() {
      // Обновление контрагента (на важно что за заказ)
      if (isUpdateVisitCounterparty) {
        await updateCounterpartyVisit();
      }

      // Создание нового заказа (магазин/предварительная запись/новый визит)
      else {
        await createVisit();
      }
    }

    /**
     * Создание нового визита
     */
    async function createVisit() {
      saveLoading.value = true;

      try {
        syncLastVisitCategories(); // async

        const bodyVisit = await generateBodyVisit();
        const res = await store.visit.create(bodyVisit);

        visitSaved.value = true;

        if (res.sendingDelayed) {
          // toast.warning('Интернет недоступен, данные сохранены, и синхрнизируются при поялении интернета');
          console.log('Интернет недоступен, данные сохранены, и синхрнизируются при поялении интернета');
        } else {
          // toast.success(t('views.visit_new_discounts.message_visit_created'));

          // NOTE: Фото будет загружаться только при доступном интернет соединении
          // FIXME: Доработать функцию создания заказа так, чтобы в сохраненном
          // на устройстве заказе (не синхронизированом) сохранялось также и
          // изображение визита, для дальнейшей отправки при синхронизации
          await uploadVisitPhoto(res.entityId);
        }

        nextTick(() => {
          clearVisitState();
          removeNewVisitPhotoInDb(); // async: При возникновении ошибки ничего не отобразиться

          emitEvent(`visit/${visitType}/created`);

          navManager.navigate({
            routerDirection: 'root',
            routerLink: {
              name: 'visits',
            },
          });
        });
        
      } catch (e) {
        toast.error(e);
        visitSaved.value = false;
      } finally {
        saveLoading.value = false;
      }
    }

    /**
     * Обновдение контрагента в существующем визите
     */
    async function updateCounterpartyVisit() {
      saveLoading.value = true;

      try {
        syncLastVisitCategories(); // async

        const fullBodyVisit = await generateBodyVisit();

        // Такое маловерояно, но при определенной сноровке можно получить
        if (!fullBodyVisit.id) {
          toast.error('Невозможно обновить запись, отсутствует идентификатор. Обратитесь к администратору');
          return;
        }

        const updateBody: CarVisitBodyRequest = {
          group: fullBodyVisit.group,
          providedServices: fullBodyVisit.providedServices,
        };

        const res = await store.visit.update(fullBodyVisit.id, updateBody);
        if (res.sendingDelayed) {
          console.log('Интернет недоступен, данные сохранены, и синхрнизируются при поялении интернета');
        }

        visitSaved.value = true;

        // NOTE: Обновление фото при смене контрагента не требуется.
        
        nextTick(() => {
          clearVisitState();

          emitEvent(`visit/counterparty/update`);

          // Вернемся обратно в карточку
          navManager.navigate({
            routerDirection: 'root',
            routerLink: {
              name: 'visit-single',
              params: { visitId: res.entityId },
            },
          });
        });
      } catch (e) {
        toast.error(e);
        visitSaved.value = false;
      } finally {
        saveLoading.value = false;
      }
    }

    const btnCreateText = computed(() => {
      if (saveLoading.value || visitSaved.value) {
        return t('views.visit_new_discounts.btn_loading_create_visit');
      }

      return (isUpdateVisitCounterparty)
        ? t('views.visit_new_discounts.btn_update_couterparty')
        : t('views.visit_new_discounts.btn_create_visit');
    });

    const btnCreateDisabled = computed(() => {
      return (saveLoading.value || visitSaved.value);
    });

    return {
      carId,
      submitVisit,
      visitImageCover,
      saveLoading,
      btnCreateText,
      btnCreateDisabled,
    };
  }
});
