import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Transition as _Transition, KeepAlive as _KeepAlive } from "vue"

const _hoisted_1 = {
  slot: "end",
  class: "color-default mr-0"
}
const _hoisted_2 = { class: "ion-padding-vertical" }
const _hoisted_3 = {
  slot: "end",
  class: "color-default mr-0"
}
const _hoisted_4 = { class: "ion-padding-vertical" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_c_divider = _resolveComponent("c-divider")!
  const _component_app_header_new_visit = _resolveComponent("app-header-new-visit")!
  const _component_c_infinite_calendar = _resolveComponent("c-infinite-calendar")!
  const _component_c_modal_action_header = _resolveComponent("c-modal-action-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_c_modal_content = _resolveComponent("c-modal-content")!
  const _component_c_modal_float = _resolveComponent("c-modal-float")!
  const _component_visits_preentry_shedule_box = _resolveComponent("visits-preentry-shedule-box")!
  const _component_c_modal_step = _resolveComponent("c-modal-step")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_app_header_new_visit, {
        title: _ctx.$t('views.visits_new_preentry_datetime.title'),
        progress: 0.825
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { class: "page-preentry-datetime-toolbar" }, {
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_ion_chip, {
                outline: "",
                color: "primary",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentTab = 'selectDate'))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.customDateFormat), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_icon, {
                    icon: "close-outline",
                    onClick: _withModifiers(_ctx.clearCustomDate, ["stop"])
                  }, null, 8, ["onClick"])
                ]),
                _: 1
              }, 512), [
                [_vShow, _ctx.customDate]
              ]),
              _withDirectives(_createVNode(_component_ion_segment, {
                class: "core-segment",
                modelValue: _ctx.currentTab,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentTab) = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_segment_button, {
                    value: "currentDate",
                    layout: "icon-hide"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('views.visits_new_preentry_datetime.tab_current_day')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_segment_button, {
                    value: "nextDate",
                    layout: "icon-hide"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('views.visits_new_preentry_datetime.tab_next_day')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_segment_button, {
                    value: "selectDate",
                    layout: "icon-hide"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('views.visits_new_preentry_datetime.tab_select_date')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"]), [
                [_vShow, !_ctx.customDate]
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_c_divider, { class: "my-0" })
        ]),
        _: 1
      }, 8, ["title", "progress"]),
      _createVNode(_component_ion_content, { "scroll-y": false }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_KeepAlive, null, [
            (_ctx.isSelectDate)
              ? (_openBlock(), _createBlock(_component_c_infinite_calendar, {
                  key: 0,
                  "onUpdate:modelValue": _ctx.setCustomDate,
                  modelValue: _ctx.customDate,
                  class: "visits-new-preentry-calendar"
                }, null, 8, ["onUpdate:modelValue", "modelValue"]))
              : (_openBlock(), _createBlock(_component_visits_preentry_shedule_box, {
                  key: 1,
                  class: "visit-new-preentry-shedule-box",
                  "select-enable": true,
                  date: _ctx.viewSheduleDate,
                  normative: _ctx.normativeTimeMinutes,
                  modelValue: _ctx.preentryValue,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.preentryValue) = $event)),
                  onPointerInfo: _ctx.setPointerInfo,
                  onPointerRect: _ctx.updatePointerRect
                }, {
                  default: _withCtx(() => [
                    _createVNode(_Transition, { name: "fade" }, {
                      default: _withCtx(() => [
                        (_ctx.showTabletPreviewModal)
                          ? (_openBlock(), _createBlock(_component_c_modal_float, {
                              key: 0,
                              "container-styles": _ctx.floatModalStyles,
                              onClickBackgroup: _ctx.onClickBackgroupFloatModal
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_c_modal_action_header, { onDismiss: _ctx.closeFloatModal }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('views.visits_new_preentry_datetime.modal_confirm_title')), 1)
                                  ]),
                                  _: 1
                                }, 8, ["onDismiss"]),
                                _createVNode(_component_c_modal_content, { class: "ion-padding-horizontal" }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.previewInfo, (info, index) => {
                                      return (_openBlock(), _createBlock(_component_ion_item, {
                                        class: "list-info-item",
                                        key: index
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(info.label), 1),
                                          _createElementVNode("div", _hoisted_1, _toDisplayString(info.value), 1)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128)),
                                    _createElementVNode("div", _hoisted_2, [
                                      _createVNode(_component_ion_button, {
                                        color: "primary",
                                        expand: "block",
                                        shape: "round",
                                        onClick: _ctx.confirm
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('views.visits_new_preentry_datetime.modal_confirm_btn')), 1)
                                        ]),
                                        _: 1
                                      }, 8, ["onClick"])
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["container-styles", "onClickBackgroup"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["date", "normative", "modelValue", "onPointerInfo", "onPointerRect"]))
          ], 1024)),
          (false === _ctx.isTabletAndUp)
            ? (_openBlock(), _createBlock(_component_c_modal_step, {
                key: 0,
                "preview-height": _ctx.previewHeight,
                state: _ctx.previewState,
                "onUpdate:state": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.previewState) = $event)),
                onClosed: _cache[4] || (_cache[4] = ($event: any) => (_ctx.preentryValue = null)),
                onClickBackgroup: _ctx.onClickBackgroupPreviewModal
              }, {
                default: _withCtx(({ close, open }) => [
                  _createVNode(_component_c_modal_action_header, {
                    onClickTitle: open,
                    onDismiss: close
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('views.visits_new_preentry_datetime.modal_confirm_title')), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClickTitle", "onDismiss"]),
                  _createVNode(_component_c_modal_content, { class: "ion-padding-horizontal" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.previewInfo, (info, index) => {
                        return (_openBlock(), _createBlock(_component_ion_item, {
                          class: "list-info-item",
                          key: index
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(info.label), 1),
                            _createElementVNode("div", _hoisted_3, _toDisplayString(info.value), 1)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128)),
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_ion_button, {
                          color: "primary",
                          expand: "block",
                          shape: "round",
                          onClick: _ctx.confirm
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('views.visits_new_preentry_datetime.modal_confirm_btn')), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["preview-height", "state", "onClickBackgroup"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}