
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import ServicesCategoryGroupView from '@/components/service/ServicesCategoryGroupView.vue';
import { isTabletDown } from '@/helpers/adaptive';

export default defineComponent({
  components: { ServicesCategoryGroupView },
  emits: ['input-search'],

  props: {
    catId: {
      type: [String, Number] as PropType<string|number>,
      required: false,
    },
    mobileSearchbarContainer: {
      type: Object as PropType<HTMLDivElement|null>,
      required: false,
    }
  },

  setup(props, { emit }) {
    const searchWords = ref('');
    watch(searchWords, text => emit('input-search', text));

    const searchbarTeleportDisabled = computed(() => !(isTabletDown.value && props.mobileSearchbarContainer));
    const searchbarContainer = computed(() => searchbarTeleportDisabled.value ? null : props.mobileSearchbarContainer);

    return {
      searchWords,
      searchbarContainer,
      searchbarTeleportDisabled,
    };
  }
});
