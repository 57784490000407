import { ref } from 'vue';
import { modalController } from '@ionic/vue';
import CProgressInfo from '@/components/core/CProgressInfo.vue';

export interface ProgressInformation {
  message?: string;
  progress: number;
}

export async function createProgressInformation() {
  const progressInfo = ref<ProgressInformation>({
    message: '',
    progress: 0,
  });

  const popover = await modalController.create({
    component: CProgressInfo,
    cssClass: 'progress-info-popover modal-fullscreen',
    showBackdrop: true,
    backdropDismiss: false,
    componentProps: {
      getContext: () => progressInfo,
      color: 'success',
    },
  });

  return {
    progressInfo,
    popover,
  };
}