
import { computed, defineComponent } from 'vue';
import VisitsPreentrySheduleBox from '@/components/visit/VisitsPreentrySheduleBox.vue';
import CInfiniteCalendar from '@/components/core/InfiniteCalendar/CInfiniteCalendar.vue';
import { useRouter } from 'vue-router';
import { usePreenryDatetimePage } from '@/composables/usePreentry';
import { useNavManager } from '@/composables/useNavManager';
import { get } from 'lodash';
import { CarVisitCollectionItem } from '@/repositories/Models/CarVisit';
import { useStore } from '@/composables/useApp';

export default defineComponent({
  components: {
    VisitsPreentrySheduleBox,
    CInfiniteCalendar,
  },
  setup() {
    const router = useRouter();
    const navManager = useNavManager();
    const store = useStore();
    const {
      currentTab,
      customDate,
      customDateFormat,
      setCustomDate,
      clearCustomDate,
      viewSheduleDate,
      isSelectDate,
    } = usePreenryDatetimePage();

    const showAddButton = computed(() => {
      return currentTab.value !== 'selectDate';
    });

    function newVisitPreentry() {
      store.visit.clearPreentryVisitState();

      // Предварительно сохраним выбранную дату, чтобы в последующем шаге 
      // при выборе времени отобразить календарь с этой датой
      store.visit.preentryVisitState.meta.saveDate = new Date(viewSheduleDate.value);
      
      router.push({ name: 'preentry-car-number' });
    }

    function toVisits() {
      if (get(router.options, 'history.state.back') === '/visits') {
        router.back();
      } else {
        navManager.navigate({
          routerDirection: 'root',
          routerLink: { name: 'visits' },
        });
      }
    }

    function toVisitSingle(visit: CarVisitCollectionItem) {
      router.push({
        name: 'visit-single',
        params: { visitId: visit.id }
      });
    }

    return {
      currentTab,
      customDate,
      setCustomDate,
      clearCustomDate,
      customDateFormat,
      showAddButton,
      newVisitPreentry,
      viewSheduleDate,
      isSelectDate,
      toVisits,
      toVisitSingle,
    };
  }
});
