
import { CarVisitItem } from '@/repositories/Models/CarVisit';
import { computed, ComputedRef, defineComponent, onMounted, PropType, toRef } from 'vue';
import { useVisit, useVisitTimings, useVisitBadges } from '@/composables/visit';
import moment from 'moment';
import { useI18n } from 'vue-i18n';

export interface VisitBadgeItem {
  text: string;
  color: string;
}

export interface VisitCardShortInfo {
  mainImage: ComputedRef<string>;
  totalPrice: ComputedRef<number>;
  progressText: ComputedRef<string>;
  formatCarNumber: ComputedRef<string>;
  badgeList: ComputedRef<VisitBadgeItem[]>;
}

export default defineComponent({
  props: {
    visit: {
      type: Object as PropType<CarVisitItem>,
      required: true,
    },
    viewBox: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const BOX_REPLACE_PREVIEW_REGV = new RegExp(t('components.visit_card.item_label_box_name') + ' ', 'i');
    const visitRef = toRef(props, 'visit');

    const {
      totalPrice,
      formatCarNumber,
      hasCarNumber,
      mainImage,
      isPreentry,
      preentryDateFormat,
      preentryTimeFormat,
    } = useVisit(visitRef);

    const {
      progressText,
      progressValue,
      progressColor,
      predictedFinishDate,
      processedDate,
      normativMinutes,
    } = useVisitTimings(visitRef);

    const { badgeList, isClosed } = useVisitBadges(visitRef);

    onMounted(() => {
      emit('short-info', {
        mainImage,
        totalPrice,
        progressText,
        formatCarNumber,
        badgeList,
      } as VisitCardShortInfo);
    });

    /** Дата создания заказа */
    const createDateFormat = computed(() => processedDate.value?.format('D MMMM YYYY') || null);

    /** Фактическое время начала работ */
    const startTimeFormat = computed(() => processedDate.value?.format('DD.MM.YY, H:mm') || null);

    /** Рассчетное время окончания работ */
    const predictedEndTimeFormat = computed(() => {
      return (normativMinutes.value && predictedFinishDate.value)
        ? predictedFinishDate.value.format('H:mm') : null;
    });

    /** Фактическое время окончания работ */
    const endTimeFormat = computed(() => {
      return props.visit.exitDate ? moment(props.visit.exitDate).format('H:mm') : null;
    });

    const boxInfo = computed(() => {
      if (!props.visit.box) return null;

      const boxName = String(props.visit.box.name);
      if (BOX_REPLACE_PREVIEW_REGV.test(boxName)) {
        return {
          label: t('components.visit_card.item_label_box_number'),
          name: boxName.replace(BOX_REPLACE_PREVIEW_REGV, ''),
        };
      }

      return {
        label: t('components.visit_card.item_label_box_name'),
        name: boxName,
      };
    });

    return {
      totalPrice,
      formatCarNumber,
      hasCarNumber,
      mainImage,
      badgeList,
      isClosed,
      progressValue,
      progressText,
      progressColor,
      createDateFormat,
      predictedEndTimeFormat,
      startTimeFormat,
      endTimeFormat,
      isPreentry,
      preentryDateFormat,
      preentryTimeFormat,
      boxInfo,
    };
  },
});
