
import { defineComponent, PropType, provide } from 'vue';
import { ModalServicesContext } from '@/views/visits/VisitSingle.vue';
import { useModalNav } from './ServicesAddCategoryGroupModal.vue';
import { ProvideServiceBodyItem } from '@/repositories/Models/CarVisit';
import ServicesProvideChoiceDiscounts from '../provide-form/ServicesProvideChoiceDiscounts.vue';

export default defineComponent({
  inheritAttrs: false,

  components: {
    ServicesProvideChoiceDiscounts,
  },

  props: {
    // HACK: Не передаем значения напрямую,
    // т.к. это вызовет проблемы с обновлением props значений
    useModalServicesContext: {
      type: Function as PropType<() => ModalServicesContext>,
      required: true,
    },

    setProvidedServices: {
      type: Function as PropType<(providedServices: ProvideServiceBodyItem[]) => void>,
      required: false,
    }
  },

  setup(props) {
    const nav = useModalNav();
    const ctx = props.useModalServicesContext();
    provide('visitState', ctx.visitState);

    function apply() {
      const providedServices = ctx.visitState.body.providedServices || [];
      props.setProvidedServices?.call(null, [...providedServices]);
    }

    return {
      apply,
      back: () => nav.backView(),
    };
  },
});
