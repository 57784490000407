
import { useStore } from '@/composables/useApp'
import { CarVisitProvideService } from '@/repositories/Models/CarVisit'
import { defineComponent, PropType } from 'vue'
import ServiceProvideViewItem from './ServiceProvideViewItem.vue'

export interface ViewGroup {
  catId: number;
  providedServices: CarVisitProvideService[];
}

export default defineComponent({
  components: {
    ServiceProvideViewItem,
  },

  props: {
    group: {
      type: Object as PropType<ViewGroup>,
      required: true,
    },
    shortTitle: {
      type: Boolean,
      default: false,
    }
  },

  setup(props) {
    const store = useStore();

    const categoryName = store.point.getCategoryBreadcrumbsComputed(props.group.catId, props.shortTitle);

    return {
      categoryName,
    };
  }
});
