/**
 * Группы партнеров
 */
import { BaseRepository, AsyncResponse} from './BaseRepository'
import { CarGroupCollectionQuery, CarGroupCollectionResponse } from './Models/CarGroup'

export class CarGroupRepository extends BaseRepository {
  /**
   * Получить список групп-партнеров
   * 
   * @param params 
   * @returns 
   */
  getCollection(params: CarGroupCollectionQuery = {}): AsyncResponse<CarGroupCollectionResponse> {
    return this.client.get('car-groups', { params });
  }
}
