import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ion-padding-horizontal ion-padding-top"
}
const _hoisted_2 = { ref: "showInfoHeaderAfterBlock" }
const _hoisted_3 = { slot: "end" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { slot: "end" }
const _hoisted_6 = { class: "visit-single-complete-btn-wrap" }
const _hoisted_7 = {
  key: 0,
  class: "ion-padding"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_visit_short_info = _resolveComponent("visit-short-info")!
  const _component_app_header = _resolveComponent("app-header")!
  const _component_visit_card = _resolveComponent("visit-card")!
  const _component_cc = _resolveComponent("cc")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_c_card_list = _resolveComponent("c-card-list")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_c_card_header = _resolveComponent("c-card-header")!
  const _component_services_provided_view_card = _resolveComponent("services-provided-view-card")!
  const _component_client_info_card = _resolveComponent("client-info-card")!
  const _component_c_content_loading = _resolveComponent("c-content-loading")!
  const _component_tablet_side_layout = _resolveComponent("tablet-side-layout")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_app_footer = _resolveComponent("app-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_app_header, {
        title: _ctx.$t('views.visit_single.title')
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_KeepAlive, null, [
            (_ctx.showShortInformationHeader && _ctx.visitShortInfo)
              ? (_openBlock(), _createBlock(_component_visit_short_info, _mergeProps({
                  key: 0,
                  class: "visit-header-information"
                }, {
            mainImage: _ctx.visitShortInfo.mainImage.value,
            totalPrice: _ctx.visitShortInfo.totalPrice.value,
            progressText: _ctx.visitShortInfo.progressText.value,
            formatCarNumber: _ctx.visitShortInfo.formatCarNumber.value,
          }), null, 16))
              : _createCommentVNode("", true)
          ], 1024))
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_ion_content, {
        class: "visit-single",
        "scroll-events": true,
        onIonScroll: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onScroll($event)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tablet_side_layout, null, _createSlots({
            side: _withCtx(() => [
              (_ctx.visit)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_visit_card, {
                      visit: _ctx.visit,
                      onShortInfo: _ctx.setShortInfo,
                      "view-box": true
                    }, null, 8, ["visit", "onShortInfo"]),
                    _createElementVNode("div", _hoisted_2, null, 512),
                    _createVNode(_component_c_card_list, null, {
                      default: _withCtx(() => [
                        (_ctx.visit.isPayed)
                          ? (_openBlock(), _createBlock(_component_ion_item, {
                              key: 0,
                              class: "color-default"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('views.visit_single.card_info_pay')), 1),
                                _createElementVNode("div", _hoisted_3, [
                                  (_ctx.paymentTypeTitle)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, "(" + _toDisplayString(_ctx.paymentTypeTitle) + ")", 1))
                                    : _createCommentVNode("", true),
                                  _createVNode(_component_cc, {
                                    price: _ctx.visit.payed
                                  }, null, 8, ["price"])
                                ])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.visit.isPayed && _ctx.visit.payedByScores)
                          ? (_openBlock(), _createBlock(_component_ion_item, {
                              key: 1,
                              class: "color-default"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('views.visit_single.card_info_pay_points')), 1),
                                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.visit.payedByScores), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.oppCloseVisit)
                          ? (_openBlock(), _createBlock(_component_ion_item, {
                              key: 2,
                              button: "",
                              detail: false,
                              onClick: _ctx.closeVisitAction,
                              class: "item-link-button ion-text-center item-no-detail-icon"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('views.visit_single.card_action_close_visit')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["onClick"]))
                          : (_ctx.oppReturnWork)
                            ? (_openBlock(), _createBlock(_component_ion_item, {
                                key: 3,
                                button: "",
                                detail: false,
                                onClick: _ctx.reopenVisitAction,
                                class: "item-link-button ion-text-center item-no-detail-icon"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('views.visit_single.card_action_return_work')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }, 8, ["onClick"]))
                            : _createCommentVNode("", true),
                        (_ctx.oppReturnPay)
                          ? (_openBlock(), _createBlock(_component_ion_item, {
                              key: 4,
                              button: "",
                              detail: false,
                              onClick: _ctx.returnPayAction,
                              class: "item-link-button ion-text-center item-no-detail-icon"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('views.visit_single.card_action_return_pay')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["onClick"]))
                          : _createCommentVNode("", true),
                        (_ctx.oppInvoiceFiscal)
                          ? (_openBlock(), _createBlock(_component_ion_item, {
                              key: 5,
                              button: "",
                              detail: false,
                              onClick: _ctx.alertConfirmInvoiceFiscal,
                              class: "item-link-button ion-text-center item-no-detail-icon"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('views.visit_single.card_action_invoice_fiscal')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["onClick"]))
                          : _createCommentVNode("", true),
                        (_ctx.oppInvoice)
                          ? (_openBlock(), _createBlock(_component_ion_item, {
                              key: 6,
                              button: "",
                              detail: false,
                              onClick: _ctx.alertConfirmInvoice,
                              class: "item-link-button ion-text-center item-no-detail-icon"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('views.visit_single.card_action_invoice')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["onClick"]))
                          : _createCommentVNode("", true),
                        (_ctx.oppCancelPreentryVisit)
                          ? (_openBlock(), _createBlock(_component_ion_item, {
                              key: 7,
                              button: "",
                              detail: false,
                              onClick: _ctx.preentryCancelVisit,
                              class: "item-link-button ion-text-center item-no-detail-icon"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('views.visit_single.card_action_cancel_preentry_visit')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["onClick"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_c_content_loading, { action: _ctx.load }, {
                default: _withCtx(() => [
                  (_ctx.visit)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createVNode(_component_c_card_header, { class: "ion-hide-md-down" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('views.visit_single.provide_services_title')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_services_provided_view_card, {
                          "provided-services": _ctx.visit.providedServices,
                          total: _ctx.visit.price
                        }, {
                          after: _withCtx(() => [
                            (_ctx.oppChangeProvidedServices)
                              ? (_openBlock(), _createBlock(_component_ion_item, {
                                  key: 0,
                                  onClick: _ctx.changeProvidedServices,
                                  class: "item-link-button ion-text-center item-no-detail-icon"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('views.visit_single.provide_services_action_edit')), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["onClick"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["provided-services", "total"]),
                        _createVNode(_component_c_card_header, { class: "ion-hide-md-down mt-3" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('views.visit_single.client_card_title')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_client_info_card, {
                          class: "padding-x-adaptive",
                          "car-data": _ctx.visit.car,
                          "show-group": true,
                          group: _ctx.visit.group,
                          onChoiceGroup: _ctx.startChangeCounterparty
                        }, null, 8, ["car-data", "group", "onChoiceGroup"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["action"])
            ]),
            _: 2
          }, [
            (_ctx.visit && (_ctx.oppPay || _ctx.oppGetStarted))
              ? {
                  name: "side-after",
                  fn: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      (_ctx.oppPay)
                        ? (_openBlock(), _createBlock(_component_ion_button, {
                            key: 0,
                            onClick: _ctx.payVisit,
                            color: "primary",
                            expand: "block",
                            shape: "round"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_cc, {
                                price: _ctx.visit.price
                              }, {
                                default: _withCtx(({ priceFormatted }) => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('views.visit_single.action_pay', { price: priceFormatted })), 1)
                                ]),
                                _: 1
                              }, 8, ["price"])
                            ]),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      (_ctx.oppGetStarted)
                        ? (_openBlock(), _createBlock(_component_ion_button, {
                            key: 1,
                            onClick: _ctx.preentryGetStarted,
                            color: "primary",
                            expand: "block",
                            shape: "round"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('views.visit_single.action_preentry_get_started')), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                }
              : undefined
          ]), 1024)
        ]),
        _: 1
      }),
      _createVNode(_component_app_footer)
    ]),
    _: 1
  }))
}