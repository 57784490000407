import { CarVisitBodyRequest } from '@/repositories/Models/CarVisit';
import { VisitTypeAndCategory } from '@/helpers/visit';
import { Discount, GiftCampaign } from '@/repositories/Models/Discount';
import { DoerCollectionItem } from '@/repositories/Models/User';
import { reactive, UnwrapRef, watch } from 'vue';
import { merge } from 'lodash';
import ls from '@/helpers/localStorage';

export interface NewVisitSatate {
  body: CarVisitBodyRequest;
  selectedTypeAndCategories: VisitTypeAndCategory[],
  defaultDiscount: Pick<Discount, "id"|"type">|null,
  defaultDoers: Pick<DoerCollectionItem, "id">[];
  totalOrderDiscount: Discount|null;
  meta: Record<string, any>;

  // Подарочные компании, которые применяются в данный момент
  // ключем является идентификатор номенклатуры
  giftCampaign: Record<string, GiftCampaign>;
}

export function getEmptyNewVisitState(): NewVisitSatate {
  return {
    body: {},
    selectedTypeAndCategories: [],
    defaultDoers: [],
    defaultDiscount: null,
    totalOrderDiscount: null,
    meta: {},
    giftCampaign: {},
  };
}

export function createNewVisitState(key: string): UnwrapRef<NewVisitSatate> {
  let visitState = getEmptyNewVisitState();

  const dataKey = `visit-state/${key}`;
  mergeNewVisitState(visitState, ls.getValue(dataKey));

  const visitStateReactive = reactive(visitState);
  watch(visitStateReactive, () => ls.saveValue(dataKey, visitStateReactive));

  return visitStateReactive;
}

export function clearNewVisitState(state: UnwrapRef<NewVisitSatate>): UnwrapRef<NewVisitSatate> {
  return Object.assign(state, getEmptyNewVisitState());
}

export function mergeNewVisitState(state: UnwrapRef<NewVisitSatate>, mergeData: NewVisitSatate|null): void {
  if (!mergeData) return;
  merge(state, mergeData);
}
