import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_c_card_list = _resolveComponent("c-card-list")!

  return (_openBlock(), _createBlock(_component_c_card_list, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_item, { class: "c-card-list-header" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('components.services_popular_card.col_label_name')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_note, {
            slot: "end",
            color: "light"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('components.services_popular_card.col_label_count')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.loading)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.skeletLoadingRows, (skeletIndex) => {
            return (_openBlock(), _createBlock(_component_ion_item, { key: skeletIndex }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_skeleton_text, {
                      animated: "",
                      style: _normalizeStyle({
              width: _ctx.randWidth(20, 70),
              height: '1em', 
            })
                    }, null, 8, ["style"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_note, {
                  slot: "end",
                  color: "light"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_skeleton_text, {
                      animated: "",
                      style: {
              width: '20px',
              height: '1em', 
            }
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 2
            }, 1024))
          }), 128))
        : (!_ctx.hasServices)
          ? (_openBlock(), _createBlock(_component_ion_item, { key: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { class: "ion-text-center" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('components.services_popular_card.services_list_empty')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.viewList, (item) => {
              return (_openBlock(), _createBlock(_component_ion_item, {
                key: item.id
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.name), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_ion_note, {
                    slot: "end",
                    color: "light"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.countPurchases), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            }), 128))
    ]),
    _: 1
  }))
}