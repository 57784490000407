
import { CarVisitItem, CarVisitStatusEnum } from '@/repositories/Models/CarVisit';
import { computed, defineComponent, PropType, toRef } from 'vue';
import { useVisit, useVisitBadges, useVisitTimings } from '@/composables/visit';
import moment from 'moment';

export default defineComponent({
  props: {
    visit: {
      type: Object as PropType<CarVisitItem>,
      required: true,
    },
  },
  setup(props) {
    const visitRef = toRef(props, 'visit');

    const {
      totalPrice,
      formatCarNumber,
      hasCarNumber,
      mainImage,
      isPreentry,
      preentryDateFormat,
      preentryTimeFormat,
    } = useVisit(visitRef);

    const { badgeList } = useVisitBadges(visitRef);

    const finishTime = computed(() => {
      if (!props.visit.finishedDate) return '—';
      return moment(props.visit.finishedDate).format('H:ss');
    });

    const isViewProgress = computed(() => {
      return props.visit.status === CarVisitStatusEnum.Processed
        || (props.visit.status === CarVisitStatusEnum.Finished && props.visit.isPayed !== true);
    });

    const {
      progressValue,
      progressColor,
    } = useVisitTimings(visitRef);

    return {
      totalPrice,
      formatCarNumber,
      hasCarNumber,
      mainImage,
      finishTime,
      badgeList,
      isViewProgress,
      progressValue,
      progressColor,
      isPreentry,
      preentryDateFormat,
      preentryTimeFormat,
    };
  },
});
