import { BaseRepository, AsyncResponse} from './BaseRepository'
import { SuspectConfigQuery, SuspectConfigCollectionResponse } from './Models/Suspect'

export class SuspectRepository extends BaseRepository {
  /**
   * Получить список конфигурации о подозрительных действиях
   * 
   * @param params 
   * @returns 
   */
  getConfig(params: SuspectConfigQuery): AsyncResponse<SuspectConfigCollectionResponse> {
    return this.client.get('suspicious/config', { params });
  }
}
