// NOTE:
// #1 - Различные дисконтные кампании добовлялись уже после реализации основной логики,
// сейчас алгоритм сильно усложнился и уже не совсем все прозначно.
// Требуется пересмотреть архитектуру и алгоритм применения скидок и сделать рефакторинг.

import { BaseStore, BaseStoreContext } from './BaseStore';
import { minutes } from '@/helpers/cache';
import { CarVisitProvideService, ProvideServiceBodyItem } from '@/repositories/Models/CarVisit';
import { toRaw, Ref, shallowRef, watch } from 'vue';
import { PricePolicyStore } from './PricePolicy';
import { VisitServiceAndCategory, VisitTimingsForServiceAndCategory } from '@/helpers/visit';
import { PointStore } from './PointStore';

import {
  Discount,
  GiftCampaign,
  StaticDiscountCampaign,
  GiftCampaignNomenclature,
  LeveledCampaign,
  LeveledCampaignNomenclature
} from '@/repositories/Models/Discount';

import {
  flatten,
  keyBy,
  groupBy,
  entries,
  cloneDeep,
  uniq,
  sortBy,
  omit,
  values,
  map,
  get,
  isEmpty,
  toNumber,
  Dictionary
} from 'lodash';

import {
  NomenclatureCollectionItem,
  ServiceByContextItem,
  ServiceCategoriesCollectionItem,
  ServiceCollectionItem,
  ServiceCollectionPriceItem
} from '@/repositories/Models/Service';
import { useSuitableСompanies } from '@/helpers/discountCampain';
import { DiscountStore } from './DiscountStore';
import { CarStore } from './CarStore';

/** @deprecated */
export interface InformationAboutProvidedService {
  id: number;
  service: ServiceByContextItem|null;
  provide: ProvideServiceBodyItem;
}

export interface ProvidedServicesInformation {
  service: ServiceCollectionItem|null;
  provide: ProvideServiceBodyItem;
}

export interface ServiceStoreContext extends BaseStoreContext {
  pricePolicyStore: PricePolicyStore;
  discountStore: DiscountStore;
  carStore: CarStore;
  pointStore: PointStore;
}

export interface ServicesCategoryByContextParams {
  /** Идентификтаор категории */
  categoryId: string|number;

  /** Идентификатор контрагента */
  groupId?: string|number;
  
  /**
   * Необходим для применения скидок
   */
  carId?: string;

  /**
   * Есть ли необходимость в загрузке и применении скидок,
   * если указать undefined, то по умолчанию значение будет !groupId,
   * т.к. для контрагентов по умолнчанию скидки не нужны.
   */
  loadAndApplyLink?: boolean;
}

export interface ServiceForForm extends ServiceCollectionItem {
  staticDiscount?: StaticDiscountCampaign;
  leveledDiscount?: LeveledCampaign;
  giftCampaignFrom?: GiftCampaign;
  giftCampaignSelf?: GiftCampaign[];
}

export interface ServiceCategoriesForForm extends ServiceCategoriesCollectionItem {
  services?: ServiceForForm[];
}

export interface ProvideServiceMeta {
  staticDiscount?: StaticDiscountCampaign;
  giftDiscount?: GiftCampaign;
  leveledDiscount?: LeveledCampaign;
}

export class ServiceStore extends BaseStore<ServiceStoreContext> {
  private services: Ref<ServiceCollectionItem[]|null>;
  private nomenclature: Ref<NomenclatureCollectionItem[]|null>;
  private servicesCategories: Ref<ServiceCategoriesCollectionItem[]|null>;
  private servicesCategoriesIndexByCarCategory: Ref<Record<string, ServiceCategoriesCollectionItem[]>|null>;
  private servicesTimingsMap: Ref<Record<string, number>|null>;

  constructor(ctx: ServiceStoreContext) {
    super(ctx);

    this.services = shallowRef(null);
    this.nomenclature = shallowRef(null);

    this.servicesCategories = shallowRef(null);
    watch(this.services, () => this.servicesCategories.value = null);

    this.servicesCategoriesIndexByCarCategory = shallowRef(null);
    watch(this.servicesCategories, () => this.servicesCategoriesIndexByCarCategory.value = null);

    this.servicesTimingsMap = shallowRef(null);
    watch(this.services, () => this.servicesTimingsMap.value = null);
  }

  /**
   * Очистка закэшированных данных в памяти
   */
  clearMemoryCached() {
    this.services.value = null;
    this.nomenclature.value = null;
  }

  /**
   * Получает полный список с услугами
   * NOTE: Оставлен для совметимости, т.к. метод getTimings()
   * использует его для рассчета нормативов времени
   * 
   * @returns
   */
  async getAll(): Promise<ServiceCollectionItem[]> {
    if (!this.services.value) {
      const servicesCategories = await this.getAllServicesCategories();
      let allServices = flatten(map(servicesCategories, sc => sc.services || []));

      // Теоритически дублирование не возможно, но оставил, для спокойствия
      allServices = values(keyBy(allServices, 'id'));

      this.services.value = allServices;
    }

    return this.cloneData(this.services.value);
  }

  /**
   * Получает полный список услуг, сгруппированный по категориям услуг
   * ServiceCategory, не путать с CarCategory
   * 
   * @returns 
   */
  async getAllServicesCategories(): Promise<ServiceCategoriesCollectionItem[]> {
    if (!this.servicesCategories.value) {
      const thisPoint = await this.ctx.pointStore.getThisClient();
      const key = ['services_categories_all', thisPoint.id];
      const cache = await this.cacheQueryWithBloking(key, async () => {
        const { data } = await this.repositories.service.getServiceCategories({
          limit: 10000,
          softdelForall: true,
          clientPointId: thisPoint.id,
        });

        return data.items;
      }, 0);

      this.servicesCategories.value = cache.data;
    }

    return this.cloneData(this.servicesCategories.value) || [];
  }

  /**
   * Получить полный список номенклатур с категориями
   * 
   * @returns 
   */
  async getAllNomenclature(): Promise<NomenclatureCollectionItem[]> {
    if (!this.nomenclature.value) {
      const cache = await this.cacheQueryWithBloking(['nomenclatures_all'], async () => {
        const { data } = await this.repositories.service.getNomenclature();
        return data;
      }, 0);
  
      this.nomenclature.value = cache.data;
    }

    return this.cloneData(this.nomenclature.value) || [];
  }

  /**
   * Вернет проиндексированные группы услуг по CarCategory
   * 
   * @returns 
   */
  async getServicesCategoryIndex(): Promise<Record<string, ServiceCategoriesCollectionItem[]>> {
    if (!this.servicesCategoriesIndexByCarCategory.value) {
      const servicesCatigories = await this.getAllServicesCategories();
      this.servicesCategoriesIndexByCarCategory.value = this.actionIndexServicesCategoriesByCarCategory(servicesCatigories);
    }

    return this.cloneData(this.servicesCategoriesIndexByCarCategory.value);
  }

  /**
   * Вернет карту для поиска норматива по времени для услуги
   * 
   * NOTE: Для старых бэкендов, которые в информации о заказе
   * не подсчитывают время выполнения на сервере
   * 
   * @returns 
   */
  protected async getServicesTimingsMap(): Promise<Record<string, number>> {
    if (!this.servicesTimingsMap.value) {
      const services = await this.getAll();

      let timingsMap: Record<string, number> = {};

      for (const service of services) {
        if (!service.timings) continue;

        for (const timing of service.timings) {
          const timingKey = `${service.id}_${timing.priceCategory.id}`;
          timingsMap[timingKey] = timing.value;
        }
      }

      this.servicesTimingsMap.value = timingsMap;
    }

    return this.cloneData(this.servicesTimingsMap.value);
  }

  /**
   * Вернет проиндексированный и обработанный список категорий услуг (ServiceCategory), по категориям (CarCategory)
   * 
   * @param servicesCatigories 
   * @returns 
   */
  actionIndexServicesCategoriesByCarCategory(
    servicesCatigories: readonly ServiceCategoriesCollectionItem[]
  ): Record<string, ServiceCategoriesCollectionItem[]> {
    let indexByCarCategory: Record<string, ServiceCategoriesCollectionItem[]> = {};
    
    for (const serviceCategory of servicesCatigories) {
      if (!serviceCategory.services) continue;

      let servicesIndex: Record<string, ServiceCollectionItem[]> = {};
      for (const service of serviceCategory.services) {
        const pricesGroup = groupBy(service.prices, p => p.carCategory.id);

        for (const [categoryId, prices] of entries(pricesGroup)) {
          if (!servicesIndex[categoryId]) {
            servicesIndex[categoryId] = [];
          }

          servicesIndex[categoryId].push({
            ...cloneDeep(service),
            prices,
          });
        }
      }

      for (const [categoryId, services] of entries(servicesIndex)) {
        if (!indexByCarCategory[categoryId]) {
          indexByCarCategory[categoryId] = [];
        }

        indexByCarCategory[categoryId].push({
          ...cloneDeep(omit(serviceCategory, ['services'])),
          services: sortBy(services, 'sort'),
        });
      }
    }

    for (const [categoryId, serviceCategory] of entries(indexByCarCategory)) {
      indexByCarCategory[categoryId] = sortBy(serviceCategory, 'sort');
    }

    return indexByCarCategory;
  }

  /**
   * Логика, для применения статических дисконтных кампаний
   * 
   * @param carId 
   * @returns 
   */
  private async useCheckStaticDiscountCompaign(carId?: string) {
    const [ staticDiscountCampain, nomenclatures, carCache ] = await Promise.all([
      this.ctx.discountStore.getStaticAllCampain(),
      this.getAllNomenclature(),
      carId ? this.ctx.carStore.getCar(carId, { relevantTime: minutes(10) }) : Promise.resolve(null),
    ]);

    const forcedCampaigns = get(carCache, 'data.discountAccount.forcedCampaigns', []) as Discount[];
    const excludedCampaigns = get(carCache, 'data.discountAccount.excludedCampaigns', []) as Discount[];

    const {
      getForCategory,
      getForNomenclature,
      campainSuitable,
      priorityDiscountApplicableToAll
    } = useSuitableСompanies(
      toRaw(staticDiscountCampain) as StaticDiscountCampaign[],
      carId || '', // TODO: Неявное отсутсвие проверки по аккаунту
      map(forcedCampaigns, c => c.id),
      map(excludedCampaigns, c => c.id),
    );

    const nomenclaturesIndex = keyBy(toRaw(nomenclatures) as NomenclatureCollectionItem[], 'id');

    function getServiceStaticDiscountCampaign(service: ServiceCollectionItem) {
      if (isEmpty(campainSuitable)) return null;

      const nomenclatureId = get(service, 'nomenclature.id', 0) as number;
      const categoryId = get(nomenclaturesIndex, `${nomenclatureId}.category.id`, 0) as number;

      const categoryStaticDiscount = getForCategory(categoryId, priorityDiscountApplicableToAll);
      const nomenclatureStaticDiscount = getForNomenclature(nomenclatureId, categoryStaticDiscount);

      return cloneDeep(nomenclatureStaticDiscount);
    }

    /**
     * Задаст для списка услуг статические акции
     * 
     * @param allServices 
     */
    function applyServicesStaticDiscountCompaign(allServices: ServiceForForm[]) {
      for (const service of allServices) {
        const staticDiscount = getServiceStaticDiscountCampaign(service);
        if (!staticDiscount) continue;

        service.staticDiscount = staticDiscount;
      }
    }

    return {
      applyServicesStaticDiscountCompaign,
    };
  }

  /**
   * Формирует список групп с услугами, по заданным параметрам
   * 
   * @param params 
   * @returns 
   */
  async filterServicesCategoryByContext(params: ServicesCategoryByContextParams) {
    const servicesCategoriesIndex = await this.getServicesCategoryIndex();
    const servicesCategory = servicesCategoriesIndex[`${params.categoryId}`];
    if (!servicesCategory) return [];

    const loadAndApplyLink = (typeof params.loadAndApplyLink === 'boolean')
      ? params.loadAndApplyLink
      : !params.groupId; // По умолчанию для контрагентов не нужно загружать и применять скидки
    
    const pricesPolicysMap = params.groupId
      ? await this.ctx.pricePolicyStore.getIndexByGroupId(params.groupId) : null;

    const [ staticDiscountHelper, giftHelper, leveledHelper ] = loadAndApplyLink ? await Promise.all([
      this.useCheckStaticDiscountCompaign(params.carId),
      this.useCheckGiftCompaign(params.carId),
      this.useCheckLeveledCompaign(params.carId)
    ]) : [];

    let preparedServicesCategory: ServiceCategoriesForForm[] = [];

    for (const group of servicesCategory) {
      let preparedServises: ServiceForForm[] = [];

      for (const service of values(group.services)) {
        let preparedService = cloneDeep(service) as ServiceForForm;
  
        // В теории всегда должна оставаться одна цена,
        // но учитывая слоную структуру с политиками,
        // может возникнуть ситуация когда будет указано несколько цен
        let ctxPrices: ServiceCollectionPriceItem[] = [];
  
        for (const price of preparedService.prices) {
          // Такого быть не должно, но паранойя плохо поддается лечению
          if (price.carCategory.id != params.categoryId) continue;
  
          // ~~Цена не указана => услуга недоступна~~
          // FIX: Даже если цена == 0, ее нужно отображать.
          // Те значения где цена не указана не попадает в объект prices
          // if (!price.value) continue;
  
          if (price.pricePolicy) {
            if (pricesPolicysMap && pricesPolicysMap[price.pricePolicy.id]) {
              ctxPrices.push(price);
            }
          } else if (null == pricesPolicysMap) {
            ctxPrices.push(price);
          }
        }
  
        preparedService.prices = ctxPrices;
  
        // Услуги у которых не назначена цена, отображаться не будут
        if (ctxPrices.length === 0) continue;
        
        preparedServises.push(preparedService);
      }

      if (loadAndApplyLink) {
        staticDiscountHelper?.applyServicesStaticDiscountCompaign(preparedServises);
        giftHelper?.applyServicesGiftCompaign(preparedServises);
        leveledHelper?.applyServicesLeveledCompaign(preparedServises);
      }

      // Если в группе нет услуг, то ее не отображать
      if (preparedServises.length > 0) {
        preparedServicesCategory.push({
          ...cloneDeep(omit(group, ['services'])),
          services: preparedServises,
        });
      }
    }

    return preparedServicesCategory;
  }

  /**
   * Получение полной информации о услугах, на основе используемых значений заказа
   * 
   * @param providedServices 
   * @param groupId 
   * @param carId 
   * @param loadNoCacheDiscount требуется ли загружать некэшируемые данные скидок
   * @returns 
   */
  async getProvidedServicesInformation(
    providedServices: ProvideServiceBodyItem[],
    groupId?: number, 
    carId?: string,
    loadAndApplyLink?: boolean
  ): Promise<ProvidedServicesInformation[]> {
    const categoriesIds = uniq(providedServices.map(ps => ps.carCategory.id));
    const servicesGroups = flatten(
      await Promise.all(categoriesIds.map(
        categoryId => this.filterServicesCategoryByContext({
          categoryId,
          groupId,
          carId,
          loadAndApplyLink,
        })
      ))
    );
    const servicesContext = flatten(servicesGroups.map(g => g.services || []))
    const servicesContextIndexById = keyBy(servicesContext, 'id');

    return providedServices.map(item => ({
      service: servicesContextIndexById[`${item.service.id}`] || null,
      provide: item,
    }));
  }

  /**
   * Получить список подарочных кампаний для клиента
   * 
   * WARNING! Данные подгрузятся только в онлайн режиме, т.к. требуется
   * актуальная информация по ранее заказанным услугам для клиента.
   * 
   * @param carId 
   * @returns 
   */
  async getGiftCampaignsByCar(carId: string) {
    let campaigns: GiftCampaign[] = [];

    if (this.isOnline) {
      try {
        let { data } = await this.repositories.discount.getGiftCampaignsByCar({ carId });

        // FIXME: Нудно убрать, после нормального кодирования на сторне бэка
        campaigns = values(data).map(campaign => {
          return {
            id:               toNumber(campaign.id),
            name:             campaign.name,
            carId:            campaign.carId,
            qty:              toNumber(campaign.qty),
            targetQty:        toNumber(campaign.targetQty),
            nomenclatureId:   toNumber(campaign.nomenclatureId),
            nomenclatureName: campaign.nomenclatureName,
            gifts: values(campaign.gifts).map(gift => {
              return {
                id: toNumber(gift.id),
                name: gift.name || gift.nomenclatureName,
              } as GiftCampaignNomenclature;
            }),
          } as GiftCampaign;
        });
      } catch {
        console.error('Произошла непонятная ошибка, при получении порарочных компаний, далее в заказе они не будут учитываться');
        // NOTE: campaigns = [];
      }
    }
    
    return campaigns;
  }

  /**
   * Получить список накопительных кампаний для клиента
   * 
   * WARNING! Данные подгрузятся только в онлайн режиме, т.к. требуется
   * актуальная информация по ранее заказанным услугам для клиента.
   * 
   * @param carId 
   * @returns 
   */
  async getLeveledCampaignsByCar(carId: string) {
    let campaigns: LeveledCampaign[] = [];

    if (this.isOnline) {
      try {
        let { data } = await this.repositories.discount.getLeveledCampaignsByCar({ carId });

        // FIXME: Нудно убрать, после нормального кодирования на сторне бэка
        campaigns = values(data).map(campaign => {
          return {
            id:       toNumber(campaign.id),
            name:     campaign.name,
            carId:    campaign.carId,
            percent:  toNumber(campaign.percent),
            target:   toNumber(campaign.target),
            nomenclatures: values(campaign.nomenclatures).map(nomenclature => {
              return {
                id:   toNumber(nomenclature.id),
                name: nomenclature.name,
              } as LeveledCampaignNomenclature;
            }),
          } as LeveledCampaign;
        });
      } catch {
        console.error('Произошла непонятная ошибка, при получении накопительных компаний, далее в заказе они не будут учитываться');
        // NOTE: campaigns = [];
      }
    }
    
    return campaigns;
  }

  /**
   * Логика, для применения подарочных компаний
   * 
   * @param carId 
   * @returns 
   */
  protected async useCheckGiftCompaign(carId?: string) {
    let campaigns = carId ? await this.getGiftCampaignsByCar(carId) : [];

    const campaignsKeyByNomenclatureId = keyBy(campaigns, 'nomenclatureId');

    /**
     * Задаст для списка услуг подарочные акции
     * 
     * @param allServices 
     */
    function applyServicesGiftCompaign(allServices: ServiceForForm[]) {
      const servicesKeyByNomenclatuteId = keyBy(allServices, 'nomenclature.id');

      for (const service of allServices) {
        const campaign = campaignsKeyByNomenclatureId[service.nomenclature.id];
        if (!campaign) continue;

        // Не набрал еще необходимое кол-во покупок
        if (campaign.qty < campaign.targetQty) continue;

        service.giftCampaignFrom = campaign;

        for (const gift of campaign.gifts) {
          const giftNomenclature = servicesKeyByNomenclatuteId[gift.id];
          if (!servicesKeyByNomenclatuteId[gift.id]) continue;

          if (!giftNomenclature.giftCampaignSelf) {
            giftNomenclature.giftCampaignSelf = [];
          }

          giftNomenclature.giftCampaignSelf.push(campaign);
        }
      }
    }

    return {
      applyServicesGiftCompaign,
    };
  }

  /**
   * Логика, для применения накопительных компаний
   * 
   * @param carId 
   * @returns 
   */
  protected async useCheckLeveledCompaign(carId?: string) {
    const campaigns = carId ? await this.getLeveledCampaignsByCar(carId) : [];

    // Механизм индексации немного отличается от стандартного, оставляем только кампании
    // с наибольшим % скидки, если у разных кампаний пересекаются номенклатуры.
    const campaignsKeyByNomenclatureId: Dictionary<LeveledCampaign> = {};
    for (const campaign of campaigns) {
      for (const nomenclature of campaign.nomenclatures) {
        const existsIndexNomenclatute = campaignsKeyByNomenclatureId[nomenclature.id];
        if (existsIndexNomenclatute && existsIndexNomenclatute.percent > campaign.percent) {
          continue;
        }

        campaignsKeyByNomenclatureId[nomenclature.id] = campaign;
      }
    }

    /**
     * Задаст для списка услуг накопительные скидки
     * 
     * @param allServices 
     */
    function applyServicesLeveledCompaign(allServices: ServiceForForm[]) {
      for (const service of allServices) {
        const campaign = campaignsKeyByNomenclatureId[service.nomenclature.id];
        if (!campaign) continue;

        service.leveledDiscount = campaign;
      }
    }

    return {
      applyServicesLeveledCompaign,
    };
  }

  /**
   * Вернет нормативы времени для указанных услуг и категорий
   * 
   * @deprecated 
   * @param inList входной список с услугами и категориями
   * @returns 
   */
  async getTimings(inList: VisitServiceAndCategory[]): Promise<VisitTimingsForServiceAndCategory[]> {
    const timingsMap = await this.getServicesTimingsMap();

    return inList.map(item => {
      const time = timingsMap[`${item.serviceId}_${item.categoryId}`] || 0;

      return { ...item, time };
    });
  }

  /**
   * Рассчитывает нормативы, для оказываемых услуг
   * 
   * @param provideServices 
   * @returns 
   */
  async calcNormativesProvideService(provideServices: Array<CarVisitProvideService|ProvideServiceBodyItem>): Promise<number> {
    const timingsMap = await this.getServicesTimingsMap();

    return provideServices
      .map(ps => {
        const time = timingsMap[`${ps.service.id}_${ps.carCategory?.id}`] || 0;
        return time * (ps.qty || 1);
      })
      .reduce((s, n) => s + n, 0);
  }
}
