import { onMounted, onBeforeUnmount, ref } from 'vue';
import { CameraPreview, CameraPreviewOptions, CameraPreviewPictureOptions } from '@capacitor-community/camera-preview';

export interface UseCameraPreviewOptions {
  topOffset?: number;
  bottomOffset?: number;
  autostart?: boolean;
  autostop?: boolean;
  className?: string;
  parentId?: string;
}

export function useCameraPreview(options: UseCameraPreviewOptions = {}) {
  const isInited = ref(false);

  function getOptions(): CameraPreviewOptions {
    return {
      position: 'rear',
      x: 0,
      y: (options.topOffset || 0),
      // width: window.screen.width,
      // height: window.screen.height - (options.topOffset || 0) - (options.bottomOffset || 0),
      paddingBottom: options.bottomOffset || 0,
      parent: options.parentId,
      className: options.className,
      disableAudio: true,
      toBack: true,
      // rotateWhenOrientationChanged: false,
      disableExifHeaderStripping: true,
      // lockAndroidOrientation: true,
    };
  }

  async function startPreview() {
    try {
      await CameraPreview.start(getOptions());
      isInited.value = true;
      document.body.classList.add('body-transparent');
    } catch (e) {
      console.error(e);
    }
  }

  async function stopPreview() {
    if (isInited.value === false) return;
    document.body.classList.remove('body-transparent');

    try {
      await CameraPreview.stop();
    } finally {
      isInited.value = false;
    }
  }

  async function capture(options: CameraPreviewPictureOptions = {}) {
    const result = await CameraPreview.captureSample({
      quality: 95,
      ...options,
    });

    return result.value as string;
  }

  onMounted(() => {
    if (options.autostart) {
      startPreview();
    }
  });

  onBeforeUnmount(() => {
    if (options.autostop) {
      stopPreview();
    }
  });

  return {
    startPreview,
    stopPreview,
    capture,
    isInited,
  };
}