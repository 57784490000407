
import { defineComponent, toRef, computed, PropType, ref, watch, toRaw, getCurrentInstance, nextTick } from 'vue';
import SheduleGridLayout from '@/components/layout/SheduleGridLayout.vue';
import { usePreentryShedule, TimeGridStyle, EmptyTimeGrid } from '@/composables/usePreentry';
import { useToast } from '@/composables/useToast';
import { find, findIndex, toNumber, round } from 'lodash';
import { shiftsSmoothingValue } from '@/helpers/shifts';
import { datetimeToTimeNumber, currentTimeMoment, MINUTES_DAY_NUMBER } from '@/helpers/datetime';
import { createGesture, GestureDetail, Gesture } from '@ionic/vue';
import { Box } from '@/repositories/Models/Box';
import { CarVisitCollectionItem } from '@/repositories/Models/CarVisit';
import { useStore } from '@/composables/useApp';

export interface PointerMoveInfo {
  currentEmpty: TimeGridStyle;
  currentEmptyEl: HTMLElement;
  pointerRowStart: number;
}

export interface PreentryValue {
  boxId: number;
  datetime: Date;
}

export interface PreentryGridPointer {
  style: TimeGridStyle;
  timeIndex: number;
  boxIndex: number;
}

export interface PointerInfo {
  box: Box;
  time: number;
  date: Date;
}

export interface PointerRect {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
  x: number;
  y: number;
  offsetLeft: number;
  offsetTop: number;
  container: {
    scrollHeight: number;
    bottom: number;
    height: number;
    left: number;
    right: number;
    top: number;
    width: number;
    x: number;
    y: number;
  };
}

export default defineComponent({
  components: {
    SheduleGridLayout,
  },

  props: {
    date: {
      type: null,
    },

    /**
     * Норматив времени в минутах, для отображения
     * доступных областей для записи.
     */
    normative: {
      type: Number,
      default: 0,
    },

    modelValue: {
      type: [Object, null] as PropType<PreentryValue|null>,
      default: null,
    },
    
    selectEnable: {
      type: Boolean,
      default: false,
    },

    stepMinutes: {
      type: Number,
      default: 15,
    },

    /** 
     * Автоматически обносить визиты в случае если изменился
     * глобальный счетчик новых заказов и в процессе.
     */
    visitsAutoSync: {
      type: Boolean,
      default: false,
    }
  },

  setup(props, { emit }) {
    const toast = useToast();
    const instance = getCurrentInstance();
    const currenDate = ref(new Date());
    const store = useStore();
    
    const date = toRef(props, 'date');
    const normative = toRef(props, 'normative');

    const {
      boxes,
      timeLine,
      loading,
      visitsSheduleGrid,
      emptySheduleGrid,
      preparedDate,
      beetweenDate,
      minmaxMinutes,
      isCurrentDate,
      loadVisits,
    } = usePreentryShedule(date, {
      autoload: true,
      autoupdateChangeDate: true,
      normative,
      stepMinutes: props.stepMinutes,
      disableBeforeData: props.selectEnable ? currenDate : undefined,
    });

    // Включено автоматическое обновление визитов,
    // при изменении данных счетчика заказов.
    if (props.visitsAutoSync) {
      const visitsCounters = store.visit.getCountersRef();
      let emptyInited = !!visitsCounters.value.emptyInited;

      watch(visitsCounters, () => {
        // Чтобы при первом запуске не делать лишний повторный запрос 
        if (emptyInited === true) {
          emptyInited = false;
          return;
        }

        loadVisits();
      }, { deep: true });
    }

    function prepareValue(value: any): PreentryValue|null {
      if (!(value?.datetime instanceof Date) || !value?.boxId) return null;

      const boxId = toNumber(value.boxId);
      if (!find(boxes.value, box => box.id === boxId)) return null;

      const { from, to } =  beetweenDate.value;
      const datetime = value.datetime as Date;
      if (datetime < from || datetime > to) return null;

      return { boxId, datetime };
    }

    const lazyValue = ref<PreentryValue|null>(prepareValue(props.modelValue));

    const innerValue = computed<PreentryValue|null>({
      get() {
        return lazyValue.value;
      },
      set(value) {
        lazyValue.value = value;
        emit('update:modelValue', lazyValue.value);
      }
    });

    watch([toRef(props, 'modelValue'), beetweenDate, boxes], ([value]) => {
      if (toRaw(lazyValue.value) !== toRaw(value)) {
        lazyValue.value = prepareValue(value);
      }
    });

    const gridPointerHeight = computed(() => Math.max(1, Math.ceil(props.normative / props.stepMinutes)));
    
    const gridPointer = computed<PreentryGridPointer|null>(() => {
      if (!lazyValue.value) return null;

      const boxId = lazyValue.value.boxId;
      const timeValue = shiftsSmoothingValue(datetimeToTimeNumber(lazyValue.value.datetime), props.stepMinutes);
      const timeIndex = findIndex(timeLine.value, t => t.timeNumber === timeValue);
      const boxIndex = findIndex(boxes.value, b => b.id === boxId);

      if (timeIndex < 0 || boxIndex < 0) return null;

      return {
        boxIndex,
        timeIndex,
        style: {
          gridRowStart: timeIndex + 1,
          gridRowEnd: (timeIndex + 1) + gridPointerHeight.value,
          gridColumnStart: boxIndex + 1,
          gridColumnEnd: boxIndex + 2,
        },
      };
    });

    watch(gridPointer, gp => {
      let info: PointerInfo|null = gp
        ? {
            box: boxes.value[gp.boxIndex],
            time: timeLine.value[gp.timeIndex].minutesBeginDay,
            date: preparedDate.value,
          }
        : null;

      emit('pointer-info', info);
    });

    const showPointer = computed(() => props.selectEnable && !!gridPointer.value);

    const onClickPointer = (ev: PointerEvent) => {
      ev.stopPropagation();
      // innerValue.value = null;
    };

    const onClickGrid = (ev: PointerEvent) => {
      ev.stopPropagation();
      // innerValue.value = null;
    };

    const onClickFreeTime = (ev: PointerEvent, g: EmptyTimeGrid) => {
      ev.stopPropagation();

      if (!props.selectEnable || g.expired) return;

      const target = ev.target as HTMLDivElement;

      const stepValue = target.clientHeight / (g.gridRowEnd - g.gridRowStart);
      const rowOffset = Math.ceil(ev.offsetY / stepValue) - 1;
      const timeIndex = Math.max(g.gridRowStart, Math.min(g.gridRowEnd - gridPointerHeight.value, rowOffset + g.gridRowStart)) - 1;
      const boxIndex = g.gridColumnStart - 1;

      const box = boxes.value[boxIndex];
      const time = timeLine.value[timeIndex];

      if (!box || !time) {
        return toast.error('Failed to calculate the place in the schedule, please contact the administrator');
      }

      const datetime = new Date(preparedDate.value);
      datetime.setMinutes(time.minutesBeginDay);

      innerValue.value = {
        boxId: box.id,
        datetime,
      };

      nextTick(emitPointerRect);
    }

    function emitPointerRect() {
      let rect: PointerRect|null = null;

      if (pointerRef.value && pointerRef.value.offsetParent) {
        const boundingClientRect = pointerRef.value.getBoundingClientRect().toJSON();
        const containerRect = pointerRef.value.offsetParent.getBoundingClientRect().toJSON();
        
        rect = {
          ...boundingClientRect,
          offsetLeft: pointerRef.value.offsetLeft,
          offsetTop: pointerRef.value.offsetTop,
          container: {
            scrollHeight: pointerRef.value.offsetParent.scrollHeight,
            ...containerRect
          },
        }
      }

      emit('pointer-rect', rect);
    }

    //#region Жесты, для перемещения
    function onMovePointer(ev: GestureDetail) {
      if (!lazyValue.value || !pointerStartMoveInfo.value) return;

      const {
        currentEmptyEl,
        pointerRowStart,
        currentEmpty: {
          gridRowStart,
          gridRowEnd,
        }
      } = pointerStartMoveInfo.value;

      const stepValue = currentEmptyEl.clientHeight / (gridRowEnd - gridRowStart);
      const rowOffset = Math.round(ev.deltaY / stepValue);

      const moveRowStart = rowOffset + pointerRowStart;
      const edgeConstraintRowStart = Math.max(gridRowStart, Math.min(gridRowEnd - gridPointerHeight.value, moveRowStart));

      if (gridPointer.value?.style.gridRowStart === edgeConstraintRowStart) {
        return; // Если ячейка не изменилась, далее ничего считать не будем
      }

      const time = timeLine.value[edgeConstraintRowStart - 1];
      if (!time) return; // Такого быть не должно

      const datetime = new Date(preparedDate.value);
      datetime.setMinutes(time.minutesBeginDay);

      lazyValue.value.datetime = datetime;

      nextTick(emitPointerRect);
    }

    /**
     * Содержит информацию необходимую, для определения
     * ограничений, при перемещении указателя жестом
     */
    const pointerStartMoveInfo = ref<PointerMoveInfo|null>(null);
    function getPointerStartMoveInfo(): PointerMoveInfo|null {
      if (!gridPointer.value || !lazyValue.value) return null;

      const colIndex = gridPointer.value.boxIndex + 1;
      const rowIndex = gridPointer.value.timeIndex + 1;

      const currentEmpty = find(emptySheduleGrid.value, item => {
        return (item.gridColumnStart === colIndex)
          && (rowIndex >= item.gridRowStart && rowIndex < item.gridRowEnd); // TODO: check
      });

      if (!currentEmpty) return null;

      const rootEl = instance?.vnode.el as HTMLElement|undefined;
      const emptyItemKey = `${currentEmpty.gridColumnStart}_${currentEmpty.gridRowStart}`;
      const currentEmptyEl = rootEl?.querySelector<HTMLElement>(`[data-empty-key="${emptyItemKey}"]`);
      const pointerRowStart = gridPointer.value.style.gridRowStart;

      if (!currentEmptyEl) return null;

      return {
        currentEmpty,
        currentEmptyEl,
        pointerRowStart,
      };
    }

    function onStartPointer(/*ev: GestureDetail*/) {
      pointerStartMoveInfo.value = getPointerStartMoveInfo();
    }
    
    let gesturePointer: Gesture|null = null;
    const pointerRef = ref<HTMLDivElement|null>(null);
    watch(pointerRef, el => {
      if (gesturePointer) {
        gesturePointer.destroy();
        gesturePointer = null;
      }

      if (!props.selectEnable) return;
      if (!el) return;

      gesturePointer = createGesture({
        el,
        threshold: 15,
        direction: 'y',
        gestureName: 'pointer-move',
        onMove: onMovePointer,
        onStart: onStartPointer
      });

      gesturePointer.enable();
    });
    //#endregion

    function onClickVisit(visit: CarVisitCollectionItem) {
      emit('click-visit', visit);
    }

    const currentTimePointerTop = computed<string|null>(() => {
      if (timeLine.value.length === 0) return null;

      // Время пройденное в минутах с начала ДНЯ
      const minutes = datetimeToTimeNumber(currentTimeMoment.value.toDate());

      // Время пройденное в минутах с начала первой СМЕНЫ
      const minutesStartShift = (minutes > minmaxMinutes.value.min)
        ? minutes - minmaxMinutes.value.min
        : (minutes + MINUTES_DAY_NUMBER) - minmaxMinutes.value.min;

      const shiftDayMinutes = (minmaxMinutes.value.max - minmaxMinutes.value.min)
        // Fix: из-за особенности отображения краев расписания, первая
        // ячейка по сути является последней и при (max - min) не учитывается
        // Но для отображения полоски текущего времени, это смещение необходимо учесть
        + props.stepMinutes;

      const offsetTopPercent = round(minutesStartShift / shiftDayMinutes * 100, 5);

      return `${offsetTopPercent}%`;
    });

    /**
     * Визиты со статусом Processed не должны визуально отображаться
     * NOTE: Незапланированная доработка
     */
    // const visitsSheduleGridView = computed(() => {
    //   return visitsSheduleGrid.value
    //     .filter(visit => visit.visit.status !== CarVisitStatusEnum.Processed);
    // });

    return {
      loading,
      boxes,
      timeLine,
      // visitsSheduleGridView,
      visitsSheduleGrid,
      emptySheduleGrid,
      showPointer,
      gridPointer,
      onClickPointer,
      onClickGrid,
      onClickFreeTime,
      pointerRef,
      onClickVisit,
      currentTimePointerTop,
      isCurrentDate,
    };
  }
});
