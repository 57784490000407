
import { useStore } from '@/composables/useApp';
import { CarGroupCollectionItem } from '@/repositories/Models/CarGroup';
import { modalController } from '@ionic/vue';
import { defineComponent, onMounted, ref, DeepReadonly, PropType } from 'vue';
import CModalSelectOption from '../core/CModalSelectOption.vue';
import { SetGroupValueCallback } from './GroupPartnerInputCard.vue';
import { filterSerchWords } from '@/helpers/filterEntities';

// TODO: Избавиться от данного компонента в пользу общего
export default defineComponent({
  components: { CModalSelectOption },
  props: {
    selectValue: {
      type: Function as PropType<SetGroupValueCallback>,
      required: false,
    },
    value: {
      type: [String, Number] as PropType<string|number>,
      default: ''
    },
  },
  setup(props) {
    const { group } = useStore();
    const list = ref<DeepReadonly<CarGroupCollectionItem[]>>([]);
    const loading = ref(false);
    const innerValue = ref(props.value);

    async function loadGroupsPartners() {
      loading.value = true;

      try {
        list.value = await group.getAll();
        onSearchChange();
      } finally {
        loading.value = false;
      }

      // TODO: Обработать ошибку
    }

    function clickItem(group?: CarGroupCollectionItem) {
      const selectedGroupId = group ? group.id : '';
      innerValue.value = selectedGroupId;

      if (props.selectValue) {
        props.selectValue(selectedGroupId, group);
      }

      modalController.dismiss();
    }

    //#region Search (Filter)
    const searchWords = ref('');
    const listFiltered = ref<DeepReadonly<CarGroupCollectionItem[]>>([]);
    function onSearchChange() {
      const s = searchWords.value.trim();
      if (!s) {
        listFiltered.value = list.value;
        return;
      }

      listFiltered.value = filterSerchWords([ ...list.value], 'name', searchWords.value);
    }
    //#endregion

    onMounted(() => loadGroupsPartners());

    return {
      list,
      innerValue,
      clickItem,
      onSearchChange,
      searchWords,
      listFiltered,
      loading,
    };
  },
});
