import { BaseRepository, AsyncResponse } from './BaseRepository';
import {
  ThisClientPointResponse,
  ThisClientQuery,
  ShiftsPointQuery,
  ShiftsPointResponse
} from './Models/Point';

export class PointRepository extends BaseRepository {
  /**
   * Получить общую информацию о текущем автосервисе,
   * точках обслуживания в нем и категориях услуг в данных точках
   * 
   * @param params 
   * @returns 
   */
  getThisClient(params: ThisClientQuery = {}): AsyncResponse<ThisClientPointResponse> {
    return this.client.get('this-client-point', { params });
  }

  /**
   * Получить информацию о расписании смен
   * 
   * @param params 
   * @returns 
   */
  getShiftsPoint(params: ShiftsPointQuery = {}): AsyncResponse<ShiftsPointResponse> {
    return this.client.get('shifts', { params });
  }
}