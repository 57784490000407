import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vShow as _vShow, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "service-view-item__price-info",
  slot: "end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_cc = _resolveComponent("cc")!
  const _component_c_spoiler = _resolveComponent("c-spoiler")!
  const _component_c_content_loading = _resolveComponent("c-content-loading")!

  return (_openBlock(), _createBlock(_component_c_content_loading, {
    ref: "loadComponentRef",
    action: _ctx.loadAction
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_item, { class: "color-default" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.titleGroup), 1)
        ]),
        _: 1
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.servicesCategoriesFiltered, (servicesCategory) => {
        return (_openBlock(), _createBlock(_component_c_spoiler, {
          key: servicesCategory.id,
          expand: false,
          title: servicesCategory.name
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(servicesCategory.services, (item) => {
              return _withDirectives((_openBlock(), _createBlock(_component_ion_item, {
                class: "service-view-item ion-align-items-start",
                key: item.service.id
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.service.name), 1),
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_cc, {
                        price: _ctx.get(item.service, 'prices.0.value'),
                        "empty-display": "-"
                      }, null, 8, ["price"])
                    ])
                  ])
                ]),
                _: 2
              }, 1024)), [
                [_vShow, item.show]
              ])
            }), 128))
          ]),
          _: 2
        }, 1032, ["title"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["action"]))
}