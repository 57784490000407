import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, createBlock as _createBlock, withDirectives as _withDirectives, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-cat-id"]
const _hoisted_2 = {
  key: 0,
  class: "c-services-category-group__collapse-arrow-wrap"
}
const _hoisted_3 = { class: "c-services-category-group__services-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_service_provide_item = _resolveComponent("service-provide-item")!
  const _component_c_spoiler = _resolveComponent("c-spoiler")!
  const _component_c_content_loading = _resolveComponent("c-content-loading")!
  const _component_collapse_transition = _resolveComponent("collapse-transition")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
    'c-services-category-group': true,
    'c-services-category-group--content-collapsed': !_ctx.showContentItems
  }),
    "data-cat-id": _ctx.context.categoryId
  }, [
    (_ctx.showTitle)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "c-services-category-group__title",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleContentItems && _ctx.toggleContentItems(...args)))
        }, [
          _createTextVNode(_toDisplayString(_ctx.titleGroup) + " ", 1),
          (_ctx.isCollapsedContent)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                _createVNode(_component_ion_icon, { name: "core-collapse-arrow" })
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_collapse_transition, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_c_content_loading, { action: _ctx.loadAction }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.servicesCategoriesFiltered, (servicesCategory) => {
                return (_openBlock(), _createBlock(_component_c_spoiler, {
                  key: servicesCategory.id,
                  expand: false,
                  "onUpdate:show": ($event: any) => (_ctx.$emit('toggle-services-group', servicesCategory, $event)),
                  title: servicesCategory.name
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(servicesCategory.services, (item) => {
                      return _withDirectives((_openBlock(), _createBlock(_component_service_provide_item, {
                        key: item.service.id,
                        service: item.service,
                        provided: _ctx.servicesSelected[item.service.id],
                        "onUpdate:provided": ($event: any) => ((_ctx.servicesSelected[item.service.id]) = $event)
                      }, null, 8, ["service", "provided", "onUpdate:provided"])), [
                        [_vShow, item.show]
                      ])
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["onUpdate:show", "title"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["action"])
        ])
      ]),
      _: 1
    }, 512), [
      [_vShow, _ctx.showContentItems]
    ])
  ], 10, _hoisted_1))
}