import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "c-calendar-mounth" }
const _hoisted_2 = { class: "c-calendar-mounth__header" }
const _hoisted_3 = { class: "c-calendar-mounth__weeks-grid" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "c-calendar-mounth__inner-date-number" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.mounthGridInfo.textMounth) + ", " + _toDisplayString(_ctx.mounthGridInfo.textYear), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mounthGridInfo.calendar, (dateInfo) => {
        return (_openBlock(), _createElementBlock("div", {
          key: dateInfo.date,
          onClick: ($event: any) => (_ctx.onClickDate(dateInfo)),
          style: _normalizeStyle({
          gridColumn: dateInfo.column,
          gridRow: dateInfo.row,
        }),
          class: _normalizeClass({
          'c-calendar-mounth__date-cell': true,
          'c-calendar-mounth__date-cell--day-off': dateInfo.dayOff,
          'c-calendar-mounth__date-cell--today': dateInfo.today,
          'c-calendar-mounth__date-cell--before-now': dateInfo.beforeNow,
          'c-calendar-mounth__date-cell--selected': (dateInfo.timestamp === _ctx.innerValueTimeshtamp),
        })
        }, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(dateInfo.date), 1)
        ], 14, _hoisted_4))
      }), 128))
    ])
  ]))
}