
import { modalController } from '@ionic/vue'
import { filter, isEqual, keyBy } from 'lodash'
import { defineComponent, PropType, ref, watch, computed } from 'vue'

export type ModalSelectSetValueCallback<V = any, I = any> = (value: V, option?: I) => void;

export interface ModalSelectOptions<V = any, I = any> {
  modalTitle?: string;
  propValue?: string;
  propText?: string;
  multiselect?: boolean;
  value?: V;
  setValue?: ModalSelectSetValueCallback<V, I>;
  options?: I[];
  autoclose?: boolean;
}

const CModalViewSelect = defineComponent({
  props: {
    modalTitle: {
      type: String,
      default: 'Выберите значение'
    },
    propValue: {
      type: String,
      default: 'value',
    },
    propText: {
      type: String,
      default: 'text',
    },
    multiselect: {
      type: Boolean,
    },
    options: {
      type: Array as PropType<Record<string, any>[]>,
      default: () => [],
    },
    value: null,
    setValue: {
      type: Function as PropType<ModalSelectSetValueCallback>,
      required: false,
    },
    autoclose: {
      type: Boolean,
      default: true,
    },
    innerItemComponent: {
      type: [String, Object],
      required: false
    }
  },
  setup(props) {
    const optionsIndex = computed(() => keyBy(props.options, props.propValue));
    const innerValue = ref<Set<any>>(preparedValue(props.value));
    watch(() => props.value, newValue => {
      if (false === isEqual(newValue, [ ...innerValue.value])) {
        innerValue.value = preparedValue(newValue);
      }
    });

    function preparedValue(value: any): Set<any> {
      if (!props.multiselect) {
        return new Set([value]);
      }
      
      return Array.isArray(value)
        ? new Set(filter(value, valueItem => optionsIndex.value[valueItem] !== undefined))
        : new Set([]);
    }

    function toggleOption(option: any) {
      const toggleValue = option[props.propValue];

      if (!props.multiselect) {
        const isSelected = innerValue.value.has(toggleValue);
        innerValue.value.clear();

        if (!isSelected) {
          innerValue.value.add(toggleValue);
        }

        props.setValue?.call(null, isSelected ? null : toggleValue, isSelected ? null : option);

        if (props.autoclose) {
          modalController.dismiss();
        }

        return; // exit
      }

      if (innerValue.value.has(toggleValue)) {
        innerValue.value.delete(toggleValue);
      } else {
        innerValue.value.add(toggleValue);
      }

      props.setValue?.call(null, [ ...innerValue.value]);
    }

    function isSelected(value: any) {
      return innerValue.value.has(value);
    }

    return {
      toggleOption,
      isSelected,
    };
  },
});

export function createSelectModal<V = any, I = any>(props: ModalSelectOptions<V, I> = {}) {
  return modalController.create({
    cssClass: 'core-modal-actions',
    component: CModalViewSelect,
    componentProps: { ...props },
    swipeToClose: true,
  });
}

export async function openSelectModal<V = any, I = any>(props: ModalSelectOptions<V, I> = {}) {
  const modal = await createSelectModal<V, I>(props);
  await modal.present();
  return modal;
}

export default CModalViewSelect;
