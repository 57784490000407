
import { computed, defineComponent, getCurrentInstance, nextTick, onMounted, shallowRef, onActivated } from 'vue';
import CCalendarMounth from './CCalendarMounth.vue';
import moment, { Moment } from 'moment';
import { throttle } from 'lodash';
import { useModelSync } from '@/composables/useModelSync';

export interface CalendarProps {
  mounth: number;
  year: number;

  /** Указатель, что это текущий месяц */
  currentMounth: boolean;

  /** Указатель на текущий point */
  currentPoint: boolean;
}

const INFINITE_CALENDAR_MOBILE_RENDER_COUNT = 3;
const INFINITE_CALENDAR_EDGE_Y_OFFSET_PX = 300;

export default defineComponent({
  components: { CCalendarMounth },
  props: {
    modelValue: {
      type: null,
    },
    startPoint: {
      type: null,
    }
  },
  setup(props) {
    const currentPoint = shallowRef(moment(props.startPoint));
    const { innerValue } = useModelSync(props, 'modelValue');
    const now = moment();

    //#region Generate mounth calendars on scroll
    function generateCalendarsPropsList(date: Moment) {
      let calendarsProps: CalendarProps[] = [];
      const start = date.clone().subtract(INFINITE_CALENDAR_MOBILE_RENDER_COUNT, 'month');
      const end = date.clone().add(INFINITE_CALENDAR_MOBILE_RENDER_COUNT, 'month');

      for (let curr = start.clone(); curr.isSameOrBefore(end); curr.add(1, 'month')) {
        calendarsProps.push({
          mounth: curr.month(),
          year: curr.year(),
          currentMounth: now.isSame(curr, 'date'),
          currentPoint: currentPoint.value.isSame(curr, 'date'),
        });
      }

      return calendarsProps;
    }

    const calendarsMounts = computed(() => generateCalendarsPropsList(currentPoint.value));

    /** Сохраняем скролл, чтобы при активации восстановить прокрутку */
    let savedScrollTop = 0;

    /**
     * При скролле добавляем/удаляем лишние месяцы
     * с корректировкой скролла в случае добавления.
     */
    function onScroll(ev: Event) {
      const scrollContainerEl = ev.target as HTMLDivElement;
      const firstCalendarEl = scrollContainerEl.firstElementChild as HTMLDivElement;
      const lastCalendarEl = scrollContainerEl.lastElementChild as HTMLDivElement;

      const st = scrollContainerEl.scrollTop;
      const dt = st - firstCalendarEl.offsetTop;
      const db = (lastCalendarEl.offsetTop + lastCalendarEl.clientHeight) - (st + scrollContainerEl.clientHeight);

      let watchChangeOffsetTopEl: HTMLDivElement|null = null;

      if (dt < INFINITE_CALENDAR_EDGE_Y_OFFSET_PX) {
        currentPoint.value = currentPoint.value.clone().subtract(INFINITE_CALENDAR_MOBILE_RENDER_COUNT, 'month');
        watchChangeOffsetTopEl = firstCalendarEl;
      } else if (db < INFINITE_CALENDAR_EDGE_Y_OFFSET_PX) {
        currentPoint.value = currentPoint.value.clone().add(INFINITE_CALENDAR_MOBILE_RENDER_COUNT, 'month');
        watchChangeOffsetTopEl = lastCalendarEl;
      }

      let watchChangeOffsetTopValue: number = watchChangeOffsetTopEl?.offsetTop || 0;

      nextTick(() => {
        // NOTE: В хроме в целом необязательно, но мозилла глючит при скролле наверх
        if (watchChangeOffsetTopEl) {
          const newOffsetTop = watchChangeOffsetTopEl.offsetTop;
          const dOffsetTop = (newOffsetTop - watchChangeOffsetTopValue);

          scrollContainerEl.scrollTop = st + dOffsetTop;
        }

        savedScrollTop = scrollContainerEl.scrollTop;
      });
    }

    const onScrollTrottle = throttle(onScroll, 100);
    //#endregion

    //#region Correct scrollTop
    const instance = getCurrentInstance();

    function getScrollContainer(): HTMLDivElement|null {
      return instance?.vnode.el as HTMLDivElement|null|undefined || null;
    }

    /**
     * Выравниваем по центру календарь на который указывает currentPoint
     */
    function currentPointCenterAlign() {
      const scrollContainerEl = getScrollContainer();
      if (!scrollContainerEl) return;

      const currentCalendarEl: HTMLDivElement|null = scrollContainerEl.querySelector('.c-infinity-calendar__mounth--point');
      if (!currentCalendarEl) return;

      const calcScrollTop = currentCalendarEl.offsetTop - ((scrollContainerEl.clientHeight - currentCalendarEl.clientHeight) / 2);
      scrollContainerEl.scrollTop = Math.round(calcScrollTop);
      savedScrollTop = scrollContainerEl.scrollTop;
    }

    /**
     * Восстанавливаем позицию скролла из ранее сохраненного значения
     */
    function restoreScroll() {
      if (!savedScrollTop) return;

      const scrollContainerEl = getScrollContainer();
      if (!scrollContainerEl) return;

      scrollContainerEl.scrollTop = savedScrollTop;
    }

    onMounted(() => {
      // FIXME: При первоначальной загрузки не срабатывает, но нормально
      // работает при создании компонента в уже загруженном приложении.
      // fix: setTimeout(currentPointCenterAlign, 150); - но что-то не хочется
      
      currentPointCenterAlign();
    });

    // keep-alive restore scroll position
    onActivated(restoreScroll);
    //#endregion

    return {
      calendarsMounts,
      onScrollTrottle,
      innerValue,
      currentPointCenterAlign,
    };
  },
});
