
import { defineComponent } from 'vue';

// function sleep(ms: number): Promise<void> {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve();
//     }, ms);
//   });
// }

export default defineComponent({
  setup() {
    return {};
  }
});
