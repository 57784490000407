
import { computed, defineComponent, ref, Ref, shallowRef, watch, provide, reactive } from 'vue';
import { useStore } from '@/composables/useApp';
import { CarVisitBodyRequest, CarVisitCheckupEnum, CarVisitResponse, CarVisitStatusEnum, ProvideServiceBodyItem } from '@/repositories/Models/CarVisit';
import { useRoute, useRouter } from 'vue-router';
import { includes } from 'lodash';
import VisitCard, { VisitCardShortInfo } from '@/components/visit/VisitCard.vue';
import ClientInfoCard from '@/components/car/ClientInfoCard.vue';
import ServicesProvidedViewCard from '@/components/service/provide-view/ServicesProvidedViewCard.vue';
import VisitShortInfo from '@/components/visit/VisitShortInfo.vue';
import { getPaymentTypeTitle } from '@/helpers/visit';
import { PaymentTypeEnum } from '@/repositories/Models/Pos';
import { modalController } from '@ionic/vue';
import { useToast } from '@/composables/useToast';
import { awaitLoading } from '@/ext/loading';
import ServicesAddCategoryGroupModal from '@/components/service/add-category-modal/ServicesAddCategoryGroupModal.vue';
import { VisitTypeAndCategory } from '@/helpers/visit';
import {
  convertProvidedServicesToBodyItems,
  provideServicesBodyGroupToList,
  mapProvideServicesBodyItemsForGroup,
  ProvideServicesBodyItemsGroup,
  prepareProvidedServicesBodyItems,
} from '@/helpers/visit';
import { isTabletDown } from '@/helpers/adaptive';
import PaymentTypeModal from '@/components/pos/PaymentTypeModal.vue';
import { useNavManager } from '@/composables/useNavManager';
import { useMetrica } from '@/composables/useMetrica';
import { useAlert } from '@/composables/alert';
import { useI18n } from 'vue-i18n';
import { getEmptyNewVisitState, NewVisitSatate } from '@/store/NewVisitState';
import { useVisitCounterparty } from '@/composables/visit';

export interface ModalServicesContext {
  providedServicesGroupsValues: Ref<ProvideServicesBodyItemsGroup>;
  visitState: NewVisitSatate;
}

// TODO: Логику в данном файле нужно разнести по composables

export default defineComponent({
  components: {
    VisitCard,
    ClientInfoCard,
    ServicesProvidedViewCard,
    VisitShortInfo,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const navManager = useNavManager();
    const { emitEvent } = useMetrica();
    const { createAlertConfirm } = useAlert();
    const { t } = useI18n();

    //#region Load Visit & other info
    const visitId = route.params.visitId as string;
    const visit = ref<CarVisitResponse|null>(null);
    const isCahier = ref(false);
    const isPreentry = computed(() => {
      return visit.value?.status === CarVisitStatusEnum.New
        && visit.value?.checkup === CarVisitCheckupEnum.PendingPreentry;
    });
    const eventPrefix = computed(() => isPreentry.value ? 'visit/preentry' : 'visit');

    provide('visit', visit);

    async function loadCahier() {
      isCahier.value = await store.user.isCahier();
    }

    async function loadVisit() {
      const { data } = await store.visit.getCarVisit(visitId);
      visit.value = data;
    }

    /** @private Обновить данные заказа с отображение полноэкранной загрузки */
    async function updateVisit(body: CarVisitBodyRequest) {
      await awaitLoading(async () => {
        let { response } = await store.visit.update(visitId, body);

        if (response) {
          visit.value = response.data;
        } else {
          await loadVisit();
        }
      }, {});
    }

    // TODO: Переписать с использованием awaitLoading
    /** Обновить информацию о визите в фоне */
    function reloadVisitBackground() {
      return awaitLoading(() => loadVisit(), {
        message: t('views.visit_single.update_visit_info_backgroung'),
      });
    }

    function load() {
      return Promise.all([
        loadVisit(),
        loadCahier(),
      ]);
    }

    const visitsCounters = store.visit.getCountersRef();
    let emptyInited = !!visitsCounters.value.emptyInited;
    const syncReloadOff = watch(visitsCounters, async () => {
      // Чтобы при первом запуске не делать лишний повторный запрос 
      if (emptyInited === true) {
        emptyInited = false;
        return;
      }

      // По сути обновление выполнется, только после того, как:
      // 1) заказ был создан в режиме оффлайн ->
      // 2) была открыта данная страница ->
      // 3) произошло соединение с сервером и синхронизация ->
      // 4) обновляем данные заказа получаемые с сервера а не из кэша.
      if (visit.value?.isOffline) {
        await reloadVisitBackground(); // async
        if (visit.value?.isOffline) return;
      }

      syncReloadOff();
    }, { deep: true });
    //#endregion

    const paymentTypeTitle = computed(() => visit.value ? getPaymentTypeTitle(visit.value.paymentType || PaymentTypeEnum.Cash) : '');

    //#region Завершить обслуживание
    // Возможность закрыть заказ
    const oppCloseVisit = computed(() => includes([
      CarVisitStatusEnum.Processed,
      CarVisitStatusEnum.Opened,
    ], visit.value?.status));

    /** @click Действие закрытия заказа */
    function closeVisitAction() {
      alertConfirmCloseVisit(); // async
    }

    /** Окно для подтверждения закрытия закза */
    const alertConfirmCloseVisit = createAlertConfirm({
      header: t('views.visit_single.alert_close_visit_header'),
      message: t('views.visit_single.alert_close_visit_message'),
      async confirm() {
        emitEvent(`${eventPrefix.value}/finish`);
        await closeVisitQuery();
      }
    });

    const finishVisitLoading = ref(false);
    /** Закрытие заказа */
    async function closeVisitQuery() {
      finishVisitLoading.value = true;
      try {
        await store.visit.finish(visitId);

        if (visit.value?.isPayed) {
          navManager.navigate({
            routerDirection: 'root',
            routerLink: { name: 'visits' },
          });
        } else {
          reloadVisitBackground(); // async
        }
      } catch (e) {
        toast.error(e, 5000, {
          header: t('views.visit_single.header_error_finish_visit')
        });
      } finally {
        finishVisitLoading.value = false;
      }
    }
    //#endregion

    //#region Оплата заказа
    // Возможность оплатить заказ
    const oppPay = computed(() => isCahier.value && (visit.value?.isPayable || false) && !isPreentry.value);

    async function payVisit() {
      if (false === isCahier.value) {
        return toast.warning(t('views.visit_single.pay_warning_not_cashier'));
      }

      const points = visit.value?.car?.discountAccount?.scores;
      const hasPoints = !!points && points > 0;
      const hasGroup = !!visit.value?.group;

      const modal = await modalController.create({
        cssClass: 'core-modal-actions',
        component: PaymentTypeModal,
        componentProps: { 
          points,
          hasPoints,
          hasGroup,
          setValue(paymentType: PaymentTypeEnum, usePoints: boolean) {
            emitEvent(`${eventPrefix.value}/pay/open`, { paymentType, usePoints });

            router.push({
              name: 'visit-pay',
              params: {
                action: 'confirm',
                visitId,
                paymentType,
              },
              query: {
                sum: visit.value?.price,
                points: usePoints && hasPoints
                  ? (points || 0) : undefined,
                status: visit.value?.status,
              }
            });
          }
        },
        swipeToClose: true,
      });

      modal.present(); // async

      // В новой версии ionic не работает, почему - неизвестно
      // await openPaymentTypeModal({
      //   points,
      //   hasPoints,
      //   hasGroup,
      //   setValue(paymentType: PaymentTypeEnum, usePoints: boolean) {
      //     emitEvent(`${eventPrefix.value}/pay/open`, { paymentType, usePoints });

      //     router.push({
      //       name: 'visit-pay',
      //       params: {
      //         action: 'confirm',
      //         visitId,
      //         paymentType,
      //       },
      //       query: {
      //         sum: visit.value?.price,
      //         points: usePoints && hasPoints
      //           ? (points || 0) : undefined,
      //         status: visit.value?.status,
      //       }
      //     });
      //   }
      // });
    }
    //#endregion

    //#region Действия предварительной записи
    const oppGetStarted = computed(() => isPreentry.value);
    const oppCancelPreentryVisit = computed(() => isPreentry.value);

    /** Приступить к выполнению */
    const preentryGetStarted = createAlertConfirm({
      header: t('views.visit_single.alert_preentry_get_started_header'),
      message: t('views.visit_single.alert_preentry_get_started_message'),
      async confirm() {
        emitEvent('visit/preentry/get-started');
        await updateVisit({
          status: CarVisitStatusEnum.Processed,
          checkup: CarVisitCheckupEnum.Verified,
        });
      }
    });

    const preentryCancelVisit = createAlertConfirm({
      header: t('views.visit_single.alert_preentry_cancel_header'),
      message: t('views.visit_single.alert_preentry_cancel_message'),
      async confirm() {
        emitEvent('visit/preentry/delete');
        await updateVisit({
          checkup: CarVisitCheckupEnum.Rejected,
        });
        
        navManager.navigate({
          routerDirection: 'root',
          routerLink: {
            name: 'visits-preentry',
          }
        });
      }
    });
    //#endregion Приступить к выполнению

    //#region Возврат в работу (открытие заказа)
    // Возможность вернуть в работу
    const oppReturnWork = computed(() => includes([
      CarVisitStatusEnum.Finished,
      CarVisitStatusEnum.Canceled,
      CarVisitStatusEnum.Archive
    ], visit.value?.status));

    /** @click Действие открытия заказа */
    function reopenVisitAction() {
      alertConfirmReopenVisit(); // async
    }

    /** Окно для подтверждения открытия закза */
    const alertConfirmReopenVisit = createAlertConfirm({
      header: t('views.visit_single.alert_reopen_visit_header'),
      message: t('views.visit_single.alert_reopen_visit_message'),
      async confirm() {
        emitEvent(`${eventPrefix.value}/reopen`);
        await reopenVisitQuery();
      }
    });

    const reopenVisitLoading = ref(false);
    /** Вернуть заказ в работу */
    async function reopenVisitQuery() {
      reopenVisitLoading.value = true;
      try {
        await store.visit.rollback(visitId);
        reloadVisitBackground(); // async
      } catch (e) {
        toast.error(e, 5000, {
          header: t('views.visit_single.header_error_process_visit')
        });
      } finally {
        reopenVisitLoading.value = false;
      }
    }
    //#endregion

    //#region Возврат средств
    const oppReturnPay = computed(() => !!visit.value?.isPayed);

    /** @click Действие возврата средств */
    function returnPayAction() {
      emitEvent(`${eventPrefix.value}/pay/return/open`);

      router.push({
        name: 'visit-pay',
        params: {
          action: 'return',
          visitId,
          paymentType: visit.value?.paymentType || PaymentTypeEnum.Cash,
        },
        query: {
          sum: visit.value?.payed,
          points: visit.value?.payedByScores || undefined,
        }
      });
    }
    //#endregion

    //#region Чеки (пречек, фискальный)
    const oppInvoice = computed(() => {
      if (oppCloseVisit.value) {
        return true;
      }

      if (visit.value?.status === CarVisitStatusEnum.New) {
        return visit.value?.checkup === CarVisitCheckupEnum.Verified;
      }

      return false;
    });

    const alertConfirmInvoice = createAlertConfirm({
      header: t('views.visit_single.alert_invoice_header'),
      message: t('views.visit_single.alert_invoice_message'),
      confirmBtnText: t('views.visit_single.alert_invoice_btn_confirm'),
      async confirm() {
        emitEvent(`${eventPrefix.value}/invoice/print`);

        await awaitLoading(() => store.visit.invoice(visitId), {
          message: t('views.visit_single.invoice_processing_wait')
        });
      },
    });
    
    const oppInvoiceFiscal = computed(() => !!visit.value?.isPayed);
    const alertConfirmInvoiceFiscal = createAlertConfirm({
      header: t('views.visit_single.alert_invoice_fiscal_header'),
      message: t('views.visit_single.alert_invoice_fiscal_message'),
      confirmBtnText: t('views.visit_single.alert_invoice_fiscal_btn_confirm'),
      async confirm() {
        emitEvent(`${eventPrefix.value}/invoice/fiscal`);

        await awaitLoading(() => store.visit.invoiceFiscal(visitId), {
          message: t('views.visit_single.invoice_fiscal_processing_wait'),
        });
      }
    });
    //#endregion

    //#region Изменение деталей заказа (выбор доп услуг)
    const oppChangeProvidedServices = computed(() => {
      return !visit.value?.isPayed && includes(
        [
          CarVisitStatusEnum.New,
          CarVisitStatusEnum.Processed,
          CarVisitStatusEnum.Opened
        ],
        visit.value?.status
      );
    });

    async function changeProvidedServices() {
      if (!visit.value) return;
      emitEvent(`${eventPrefix.value}/edit/services`);

      const visitState = reactive(getEmptyNewVisitState());
      if (visit.value.group) {
        visitState.body.group = { id: visit.value.group.id };
      }

      // Оказываемые услуги, разбитые по группам (тип_категория)
      const providedServicesGroupsValues = ref(
        mapProvideServicesBodyItemsForGroup(
          convertProvidedServicesToBodyItems(visit.value.providedServices || [])
        )
      );

      // При изменении значений в группе, необходимо обновить данные состояния
      watch(providedServicesGroupsValues, servicesGroups => {
        visitState.body.providedServices = provideServicesBodyGroupToList(servicesGroups);
      }, { immediate: true, deep: true });

      const selectedTypeAndCategoriesCRef = computed<VisitTypeAndCategory[]>(() => {
        if (!visitState.body.providedServices) return [];
        return visitState.body.providedServices.map(ps => ({
          typeId: ps.pointType.id,
          catId: ps.carCategory.id,
        }));
      });

      function useModalServicesContext(): ModalServicesContext {
        return {
          providedServicesGroupsValues,
          visitState,
        };
      }

      const modal = await modalController.create({
        cssClass: 'core-modal-actions',
        component: ServicesAddCategoryGroupModal,
        swipeToClose: true,
        componentProps: {
          groupId: visit.value.group?.id,
          carId: visit.value.car?.id,
          action: 'selectServices',
          selectedTypeAndCategoriesCRef,
          providedServicesGroupsValues,
          useModalServicesContext,
          setProvidedServices(providedServices: ProvideServiceBodyItem[]) {
            emitEvent(`${eventPrefix.value}/edit/provide-services/update`);

            updateVisit({ // async
              providedServices: prepareProvidedServicesBodyItems(providedServices)
            });

            modal.dismiss();
          },
        },
      });

      modal.present();
      return modal;
    }
    //#endregion

    //#region Краткая информация о заказе в шапке при скролле на смартфоне
    const visitShortInfo = shallowRef<VisitCardShortInfo|null>(null);
    function setShortInfo(info: VisitCardShortInfo) {
      visitShortInfo.value = info;
    }

    const showInfoHeaderAfterBlock = ref<HTMLDivElement|null>(null);
    const belowInformationBlock = ref(false);
    const showShortInformationHeader = computed(() => !!visitShortInfo.value && belowInformationBlock.value && isTabletDown.value);
    function onScroll(event: CustomEvent) {
      if (!showInfoHeaderAfterBlock.value || !event.detail.scrollTop) return;

      const dScrollY = showInfoHeaderAfterBlock.value.offsetTop - event.detail.scrollTop as number;
      belowInformationBlock.value = dScrollY < 0;
    }
    //#endregion

    //#region Выбрать группу
    const { startChangeCounterparty } = useVisitCounterparty({ visit });
    //#endregion

    return {
      load,
      visit,
      oppReturnWork,
      oppCloseVisit,
      closeVisitAction,
      oppPay,
      payVisit,
      finishVisitLoading,
      reopenVisitAction,
      reopenVisitLoading,
      oppReturnPay,
      returnPayAction,
      oppInvoice,
      alertConfirmInvoice,
      oppInvoiceFiscal,
      alertConfirmInvoiceFiscal,
      oppChangeProvidedServices,
      changeProvidedServices,
      paymentTypeTitle,
      onScroll,
      showInfoHeaderAfterBlock,
      showShortInformationHeader,
      setShortInfo,
      visitShortInfo,
      isPreentry,
      oppGetStarted,
      preentryGetStarted,
      oppCancelPreentryVisit,
      preentryCancelVisit,
      startChangeCounterparty,
    };
  },
});
