
import { defineComponent, ref } from 'vue'
import { modalController } from '@ionic/vue'
import LoginForm from '@/components/user/LoginForm.vue'
import InputCarNumber from '@/components/car/InputCarNumber.vue'
import InputPrice from '@/components/visit/InputPrice.vue'
import InputPos from '@/components/pos/InputPos.vue'
import DemoModal from './DemoModal.vue'
import NetworkIndicator from '@/components/app-service/NetworkIndicator.vue'

export default defineComponent({
  components: {
    LoginForm,
    InputCarNumber,
    InputPrice,
    InputPos,
    NetworkIndicator,
  },
  setup() {
    const carNumber = ref('');
    const testPrice = ref('400');
    const posSum = ref('10');
    const isOpen = ref(false);

    function setOpen(value: boolean) {
      isOpen.value = value;
    }

    async function createModal() {
      const m = await modalController.create({
        component: DemoModal,
        cssClass: 'core-modal-actions',
        swipeToClose: true,
      });

      return await m.present();
    }

    return {
      carNumber,
      isOpen,
      setOpen,
      createModal,
      testPrice,
      posSum,
    };
  }
});
