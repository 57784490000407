
import { defineComponent, PropType, computed, ComputedRef } from 'vue';
import { useStore } from '@/composables/useApp';
import ServicesByCategoryGroup from '../provide-form/ServicesProvideByCategoryGroup.vue';
import { ProvideServiceBodyItem } from '@/repositories/Models/CarVisit';
import { generateKeyProvideService } from '@/helpers/visit';
import { useModalNav } from './ServicesAddCategoryGroupModal.vue';
import { ModalServicesContext } from '@/views/visits/VisitSingle.vue';
import SelectDoersView from './SelectDoersView.vue';
import { VisitTypeAndCategory } from '@/helpers/visit';
import { cloneDeep, groupBy } from 'lodash';
import { useMetrica } from '@/composables/useMetrica';

export default defineComponent({
  inheritAttrs: false,

  components: {
    ServicesByCategoryGroup,
  },

  props: {
    // FIXME: Избавиться от такого большого кол-ча передаваемых
    // значений и передавать их в ctx.visitState
    catId: {
      type: [String, Number] as PropType<string|number>,
      required: true,
    },
    typeId: {
      type: [String, Number] as PropType<string|number>,
      required: true,
    },
    groupId: {
      type: [String, Number] as PropType<string|number>,
      required: false,
    },
    carId: {
      type: String,
      required: false,
    },

    // HACK: Не передаем значения напрямую,
    // т.к. это вызовет проблемы с обновлением props значений
    useModalServicesContext: {
      type: Function as PropType<() => ModalServicesContext>,
      required: true,
    },

    selectedTypeAndCategoriesCRef: {
      type: Object as PropType<ComputedRef<VisitTypeAndCategory[]>>,
      default: () => computed(() => [] as VisitTypeAndCategory[]),
    },
  },

  setup(props) {
    const store = useStore();
    const nav = useModalNav();
    const ctx = props.useModalServicesContext();
    const { emitEvent } = useMetrica();

    const title = store.point.getCategoryBreadcrumbsComputed(props.catId);
    const categoryGroupKey = generateKeyProvideService(props.typeId, props.catId); // no reactive!

    /** Заменит категорию с сохранением выбранных услуг, если у типа нет мультивыбора */
    async function replaceCategoryIfDetectNotMultichoice() {
      const pointId = +props.typeId;
      const pointType = await store.point.filterTypePoint(pointId);
      if (pointType?.categoryMultichoice) return;

      const groupByTypes = groupBy(props.selectedTypeAndCategoriesCRef.value, 'typeId')
      if (groupByTypes[`${pointId}`]) {
        let savedProvidedServices: ProvideServiceBodyItem[]|null = null;
        groupByTypes[`${pointId}`].forEach(item => {
          const categoryGroupKey = generateKeyProvideService(item.typeId, item.catId);

          if (ctx.providedServicesGroupsValues.value[categoryGroupKey]) {
            savedProvidedServices = cloneDeep(ctx.providedServicesGroupsValues.value[categoryGroupKey])
            delete ctx.providedServicesGroupsValues.value[categoryGroupKey];
          }
        });

        if (savedProvidedServices) {
          ctx.providedServicesGroupsValues.value[categoryGroupKey] = savedProvidedServices;
        }
      }
    }

    replaceCategoryIfDetectNotMultichoice();

    const currentGroupValues = computed<ProvideServiceBodyItem[]>({
      get() {
        return ctx.providedServicesGroupsValues.value[categoryGroupKey] || [];
      },
      set(providedServices) {
        ctx.providedServicesGroupsValues.value[categoryGroupKey] = providedServices;
      }
    });

    const total = computed(() => currentGroupValues.value.reduce((s, item) => s + item.totalPrice, 0));

    function apply() {
      emitEvent('visit/edit/services/apply');
      nav.pushView(SelectDoersView);
    }

    return {
      title,
      total,
      currentGroupValues,
      apply,
      back: () => nav.backView(),
    };
  },
});
