
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  emits: ['click-backgroup'],

  props: {
    containerStyles: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({}),
    }
  },

  setup(props) {
    const modalContainerStyle = computed(() => {
      return {
        ...props.containerStyles,
      };
    });

    return {
      modalContainerStyle,
    };
  }
});
