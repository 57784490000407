
import { defineComponent, ref, computed, watch } from 'vue';
import { useNavManager } from '@/composables/useNavManager';
// import { mdTransitionAnimation } from '@ionic/core';
import VisitsTabProcessing from './VisitsTabProcessing.vue';
import VisitsTabArchive from './VisitsTabArchive.vue';
import { isTabletAndUp } from '@/helpers/adaptive';
import { useStore } from '@/composables/useApp';
import { scrollHide } from '@/directives/scrollHide';
import router from '@/router';
import { useMetrica } from '@/composables/useMetrica';
import ls from '@/helpers/localStorage';
import { useI18n } from 'vue-i18n';

const LS_VIEW_TYPE_KEY = 'visits-view-type';
const LS_VIEW_ARCHIVE_TYPE_KEY = 'visits-archive-view-type';

export default defineComponent({
  components: {
    VisitsTabProcessing,
    VisitsTabArchive,
  },
  directives: { scrollHide },
  setup() {
    const store = useStore();
    const navManager = useNavManager();
    const { emitEvent } = useMetrica();
    const { t } = useI18n();

    if (!store.user.isAuth) {
      navManager.navigate({
        routerDirection: 'root',
        routerLink: {
          name: 'login',
        }
      });
    }

    const usePreentry = store.config.usePreentry;

    //#region Tabs and View type
    const currentTab = ref('processing');
    watch(() => currentTab.value, tabName => emitEvent(`visits/tab/${tabName}`));

    const viewToggleContainer = ref<HTMLDivElement|null>(null);

    const processingViewType = ref<string>(ls.getValue(LS_VIEW_TYPE_KEY, 'normal'));
    watch(processingViewType, type => ls.saveValue(LS_VIEW_TYPE_KEY, type));

    const archiveViewType = ref<string>(ls.getValue(LS_VIEW_ARCHIVE_TYPE_KEY, 'mini'));
    watch(archiveViewType, type => ls.saveValue(LS_VIEW_ARCHIVE_TYPE_KEY, type));

    const viewType = computed({
      get() {
        return (currentTab.value === 'processing')
          ? processingViewType.value
          : archiveViewType.value;
      },
      set(value: string) {
        if (currentTab.value === 'processing') {
           processingViewType.value = value;
        } else {
          archiveViewType.value = value;
        }
      }
    });

    const isArchive = computed(() => currentTab.value === 'archive');
    //#endregion

    function newVisit() {
      emitEvent('visit/new');

      store.visit.clearNewVisitState();
      router.push({ name: 'visit-new-photo-scanner' });

      // navManager.navigate({
      //   routerDirection: 'forward',
      //   routerLink: { name: 'visit-new-photo-scanner' },
      //   routerAnimation: mdTransitionAnimation,
      // });
    }

    function toPreentry() {
      router.push({ name: 'visits-preentry' });
    }

    //#region Search bar
    const showSearchbar = ref(false);
    const showHeaderSearchbar = computed(() => isArchive.value && isTabletAndUp.value && showSearchbar.value);  // for tablet & desktop
    const showFooterSearchbar = computed(() => isArchive.value && !isTabletAndUp.value); // for mobile
    const searchWords = ref('');
    const searchInputRef = ref<any|null>(null);
    watch(searchInputRef, ionInputComponent => {
      // crutch
      setTimeout(() => ionInputComponent?.$el?.setFocus(), 200);
    }, { flush: 'post' });

    function openSearchbar() {
      showSearchbar.value = true;
    }

    function closeSearchbar() {
      showSearchbar.value = false;
      searchWords.value = '';
    }

    function toggleSearchbar() {
      if (showSearchbar.value) {
        closeSearchbar();
      } else {
        openSearchbar();
      }
    }
    //#endregion

    return {
      currentTab,
      newVisit,
      openSearchbar,
      showHeaderSearchbar,
      showFooterSearchbar,
      searchWords,
      toggleSearchbar,
      closeSearchbar,
      searchInputRef,
      isArchive,
      isTabletAndUp,
      toPreentry,
      viewType,
      archiveViewType,
      processingViewType,
      viewToggleContainer,
      usePreentry,
      t,
    };
  },
});
