import { BaseRepository, AsyncResponse} from './BaseRepository'
import { BoxCollectionQuery, BoxCollectionResponse } from './Models/Box'

export class BoxRepository extends BaseRepository {
  /**
   * Получить информацию о боксах
   * 
   * @param params 
   * @returns 
   */
  getCollection(params: BoxCollectionQuery = {}): AsyncResponse<BoxCollectionResponse> {
    return this.client.get('boxs', { params });
  }
}
