import { ThisClientPointResponse, PointTypeCategoryItem, ThisClientPointTypeItem, Shift } from "@/repositories/Models/Point"
import { values } from "lodash"
import { reactive, readonly, UnwrapRef, DeepReadonly, watch, computed, ComputedRef, Ref, unref } from "vue"
import { BaseStore, BaseStoreContext } from "./BaseStore"
import { UserStore } from "./UserStore"

export interface PointStoreContext extends BaseStoreContext {
  userStore: UserStore;
}

export interface PointStoreState {
  thisClient: ThisClientPointResponse|null;
  shifts: Shift[]|null;
}

export class PointStore extends BaseStore<PointStoreContext> {
  readonly state: UnwrapRef<PointStoreState>;

  constructor(ctx: PointStoreContext) {
    super(ctx);

    this.state = reactive({
      thisClient: null,
      shifts: null,
    });

    watch(() => this.user.state.current, () => {
      this.state.thisClient = null;
    });
  }

  get user() {
    return this.ctx.userStore;
  }

  /**
   * Получить общую информацию о текущем пункте автосервиса,
   * пунктах обслуживания в нем и категориях услуг в данных точках
   * 
   * CACHE: Данные автоматически кэшируются, и запрашиваются по
   * требованию только первый раз после запуска приложения
   * 
   * @returns 
   */
  async getThisClient(): Promise<DeepReadonly<ThisClientPointResponse>> {
    if (this.state.thisClient) {
      return readonly(this.state.thisClient);
    }

    const username = this.user.getCurrentUserName(); // Т.к. информация о сервисе получается относительно залогиненного менеджера
    const cache = await this.cacheQueryWithBloking(['point_this_client', username], async () => {
      const { data } = await this.repositories.point.getThisClient({
        softdelForall: true,
      });
      
      return data;
    }, 0);

    this.state.thisClient = cache.data;
    return readonly(this.state.thisClient);
  }

  /**
   * Получает информацию о расписании смен, для текущей точки
   * @returns 
   */
  async getShiftsThisPoint(): Promise<Shift[]> {
    if (!this.state.shifts) {
      const thisPoint = await this.getThisClient();

      const cache = await this.cacheQueryWithBloking(['shifts_this_point', thisPoint.id], async () => {
        const { data } = await this.repositories.point.getShiftsPoint({
          clientPointId: thisPoint.id,
          limit: 10000,
        });
        
        return data.items;
      }, 0);

      this.state.shifts = cache.data;
    }

    return this.cloneData(this.state.shifts);
  }

  async filterTypePoint(typeId: number): Promise<DeepReadonly<ThisClientPointTypeItem|undefined>> {
    const thisClient = await this.getThisClient();
    const targetPointType = thisClient.pointTypes.find(pt => pt.id == typeId);

    return targetPointType;
  }

  /**
   * Вернет путь в виде заголовков до заданной ID категории
   * ВРИМАНИЕ! Предварительно необходимо прогрузить информацию методом getThisClient()
   * 
   * TODO: прочитать молитву, чтобы никто это не увидел 😂
   * FIXME: Что-то надо явно переделать
   * 
   * @param catId 
   * @param onlyCategoryName 
   * @returns 
   */
  getCategoryBreadcrumbsComputed(catId: Ref<number|string>|number|string, onlyCategoryName = false): ComputedRef<string> {
    return computed(() => {
      const catIdNumber = +unref(catId);

      // Необходимо предварительно прогрузить данные
      if (!this.state.thisClient) {
        return '...';
      }

      let targetPointType: ThisClientPointTypeItem|null = null;
      let targetCategory: PointTypeCategoryItem|null = null;

      for (let pointType of values(this.state.thisClient.pointTypes)) {
        if (!pointType.carCategories) continue; // fix: оказывается не у всех типов есть категории

        for (let category of pointType.carCategories) {
          if (category.id === catIdNumber) {
            targetPointType = pointType;
            targetCategory = category;
            break;
          }
        }
      }

      if (onlyCategoryName) {
        if (targetCategory) {
          return targetCategory.name;
        }
      } else if (targetPointType && targetCategory) {
        // В дизайне все стрелки ровные, но на практике все кривые/косые: → 🠢 🡒 ⟶
        return `${targetPointType.name} ⟶ ${targetCategory.name}`;
      }

      return 'Без категории';
    });
  }
}