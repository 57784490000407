
import { computed, defineComponent, onMounted, ref } from 'vue';
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import { includes, toNumber } from 'lodash';
import { PAYMENT_TYPES_LIST, getPaymentTypeTitle } from '@/helpers/visit';
import { PaymentTypeEnum } from '@/repositories/Models/Pos';
import InputPos from '@/components/pos/InputPos.vue';
import { useToast } from '@/composables/useToast';
import { useRepositories, useStore } from '@/composables/useApp';
import { useNavManager } from '@/composables/useNavManager';
import { CarVisitStatusEnum } from '@/repositories/Models/CarVisit';
import { useMetrica } from '@/composables/useMetrica';
import { useAlert } from '@/composables/alert';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    InputPos,
  },
  setup() {
    const route = useRoute();
    const repo = useRepositories();
    const toast = useToast();
    const navManager = useNavManager();
    const store = useStore();
    const { emitEvent } = useMetrica();
    const { createAlertConfirm } = useAlert();
    const { t } = useI18n();

    /** если true, то перед уходом необходимо отменить оплату */
    let hasAbilityCancel = false;

    /** Осуществляется действие возврата? */
    const isReturnAction = route.params.action === 'return';
    const isStoreVisit = route.meta.visitType === 'market';
    
    // TODO: В будущем добавится preentry
    const eventPrefix = 'visit' + (isStoreVisit ? '/market' : '');

    onBeforeRouteLeave(async () => {
      if (hasAbilityCancel) await cancelAction();
    });

    //#region Payment type and sum
    const visitId = route.params.visitId as string;
    const totalVisit = ref(toNumber(route.query.sum || 0));

    const points = route.query.points ? +route.query.points : undefined;
    const pointsFormatted = computed(() => points ? Math.min(points, totalVisit.value) : 0);
    const hasPoints = computed(() => !!points);

    const sum = ref<string|number>(0);
    const paymentType = includes(PAYMENT_TYPES_LIST, route.params.paymentType)
      ? route.params.paymentType as PaymentTypeEnum
      : PaymentTypeEnum.Cash;
    const paymentTypeTitle = getPaymentTypeTitle(paymentType, {
      [PaymentTypeEnum.Card]: t('views.visit_pay.payment_type_card')
    });

    const cashDeposited = ref<string|number>(0);
    const cashDepositedError = computed(() => paymentType === PaymentTypeEnum.Cash && +cashDeposited.value < +sum.value);
    const changeFromCash = computed(() => {
      let payback = cashDepositedError.value ? 0 : (+cashDeposited.value) - (+sum.value);

      // Здесь учитывать не нужно, т.к. при инициализации
      // оплаты сумма просчитывается на сервере.
      // if (points) {
      //   payback += points;
      // }

      return payback;
    });
    // const changeFromCashFormatted = computed(() => preparePrice(changeFromCash.value));
    const showCalculator = false === isReturnAction && includes([
      PaymentTypeEnum.Cash,
      // В будущем возможно при оплате баллами
    ], paymentType);
    //#endregion

    const initLoading = ref(false);
    /** Предварительная инициализация и загрузка */
    async function initAndLoadAction() {
      initLoading.value = true;

      try {
        if (isReturnAction) { // Операция возврата
          await repo.pos.payReturnInit(visitId)
          sum.value = totalVisit.value;
        } else { // Операция оплаты
          const { data } = await repo.pos.payInit(visitId, {
            type: paymentType,
            useScores: points || 0,
          });

          sum.value = data.toPay;

          // if (isStoreVisit) { // fix: При оплате визита в магазин query sum не передается [уже передается]
          //   totalVisit.value = data.toPay;
          // }
        }

        hasAbilityCancel = true;
      } catch (e) {
        toast.error(e, 5000, {
          header: t('views.visit_pay.header_error_init')
        });
      } finally {
        initLoading.value = false;
      }
    }

    /** Подтвердить выполнение операции */
    const operationProcessing = ref(false);

    /** Окно подтверждения оплаты и закрытия заказа */
    const alertConfirmPay = createAlertConfirm({
      header: t('views.visit_pay.alert_confirm_pay_header'),
      message: t('views.visit_pay.alert_confirm_pay_message'),
      async confirm() {
        emitEvent(`${eventPrefix}/pay/confirm`, { paymentType });
        await confirmPayVisit();
      }
    });

    /** Окно подтверждения возврата средств */
    const confirmAlertReturnPay = createAlertConfirm({
      header: t('views.visit_pay.alert_confirm_return_pay_header'),
      message: t('views.visit_pay.alert_confirm_return_pay_message'),
      async confirm() {
        emitEvent(`${eventPrefix}/pay/return/confirm`, { paymentType });
        await confirmReturnPay();
      }
    });

    function confirmAction() {
      if (isReturnAction) { // Подтвердить возврат
        confirmAlertReturnPay();
      } else { // Подтвердить оплату
        alertConfirmPay();
      }
    }

    function getNextPage() {
      if (isStoreVisit) {
        return { name: 'market' };
      }

      return (route.query.status === CarVisitStatusEnum.Finished)
        ? { name: 'visits' }
        : { name: 'visit-single', params: { visitId } };
    }

    /** Подтверждение оплаты и закрытие визита */
    async function confirmPayVisit() {
      operationProcessing.value = true;

      try {
        if (cashDepositedError.value) {
          return toast.error(t('views.visit_pay.cash_deposited_error'), 4000, {
            header: t('views.visit_pay.header_error_pos')
          });
        }

        const isCash = (paymentType === PaymentTypeEnum.Cash);

        // Если оплата происходит наличными, то подставляем сумму введеную с
        // клавиатуры, в остальных случаях берем сумму "к оплате" (toPay)
        const paySum = isCash ? +cashDeposited.value : +sum.value;
        const payback = isCash ? changeFromCash.value : 0;
        
        await repo.pos.payConfirm(visitId, {
          sum: paySum,
          type: paymentType,
          useScores: points || 0,
          payback,
        });

        hasAbilityCancel = false;

        if (isStoreVisit) {
          toast.success(t('views.visit_pay.pay_visit_success_message'));
          store.visit.clearNewStoreVisitState();
        }
        
        navManager.navigate({
          // FIXME: back - почемуто работает некорректно
          // routerDirection: 'back',
          routerDirection: 'root',
          routerLink: getNextPage(),
        });
      } catch (e) {
        toast.error(e, 4000, {
          header: t('views.visit_pay.header_error_pay_visit')
        });
      } finally {
        operationProcessing.value = false;
      }
    }

    /** Подтвердить возврат платежа */
    async function confirmReturnPay() {
      operationProcessing.value = true;

      try {
        await repo.pos.payReturnConfirm(visitId);

        hasAbilityCancel = false;

        navManager.navigate({
          routerDirection: 'root',
          // routerDirection: 'back',
          routerLink: {
            name: 'visit-single',
            params: { visitId }
          },
        });
      } catch(e) {
        toast.error(e, 4000, {
          header: t('views.visit_pay.header_error_return_pay_visit')
        });
      } finally {
        operationProcessing.value = false;
      }
    }

    /** Отменить выполнение операции */
    async function cancelAction() {
      try {
        if (isReturnAction) { // Отмена операции возврата
          await repo.pos.payReturnCancel(visitId);
        } else { // Отмена операции оплаты
          await repo.pos.payCancel(visitId, {
            type: paymentType,
            useScores: 0, // FIXME: Устанавливать по умолчанию
          });
        }
      } catch (e) {
        toast.error(e, 4000, {
          header: t('views.visit_pay.header_error_pos')
        });
      }

      hasAbilityCancel = false;
    }

    const buttonConfirmDisabled = computed(() => {
      return (initLoading.value || operationProcessing.value)
        || (false === isReturnAction && cashDepositedError.value);
    });

    const buttonConfirmText = computed(() => {
      if (initLoading.value) {
        return t('views.visit_pay.confirm_btn_init');
      }

      if (operationProcessing.value) {
        return t('views.visit_pay.confirm_btn_processing');
      }

      if (isReturnAction) {
        return t('views.visit_pay.confirm_btn_confirm_return');
      }

      return (route.query.status === CarVisitStatusEnum.Finished)
        ? t('views.visit_pay.confirm_btn_confirm_pay_and_close')
        : t('views.visit_pay.confirm_btn_confirm_pay');
    });

    onMounted(initAndLoadAction);

    const viewTitle = computed(() => {
      return (isReturnAction)
        ? t('views.visit_pay.title_return')
        : t('views.visit_pay.title_accept');
    });

    const labelTotal = computed(() => {
      return (isReturnAction)
        ? t('views.visit_pay.label_total_return')
        : t('views.visit_pay.label_total_accept');
    });

    const labelPaymentType = computed(() => {
      return (isReturnAction)
        ? t('views.visit_pay.label_payment_type_return')
        : t('views.visit_pay.label_payment_type_accept');
    });

    return {
      viewTitle,
      labelTotal,
      labelPaymentType,
      route,
      paymentTypeTitle,
      sum,
      totalVisit,
      hasPoints,
      pointsFormatted,
      showCalculator,
      cashDeposited,
      changeFromCash,
      cashDepositedError,
      confirmAction,
      initLoading,
      isReturnAction,
      buttonConfirmDisabled,
      buttonConfirmText,
    };
  }
});
