/**
 * NOTE: Данные: User, CurrentUser, Doer, Manager - довольно сильно разлечаются,
 * делать базовые определения особо не имеет смысла.
 */

import { BaseCollectionResponse, BaseQueryPage } from './Base'

/**
 * Базовая информация о текущем пользователе
 */
export interface CurrentUser {
  /** Имя */
  firstName: string;

  /** Фамилия */
  lastName: string;

  /** Является кассиром */
  isCashier: boolean;
}

/**
 * Получаемая информация о текущем пользователе
 */
export interface CurrentUserResponse extends CurrentUser {
  sessionAutofinishAfter?: number;
  sessionAutofinishBefore?: number;
}

export interface UserCollectionItem {
  id: number; // int64

  /** Логин пользователя */
  username: string;

  /** Имя */
  firstName: string;

  /** Фамилия */
  lastName: string;

  /** E-mail пользователя */
  email: string;

  /** Пользователь активен */
  enabled: boolean;

  /** Пользователь заблокирован */
  locked: boolean;

  /** Истек срок дейтсвия ??? - не до конца понятно */
  expired: boolean;

  /** Истекли учетные данные */
  credentialsExpired: boolean;

  /** Пользователь удален (исп. в мягком удалении) */
  deleted: boolean;
}

export interface ManagerCollectionItem {
  id: number;

  /** Имя фамилия */
  name: string;

  /** Является кассиром */
  isCashier: boolean;

  /** Оплата труда */
  salary: number;

  /** Пользователь удален (исп. в мягком удалении) */
  deleted: boolean;

  /**
   * Тип пользователя
   * @type {string}
   * @default manager
   */
  type: UserRoleTypeEnum;

  /** TODO: ??? */
  percentRulesInterval: UserPercentRulesInterval;

  /** Базовая информация о пользователе */
  user: UserCollectionItem;
}

export interface DoerCollectionItem {
  id: number;

  /** Имя фамилия */
  name: string;

  /** Оплата труда */
  salary: number;

  /** Пользователь удален (исп. в мягком удалении) */
  deleted: boolean;

  /**
   * Тип пользователя
   * @type {string}
   * @default doer
   */
  type: UserRoleTypeEnum;

  /** TODO: ??? */
  percentRulesInterval: UserPercentRulesInterval;
}

export interface UserPercentRulesInterval {
  min: number;
  max: number;
}

export enum UserRoleTypeEnum {
  Manager = 'manager',
  Doer = 'doer',
}

export type CurrentUserQuery = {
  appName: string;
  [param: string]: any;
};
export type UserCollectionQuery = BaseQueryPage & { [param: string]: any };
export type ManagerCollectionQuery = UserCollectionQuery;
export type DoerCollectionQuery = UserCollectionQuery;

export type UserCollectionResponse = BaseCollectionResponse<UserCollectionItem>;
export type ManagerCollectionResponse = BaseCollectionResponse<ManagerCollectionItem>;
export type DoerCollectionResponse = BaseCollectionResponse<DoerCollectionItem>;
