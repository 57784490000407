import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_visits_archive_groups_by_date = _resolveComponent("visits-archive-groups-by-date")!
  const _component_c_infinite_loading = _resolveComponent("c-infinite-loading")!

  return (_openBlock(), _createBlock(_component_c_infinite_loading, {
    infinite: _ctx.loadVisitsSearch,
    ref: "cInfiniteLoadingRef",
    "per-page": 40,
    threshold: "400px"
  }, {
    default: _withCtx(({ items, total, loading }) => [
      _withDirectives(_createElementVNode("div", { class: "ion-padding ion-text-center text-muted" }, _toDisplayString(total == 0
        ? _ctx.$t('components.visit_archive_search.not_found')
        : _ctx.$t('components.visit_archive_search.found_info', { count: total })), 513), [
        [_vShow, !loading || !!total]
      ]),
      _createVNode(_component_visits_archive_groups_by_date, { visits: items }, null, 8, ["visits"])
    ]),
    _: 1
  }, 8, ["infinite"]))
}