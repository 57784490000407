import { BaseRepository, AsyncResponse} from './BaseRepository'
import { PricePolicyListQuery, PricePolicyListResponse } from './Models/PricePolicy'

export class PricePolicyRepository extends BaseRepository {
  /**
   * Получает список с ценовыми политиками
   * 
   * @param params 
   * @returns 
   */
  getList(params: PricePolicyListQuery = {}): AsyncResponse<PricePolicyListResponse> {
    return this.client.get('price-policy', { params });
  }
}