
import { defineComponent, provide } from 'vue';
import AppHeaderNewVisit from '@/components/layout/AppHeaderNewVisit.vue';
import CarSideInfo from '@/components/car/CarSideInfo.vue';
import ServicesProvideChoiceDoers from '@/components/service/provide-form/ServicesProvideChoiceDoers.vue';
import { useRouter } from 'vue-router';
import { useCreateVisitStep, useNewVisitPhoto } from '@/composables/visit';
import { useMetrica } from '@/composables/useMetrica';

export default defineComponent({
  components: {
    AppHeaderNewVisit,
    CarSideInfo,
    ServicesProvideChoiceDoers,
  },

  setup() {
    const router = useRouter();
    const { emitEvent } = useMetrica();
    const { isPreentry, visitId, carId, visitType, visitState, prefixNamePage } = useCreateVisitStep({
      stateProvideKey: 'visitState'
    });
    const { visitImageCover } = useNewVisitPhoto({ visitState, onlyMeta: isPreentry });

    provide('visitAction', visitType);

    function next() {
      emitEvent(`visit/${visitType}/doers/next`);
      router.push({
        name: prefixNamePage('discounts'),
        params: { visitId },
      });
    }

    return {
      carId,
      visitImageCover,
      next,
    };
  },
});
