import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { slot: "end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_services_provided_view_group = _resolveComponent("services-provided-view-group")!
  const _component_cc = _resolveComponent("cc")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, { class: "padding-x-adaptive" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (group) => {
        return (_openBlock(), _createBlock(_component_services_provided_view_group, {
          key: group.catId,
          group: group,
          "short-title": _ctx.shortGroupName
        }, null, 8, ["group", "short-title"]))
      }), 128)),
      _createVNode(_component_ion_item, { class: "color-default" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('components.services_provided_view_card.card_item_label_total')), 1),
          _createElementVNode("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "total-price", {}, () => [
              _createVNode(_component_cc, { price: _ctx.total }, null, 8, ["price"])
            ])
          ])
        ]),
        _: 3
      }),
      _renderSlot(_ctx.$slots, "after")
    ]),
    _: 3
  }))
}