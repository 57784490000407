import { ref, computed, Ref, onMounted, watch } from 'vue';
import { useShiftsThisPoint } from '@/composables/useShiftsThisPoint';
import { useBoxes } from '@/composables/useBoxes';
import { useStore } from '@/composables/useApp';
import { CarVisitCheckupEnum, CarVisitCollectionItem, CarVisitCollectionQuery, CarVisitStatusEnum } from '@/repositories/Models/CarVisit';
import { CarVisitExt } from "@/repositories/Models/CarVisit";
import { Box } from "@/repositories/Models/Box";
import { numberMinutesToTimeGridItem, ShiftTimeGridItem } from '@/helpers/shifts';
import { groupBy, get, mapValues, isEmpty } from 'lodash';
import moment from 'moment';
import { shiftsSmoothingValue } from '@/helpers/shifts';
import { useToast } from './useToast';

import {
  datetimeToTimeNumber,
  dateToServerDatetimeString,
  convertToDateAndCleanTime,
  parseToDate,
  getDateCleanTime,
  currentTimeMoment,
} from '@/helpers/datetime';
import { useI18n } from 'vue-i18n';

export interface TimeGridStyle {
  gridRowStart: number;
  gridRowEnd: number;
  gridColumnStart: number;
  gridColumnEnd: number;
}

export interface CarVisitTimeGridInfo<T = CarVisitExt> {
  visit: T;
  timing: number;
  box: Box;
  time: ShiftTimeGridItem;
  calcEndTime: ShiftTimeGridItem;
  classes: Record<string, boolean>;
  gridStyle: TimeGridStyle;
}

export interface EmptyTimeGrid extends TimeGridStyle {
  expired?: boolean;
}

export function usePreentryVisits() {
  const store = useStore();

  const visitsPreentry = ref<CarVisitCollectionItem[]>([]);

  /**
   * Информация о времени исполнения (нормативе) для каждого заказа
   * Индексирует по идентификатору заказа
   */
  const visitsTimingsAsyncRef = ref<Record<string, number>>({});

  async function calculateVisitsTimings(visits: CarVisitCollectionItem[]): Promise<Record<string, number>> {
    let visitsTimings: Record<string, number> = {};

    for (const visit of visits) {
      const time = await store.service.calcNormativesProvideService(visit.providedServices || []);
      visitsTimings[visit.id] = time;
    }

    return visitsTimings;
  }

  async function loadVisitsParams(from: Date, to: Date, params: CarVisitCollectionQuery = {}) {
    const cache = await store.visit.getCarVisitCollection({
      limit: 1000,
      fromDate: dateToServerDatetimeString(from),
      toDate: dateToServerDatetimeString(to),
      ...params,
    });

    return cache.data.items;
  }

  async function loadVisits(from: Date, to: Date) {
    visitsPreentry.value = [];
    visitsTimingsAsyncRef.value = {};

    const [preentry, processed] = await Promise.all([
      loadVisitsParams(from, to, {
        status: CarVisitStatusEnum.New,

        // На данный момент в API бэкенда такого фильтра нет, но оставил на будущее
        chekup: CarVisitCheckupEnum.PendingPreentry,
      }),

      loadVisitsParams(from, to, {
        status: CarVisitStatusEnum.Processed,
      }),
    ]);

    const allVisits = [...processed, ...preentry ];

    // Последовательность должна быть именно такая
    visitsTimingsAsyncRef.value = await calculateVisitsTimings(allVisits);
    visitsPreentry.value = allVisits;
  }

  return {
    visitsPreentry,
    loadVisits,
    visitsTimingsAsyncRef,
  };
}

export interface UsePreentryOptions {
  autoload?: boolean;
  autoupdateChangeDate?: boolean;
  normative?: Ref<number>;
  stepMinutes?: number;
  disableBeforeData?: Ref<Date>;
}

const USER_PREENTRY_OPTIONS_DEFAULTS: UsePreentryOptions = {
  autoload: true,
  autoupdateChangeDate: true,
};

export function usePreentryShedule(date: Ref<any>, options: UsePreentryOptions = {}) {
  const opt = { ...USER_PREENTRY_OPTIONS_DEFAULTS, ...options };

  const stepMinutes = ref(opt.stepMinutes || 15);
  const toast = useToast();
  const store = useStore();
  const { t } = useI18n();

  const { boxes, loadBoxes } = useBoxes();
  const { timeLine, loadShifts, minmaxMinutes } = useShiftsThisPoint({
    stepMinutes: stepMinutes.value,
  });
  const {
    visitsPreentry,
    loadVisits: loadVisitsBeetween,
    visitsTimingsAsyncRef,
  } = usePreentryVisits();

  /** Данные визитов, выстроенные по сетке расписания */
  const visitsSheduleGrid = computed(() => {
    // Нечего рассчитывать
    if (isEmpty(visitsPreentry.value)) return [];

    if (isEmpty(visitsTimingsAsyncRef.value)) {
      console.log('Ожидание рассчета нормативов...');
      return [];
    }

    let visitsGrid: CarVisitTimeGridInfo[] = [];

    if (isEmpty(timeLine.value)) {
      console.warn('Не указано время смен, для отобраежения предварительных записей');
      return [];
    }

    if (isEmpty(boxes.value)) {
      console.warn('Не указан список боксов, для отобраежения предварительных записей');
      return [];
    }

    const { from: fromDate, to: toDate } = beetweenDate.value;
    const timingsMapByVisitId = visitsTimingsAsyncRef.value;

    // Раньше просроченным считался заказ спустя 20 мин после начала, теперь сразу
    const afterExpiredDatetime = moment(); // .add(-20, 'minute');

    for (const visit of visitsPreentry.value) {
      if (!visit.creationDate) {
        console.warn('У визита не задана дата создания', visit);
        continue;
      }

      const visitDateObject = parseToDate(visit.creationDate);

      // LEGACY: Старые бэкенды возможно не имеют возможности получать визиты по 
      // диапазону дат, ввиду этого приходится фильтравать записи по факту
      if (visitDateObject < fromDate || visitDateObject > toDate) {
        continue;
      }

      // LEGACY: Текущие бэкенды не имеют фильра по статусу проверки
      // Те заказы которые имеют статус new и visit.checkup != pending_preentry
      // скореевсего были автоматически созданы камерой и такие нам отображать не нужно
      if (visit.checkup !== CarVisitCheckupEnum.PendingPreentry && visit.status !== CarVisitStatusEnum.Processed) {
        continue;
      }

      const visitBoxId = visit.box?.id || 0;
      const boxIndex = boxes.value.findIndex(b => b.id == visitBoxId);
  
      if (boxIndex < 0) {
        console.warn('Не удалось определить бокс для визита', visit);
        continue;
      }
  
      const box = { ...boxes.value[boxIndex] };
      const gridColumnStart = boxIndex + 1;
      const gridColumnEnd = gridColumnStart + 1;
  
      const visitTimeNumber = shiftsSmoothingValue(datetimeToTimeNumber(visitDateObject), stepMinutes.value);
      const timeIndex = timeLine.value.findIndex(t => t.timeNumber === visitTimeNumber);

      if (timeIndex < 0) {
        console.warn('Не удалось найти время в расписании', visit);
        continue;
      }

      const timing = timingsMapByVisitId[visit.id] || 0;
      const time = timeLine.value[timeIndex];
      const gridRowStart = timeIndex + 1;
      const gridRowEnd = (timeIndex + 1) + Math.max(1, Math.ceil(timing / stepMinutes.value));
      const calcEndTime = numberMinutesToTimeGridItem(time.timeNumber + timing);

      const classes = {
        '--processed': visit.status === CarVisitStatusEnum.Processed,
        '--new': visit.status === CarVisitStatusEnum.New,
        '--expired': afterExpiredDatetime.isAfter(visitDateObject),
      };

      visitsGrid.push({
        visit,
        box,
        timing,
        time,
        calcEndTime,
        classes,
        gridStyle: {
          gridColumnStart,
          gridColumnEnd,
          gridRowStart,
          gridRowEnd,
        },
      });
    }

    return visitsGrid;
  });

  /** Дата с очищенным временем */
  const preparedDate = computed(() => convertToDateAndCleanTime(date.value));

  /** В расписании выбрана текущая дата */
  const isCurrentDate = computed<boolean>(() => {
    const { from, to } = beetweenDate.value;

    const current = currentTimeMoment.value.toDate();
    return (current >= from && current < to);
  });

  const isPrevDate = computed(() => {
    const current = currentTimeMoment.value.toDate();
    return (current > beetweenDate.value.to);
  });

  /**
   * Требуемое время норматива (для отображения пустых областей)
   */
  const normative = computed(() => opt.normative ? opt.normative.value : 0);

  /**
   * Имеет отключеные области до указанной даты
   */
  const hasDisableBeforeDate = computed(() => !!opt.disableBeforeData);

  const disableBeforeData = computed(() => opt.disableBeforeData?.value || new Date());
  // const disableBeforeMinutesStartDay = computed(() => datetimeToTimeNumber(disableBeforeData.value));
  // const isSameDisableBeforeData = computed(() => moment(disableBeforeData.value).isSame(preparedDate.value, 'date'));

  /**
   * Проверка области на соответствие нормативу.
   * Если область меньше заданного норматива, то вернется false.
   * 
   * @param startITime индекс времени начала
   * @param endITime индекс времени конца
   * @returns 
   */
  const checkNormative = (startITime: number, endITime: number): boolean => {
    if (!normative.value) return true;
    
    const start = timeLine.value[startITime];
    const end = timeLine.value[endITime];
    if (!start || !end) return false;

    const dMinutes = end.minutesBeginDay - start.minutesBeginDay;

    return dMinutes >= normative.value;
  };

  /**
   * Индексирование визитов по сетке, в отличае от keyBy(item.gridStyle.gridRowStart)
   * заполняет промежуточные ячейки, это позволяет избежать
   * различных ошибок, при пересечении визитов в сетке.
   * 
   * @private
   * 
   * @param visitGrid 
   * @param rowsCount 
   * @returns 
   */
  function visitsKeyByRow(visitGrid: CarVisitTimeGridInfo<CarVisitExt>[], rowsCount: number) {
    let visitGridIndex: Record<number, CarVisitTimeGridInfo<CarVisitExt>|null> = {};
    
    for (const item of visitGrid) {
      for (let i = item.gridStyle.gridRowStart; i < item.gridStyle.gridRowEnd && i < rowsCount; ++i) {
        visitGridIndex[i] = item;
      }
    }

    return visitGridIndex;
  }

  /**
   * Для переданного времени вычислит индекс строки из timeline.
   * Если не найдется, вернется -1
   * 
   * @private
   * 
   * @param date 
   * @returns 
   */
  function findDateIndexInTimeline(date: Date) {
    const visitTimeNumber = shiftsSmoothingValue(datetimeToTimeNumber(date), stepMinutes.value);
    const timeIndex = timeLine.value.findIndex(t => t.timeNumber === visitTimeNumber);

    return timeIndex;
  }

  /**
   * Пустые блоки расписания
   * По сути нужны только для визуального отображения
   * пустых участков, как это сделано в дизайне
   */
  const emptySheduleGrid = computed(() => {
    // Если указанная дата меньше текущей, то заранее известно,
    // что доступных пустых областей для записи не будет
    // if (hasDisableBeforeDate.value && getDateCleanTime(disableBeforeData.value) > preparedDate.value) {
    //   return [] as TimeGridStyle[];
    // }

    const rowsCount = timeLine.value.length;
    const colsCount = boxes.value.length;

    // Индексируем визиты по принципу visits[<columnIndex>][<rowIndex>]
    const visitsIndexGrid = mapValues(
      groupBy(visitsSheduleGrid.value, vg => vg.gridStyle.gridColumnStart),
      bvg => visitsKeyByRow(bvg, rowsCount)
    );

    // Включено отсеивание времени до определенного значения
    const splitCurrentTimeIndex = (hasDisableBeforeDate.value && isCurrentDate.value)
      ? findDateIndexInTimeline(disableBeforeData.value) + 1 : 0;
    let emptyItems: EmptyTimeGrid[] = [];

    for (let colIndex = 1; colIndex <= colsCount; ++colIndex) {
      let startRowIndex = 0;
      let splitIndex = splitCurrentTimeIndex;

      for (let rowIndex = 1; rowIndex <= rowsCount; ) {
        const visitGridInfo = get(visitsIndexGrid, `${colIndex}.${rowIndex}`, null) as CarVisitTimeGridInfo|null;
        const isSplit = splitIndex === rowIndex;

        if (visitGridInfo || rowIndex === rowsCount || isSplit) {
          if (startRowIndex) {
            const expired = isSplit
              || (hasDisableBeforeDate.value && isPrevDate.value)
              || (rowIndex < splitIndex)
              || (hasDisableBeforeDate.value && !checkNormative(startRowIndex - 1, rowIndex - 1))

            emptyItems.push({
              gridColumnStart: colIndex,
              gridColumnEnd: colIndex + 1,
              gridRowStart: startRowIndex || 1,
              gridRowEnd: rowIndex,
              expired,
            });

            startRowIndex = 0;
          }

          if (isSplit) {
            splitIndex = 0;
          } else {
            ++rowIndex;
          }
          
          continue;
        }
        
        if(!startRowIndex) {
          startRowIndex = rowIndex;
        }

        ++rowIndex;
      }
    }

    return emptyItems;
  });

  /**
   * значения диапазона дат, с учетом переданной даты
   * и временем графика начала и окончания смен
   */
  const beetweenDate = computed(() => getDateBeetweenValues(date.value));

  /**
   * Вернет значения диапазона дат, с учетом переданной даты
   * и временем графика начала и окончания смен
   * 
   * @returns 
   */
  function getDateBeetweenValues(date: any) {
    const preparedDate = convertToDateAndCleanTime(date);
    const shiftMinutes = minmaxMinutes.value;

    const from = moment(preparedDate)
      .add(shiftMinutes.min, 'minute')
      .toDate();
    
    const to = moment(preparedDate)
      .add(shiftMinutes.max, 'minute')
      .toDate();

    return { from, to };
  }

  /**
   * Загрузить список визитов
   */
  async function loadVisits() {
    const { from, to } = beetweenDate.value;
    return loadVisitsBeetween(from, to);
  }

  // Флаги состояния загрузки
  const loadingAll = ref(false);
  const loadingVisits = ref(false);
  const loading = computed(() => loadingAll.value || loadingVisits.value);

  /**
   * Разом загружает все необходимые
   * данные в нужной последовательности
   */
  async function load() {
    loadingAll.value = true;

    try {
      await Promise.all([
        // Предварительная загрузка списка, чтобы быстрее
        // считать нормативы устаревшим способом
        store.service.getAll(),

        loadShifts(),
        loadBoxes(),
      ]);

      await loadVisits();
    } catch(e) {
      toast.error(e, 4000, {
        header: t('composables.visit_preentry.header_error_load_data')
      });
    } finally {
      loadingAll.value = false;
    }
  }

  if (opt.autoload) {
    onMounted(load);
  }

  if (opt.autoupdateChangeDate) {
    watch(date, async () => {
      loadingVisits.value = true;
      try {
        await loadVisits();
      } catch(e) {
        toast.error(e, 3500, {
          header: t('composables.visit_preentry.header_error_load_data'),
        });
      } finally {
        loadingVisits.value = false;
      }
    });
  }

  return {
    visitsSheduleGrid,
    emptySheduleGrid,
    visitsPreentry,
    timeLine,
    boxes,
    load,
    loadBoxes,
    loadShifts,
    loadVisits,
    loading,
    loadingAll,
    loadingVisits,
    getDateBeetweenValues,
    preparedDate,
    beetweenDate,
    minmaxMinutes,
    isCurrentDate,
  };
}


//#region Page preentry
export type TabValue = 'currentDate'|'nextDate'|'customDate'|'selectDate';

/**
 * Вспомогательные данные для страницы с календарем предварительной записи.
 */
export function usePreenryDatetimePage() {
  const currentTab = ref<TabValue>('currentDate');
  const customDate = ref<Date|null>(null);
  const nextDateMoment = moment().add(1, 'day');
  const nextDate = getDateCleanTime(nextDateMoment.toDate());
  const currentDateMoment = moment();
  const currentDate = getDateCleanTime(currentDateMoment.toDate());

  const viewSheduleDate = computed(() => {
    if (currentTab.value === 'customDate' && customDate.value) {
      return customDate.value;
    }

    if (currentTab.value === 'nextDate') {
      return nextDate;
    }

    return currentDate;
  });

  const isSelectDate = computed(() => currentTab.value === 'selectDate');

  const customDateFormat = computed(() => {
    if (!customDate.value) return '—';

    const customDateMoment = moment(customDate.value);
    if (currentDateMoment.isSame(customDateMoment, 'year')) {
      return customDateMoment.format('D MMMM');
    }

    return customDateMoment.format('D MMMM YYYY');
  });

  function setCustomDate(date: Date|null) {
    if (!date) {
      return clearCustomDate();
    }

    const momentDate = moment(date);
    
    if (momentDate.isSame(currentDateMoment, 'date')) {
      customDate.value = null;
      currentTab.value = 'currentDate';
    } else if (momentDate.isSame(nextDateMoment, 'date')) {
      customDate.value = null;
      currentTab.value = 'nextDate';
    } else {
      customDate.value = date;
      currentTab.value = 'customDate';
    }
  }

  function clearCustomDate() {
    customDate.value = null;
    currentTab.value = 'currentDate';
  }

  return {
    currentTab,
    customDate,
    nextDate,
    currentDateMoment,
    customDateFormat,
    setCustomDate,
    clearCustomDate,
    currentDate,
    viewSheduleDate,
    isSelectDate,
  };
}
//#endregion