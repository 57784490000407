
import { useNavManager } from '@/composables/useNavManager';
import { defineComponent, PropType } from 'vue';
import { useCreateVisitStep } from '@/composables/visit';
import type { RouteLocationRaw } from 'vue-router';
import { useAlert } from '@/composables/alert';
import { useMetrica } from '@/composables/useMetrica';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  inheritAttrs: true,

  props: {
    progress: {
      type: Number as PropType<number>,
      default: 0
    }
  },

  setup() {
    const navManager = useNavManager();
    const { createAlertConfirm } = useAlert();
    const { clearVisitState, isPreentry, visitType } = useCreateVisitStep();
    const { emitEvent } = useMetrica();
    const { t } = useI18n();

    const closeNewVisit = () => {
      clearVisitState();

      const routerLink: RouteLocationRaw = isPreentry
        ? { name: 'visits-preentry'} : { name: 'visits' };

      navManager.navigate({
        routerDirection: 'root',
        routerLink,
      });
    }

    const alertConfirmClose = createAlertConfirm({
      header: t('components.app_header_new_visit.alert_close_header'),
      message: t('components.app_header_new_visit.alert_close_message'),
      confirmBtnText: t('components.app_header_new_visit.alert_close_btn_confirm'),
      async confirm() {
        emitEvent(`visit/${visitType}/close`);
        closeNewVisit();
      }
    });

    return {
      alertConfirmClose,
    };
  }
});
