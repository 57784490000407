
import { defineComponent, ref, DeepReadonly } from 'vue';
import ServicesView from '@/components/service/ServicesView.vue';
import ServicesCategories from '@/components/service/ServicesCategories.vue';
import { ThisClientPointTypeItem, PointTypeCategoryItem } from '@/repositories/Models/Point';
import { useRoute } from 'vue-router';
import { get, pick, debounce } from 'lodash';
import { useMetrica } from '@/composables/useMetrica';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    ServicesView,
    ServicesCategories
  },
  setup() {
    const route = useRoute();
    const { emitEvent } = useMetrica();
    const { t } = useI18n();

    const pageTitle = ref(t('views.pricelist_cat_and_services.default_title'));
    const typeId = route.params.typeId as string;
    const catId = ref<string|number>('');

    function selectCategory(category: DeepReadonly<PointTypeCategoryItem>) {
      emitEvent('pricelist/category/tablet', pick(category, ['id', 'name']));
      catId.value = category.id;
    }

    function onTypeLoaded(pointType: DeepReadonly<ThisClientPointTypeItem>) {
      // При инициализации категорий установим самую первую
      catId.value = get(pointType, 'carCategories.0.id');
    }

    const inputSearchDebounce = debounce((text: string) => {
      if (text) {
        emitEvent('pricelist/search', { text });
      }
    }, 1400);

    return {
      catId,
      selectCategory,
      typeId,
      onTypeLoaded,
      pageTitle,
      inputSearchDebounce,
    };
  }
});
