import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_calendar_mounth = _resolveComponent("c-calendar-mounth")!

  return (_openBlock(), _createElementBlock("div", {
    class: "c-infinity-calendar",
    onScroll: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onScrollTrottle && _ctx.onScrollTrottle(...args)))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.calendarsMounts, (mounthInfo) => {
      return (_openBlock(), _createBlock(_component_c_calendar_mounth, {
        key: `${mounthInfo.mounth}_${mounthInfo.year}`,
        mounth: mounthInfo.mounth,
        year: mounthInfo.year,
        modelValue: _ctx.innerValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
        class: _normalizeClass({
        'c-infinity-calendar__mounth': true,
        'c-infinity-calendar__mounth--current': mounthInfo.currentMounth,
        'c-infinity-calendar__mounth--point': mounthInfo.currentPoint,
      })
      }, null, 8, ["mounth", "year", "modelValue", "class"]))
    }), 128))
  ], 32))
}