import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_c_transparent_button = _resolveComponent("c-transparent-button")!
  const _component_app_header = _resolveComponent("app-header")!

  return (_openBlock(), _createBlock(_component_app_header, null, {
    "toolbar-after": _withCtx(() => [
      (_ctx.progress)
        ? (_openBlock(), _createBlock(_component_ion_progress_bar, {
            key: 0,
            value: _ctx.progress,
            color: "secondary"
          }, null, 8, ["value"]))
        : _createCommentVNode("", true)
    ]),
    end: _withCtx(() => [
      _createVNode(_component_c_transparent_button, { onClick: _ctx.alertConfirmClose }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            name: "close-outline",
            class: "icon-medium"
          })
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}