import config from '@/config';
import { AppDB } from '@/database/AppDB';
import { bindOnline, isOnline } from '@/helpers/network';
import { createClient, createRepositories, Repositories } from '@/repositories';
import { createStore, StoreInstance } from '@/store';
import { prepareDomain, prepareBaseUrl } from '@/utils/domain';
import { sleep } from '@/utils/wait';
import { App, inject } from '@vue/runtime-core';
import toast from '@/ext/toast';
import { createProgressInformation } from '@/composables/progress';

export const LS_KEY_BASE_DOMAIN = 'app/baseDomain';

export interface PreloadOptions {
  progressCallback?(percent: number, message: string): void;
}

export function createAppContext() {
  const apiDomain = getApiDomain();
  const httpClient = createClient(`${apiDomain}${config.apiPath}`);
  const repositories = createRepositories(httpClient);

  let dbInstance = new AppDB(prepareDomain(apiDomain));
  const store = createStore(dbInstance, repositories, httpClient);

  function setApiDomain(domain: string) {
    // TODO: Возможно перед применением домена
    // нужно протестировать каконибудь запрос

    localStorage.setItem(LS_KEY_BASE_DOMAIN, prepareBaseUrl(domain));
    httpClient.defaults.baseURL = `${getApiDomain()}${config.apiPath}`;

    dbInstance = new AppDB(domain);
    store.recreateInstances(dbInstance);
  }

  function getApiDomain() {
    return localStorage.getItem(LS_KEY_BASE_DOMAIN) || config.defaultBaseDomain;
  }

  async function syncSavedRequests() {
    console.log('START SYNC');
    await store.car.syncSavedRequests();
    await store.visit.syncSavedRequests();
  }

  async function clearCache() {
    // fix: В какой-то момент, значение может быть переполнено
    // в предыдущих версиях. В новых сохранение отключено.
    localStorage.removeItem('camerakit-images');

    localStorage.removeItem('pdfjs.history');
    localStorage.removeItem('cache/new_visit_state');
    localStorage.removeItem('cache/new_store_visit_state');

    dbInstance.cacheQuery.clear();
    dbInstance.keyValue.clear();

    // Пересоздать все хранилища, чтобы кэш из памяти тоже был удален
    store.recreateInstances(dbInstance);
  }

  bindOnline(syncSavedRequests);
  if (isOnline()) syncSavedRequests();

  async function preloadMandatoryData(options: PreloadOptions = {}) {
    store.service.clearMemoryCached();

    await sleep(2000);// TMP

    options.progressCallback?.call(null, 10, 'Загрузка конфигурации');

    await Promise.all([
      store.config.loadCIDConfig(),
      store.suspect.getThisPointConfig(),
    ]);

    await sleep(2000);

    options.progressCallback?.call(null, 40, 'Загрузка списка услуг и товаров');
    await Promise.all([
      store.service.getAll(),
      store.service.getAllNomenclature(),
      store.pricePolicy.getAll(),
      store.point.getThisClient(),
    ]);

    await sleep(2000);

    options.progressCallback?.call(null, 70, 'Загрузка акций, исполнителей и контрагентов');
    await Promise.all([
      store.discount.getAll(),
      store.discount.getStaticAllCampain(),
      store.user.getDoers(),
      store.box.getAll(),
      store.group.getAll(),
    ]);

    options.progressCallback?.call(null, 100, 'Готово');
  }

  async function clearCacheJob() {
    const { progressInfo, popover } = await createProgressInformation();

    await popover.present();

    try {
      progressInfo.value = {
        // message: 'Очистка кэша...',
        message: 'Загружаем конфигурации...', // Почему? - по качану!
        progress: 5,
      };
      await clearCache();

      await preloadMandatoryData({
        progressCallback(percent, message) {
          // Нормализация относительно общего прогресса
          const progress = 5 + (percent * 0.75);

          progressInfo.value = {
            message: message + '...',
            progress,
          };
        }
      });

      progressInfo.value = {
        // message: 'Загрузка локализаций',
        message: 'Осталось немного',
        progress: 90,
      };
      await store.config.reloadCurrentLocale();

      progressInfo.value = {
        message: 'Готово',
        progress: 100,
      };

      popover.dismiss();
    }
    catch(e) {
      toast.error(e, 4000, {
        header: 'Ошибка кэша'
      });
      popover.dismiss();
      throw e;
    }
  }

  async function loginPreloadCacheJob() {
    const { progressInfo, popover } = await createProgressInformation();

    progressInfo.value = {
      message: 'Обновление данных...',
      progress: 1,
    };

    await popover.present();

    try {
      await preloadMandatoryData({
        progressCallback(percent, message) {
          progressInfo.value = {
            message: message + '...',
            progress: percent,
          };
        }
      });

      popover.dismiss();
    }
    catch(e) {
      toast.error(e, 3500, {
        header: 'Ошибка предзагрузки данных'
      });
      popover.dismiss();
      throw e;
    }
  }

  return {
    http: httpClient,
    repositories,
    setApiDomain,
    getApiDomain,
    syncSavedRequests,
    clearCache,
    preloadMandatoryData,
    loginPreloadCacheJob,
    clearCacheJob,
    
    get store() { return store; },
    getDb() { return dbInstance; },

    destroy() {
      throw new Error('AppContext.destroy() не реализована!');
    },
  };
}

export type AppContext = ReturnType<typeof createAppContext>;

export interface AppContextConfig {
  context?: AppContext;
  global?: boolean;
  injectKey?: string;
}

export default {
  install(app: App, config: AppContextConfig = {}) {
    const appContext = config.context || createAppContext();

    if (config.global !== false) {
      app.config.globalProperties.$app = appContext;
      Object.defineProperty(app.config.globalProperties, '$store', {
        get() { return appContext.store; }
      });
    }
    
    app.provide(config.injectKey || 'appContext', appContext);
  }
};

export function useApp(key?: string): AppContext {
  return inject(key || 'appContext') as AppContext;
}

export function useStore(appContextKey?: string): StoreInstance {
  const appContext = inject(appContextKey || 'appContext') as AppContext;
  return appContext.store;
}

export function useRepositories(appContextKey?: string): Repositories {
  const appContext = inject(appContextKey || 'appContext') as AppContext;
  return appContext.repositories;
}