
import { useVirtualKeyboard } from '@/composables/useVirtualKeyboard'
import { defineComponent, onMounted, PropType, computed, ref, watch } from 'vue'
import SimpleKeyboard from 'simple-keyboard'
import { useStore } from '@/composables/useApp';

export const INPUT_CAR_NUBER_PLACEHOLDER_DEFAULT = 'А123ХЕ177';
export const INPUT_CAR_NUBER_REGEXP_DEFAULT = /^[A-ZА-Я]{1}[\d]{3}[A-ZА-Я]{2}[\d]{2,3}$/;

export default defineComponent({
  emits: ['update:modelValue'],

  props: {
    modelValue: {
      type: String as PropType<string>,
      default: ''
    },
    carNumberPlaceholder: {
      type: String as PropType<string>,
      default: INPUT_CAR_NUBER_PLACEHOLDER_DEFAULT,
    },
    carNumberRegExp: {
      type: RegExp as PropType<RegExp>,
      default: () => INPUT_CAR_NUBER_REGEXP_DEFAULT
    },
    colored: {
      type: Boolean as PropType<boolean>,
      default: true
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const numberConfig = computed(() => store.config.cidConfigPrepared.value.inputCarNumber);

    const carNumber = ref(props.modelValue);
    watch(carNumber, inputValue => emit('update:modelValue', inputValue));
    watch(() => props.modelValue, newValue => {
      if (carNumber.value !== newValue) {
        carNumber.value = newValue;
        keyboard.setCurrentInputValue(newValue);
      }
    });

    /**
     * Доступный способ ввода относительно текущего значения
     * (ввод буквы, ввод цифры, ничего)
     */
    const availableInput = computed(() => {
      if (carNumber.value.length >= props.carNumberPlaceholder.length) {
        return 'disabled';
      }

      const nextChar = props.carNumberPlaceholder[carNumber.value.length];
      return (/\d/.test(nextChar))
        ? 'numeric'
        : 'word';
    });

    const isValid = computed(() => props.carNumberRegExp.test(carNumber.value));
    
    /** Разбивает номер на отдельные символы (для подсветки их разными цветами) */
    const carNumberColored = computed(() => {
      return carNumber.value.split('').map(char => ({
        isNumber: /\d/.test(char),
        char,
      }));
    });

    //#region Keyboard
    const keyboardContainer = ref(null);
    const input = ref<HTMLInputElement|null>(null);

    // WARNING! При изменении CID, клавиатура не перезагрузится,
    // т.к. в приложении такой ситуации впринципе не должно возникнуть
    // добавлять реактивность смысла не имеет.
    const keyboard = useVirtualKeyboard(keyboardContainer, {
      onInit: onInitKeyboard, // Дополнительно
      disableCaretPositioning: true,
      layout: {
        default: numberConfig.value.keyboardLayout,
      },
      buttonTheme: [
        {
          class: 'hg-small-height',
          buttons: numberConfig.value.keyboardLayoutStyle.small,
        },
        {
          class: 'c-input-car-numeric',
          buttons: numberConfig.value.keyboardLayoutStyle.numeric,
        }
      ],
      maxLength: 9,
    });

    function backspaceAct() {
      keyboard.instance?.handleButtonClicked('{bksp}');
    }

    function onInitKeyboard(instance?: SimpleKeyboard|null) {
      if (input.value && instance) {
        keyboard.setInput(input.value);
        instance.setInput(String(carNumber.value));
      }
    }

    onMounted(() => {
      onInitKeyboard(keyboard.instance);
    });
    //#endregion Keyboard

    return {
      carNumber,
      keyboardContainer,
      input,
      backspaceAct,
      availableInput,
      isValid,
      carNumberColored,
    };
  },
});
