
import { useStore } from '@/composables/useApp';
import { defineComponent, ref, PropType, watch, toRef } from 'vue';
import { get } from 'lodash';
import { useServicesCategoriesContext } from '@/composables/useServicesCategoriesContext';

export default defineComponent({
  props: {
    catId: {
      type: [String, Number] as PropType<string|number>,
      required: true,
    },
    searchWords: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const store = useStore();
    
    const titleGroup = store.point.getCategoryBreadcrumbsComputed(toRef(props, 'catId'));

    const {
      servicesCategoriesFiltered,
      loadServicesCategoriesByContext,
    } = useServicesCategoriesContext(toRef(props, 'searchWords'));

    const loadAction = () => loadServicesCategoriesByContext({ categoryId: props.catId });
    
    const loadComponentRef = ref<any>(null);
    watch(() => props.catId, () => {
      loadComponentRef.value?.load();
    });

    return {
      titleGroup,
      loadComponentRef,
      servicesCategoriesFiltered,
      loadAction,
      get,
    };
  }
});
