import { AsyncCache, minutes, prepareParams } from "@/helpers/cache"
import { BaseStore, OperationInfo, QueryCacheOptions } from "./BaseStore"
import uuid from 'uuid'
import {
  CarCollectionQuery, CarCollectionResponse,
  CarResponse, CarHistoryResponse,
  CarServicesPopularResponse, CarBodyRequest
} from "@/repositories/Models/Car"
import { SavedCarRequest } from "@/database/Tables/SavedCarRequest"
import { merge } from "lodash"
import { recreateCarResponseFromBody } from '@/helpers/restore'

export const ACTION_CREATE = 'create';
export const ACTION_UPDATE = 'update';

export class CarStore extends BaseStore {
  /**
   * Получить список автомобилей
   * 
   * CACHE: При обращении всегда идет запрос к серверу,
   * за исключением когда приложение в оффлайн режиме.
   * 
   * @param params 
   * @returns 
   */
  getCollection(params: CarCollectionQuery = {}): AsyncCache<CarCollectionResponse> {
    const key = ['cars_collection', ...prepareParams(params)];
    return this.cacheQuery(key, async () => {
      const { data } = await this.repositories.car.getCollection(params);
      return data;
    }, 0);
  }

  /**
   * Получение информации о конкретном автомобиле
   * 
   * CACHE: При обращении всегда идет запрос к серверу,
   * за исключением когда приложение в оффлайн режиме.
   * 
   * @param id идентификатор автомобиля
   * @param cacheOptions дополнительные опции при запросе данных из кэша
   * @returns 
   */
  getCar(id: string, cacheOptions: QueryCacheOptions = {}): AsyncCache<CarResponse> {
    return this.cacheQuery(
      ['car_single', id],
      async () => {
        const { data } = await this.repositories.car.getCar(id);
        return data;
      },
      cacheOptions.relevantTime || 0,
      async () => {
        // Попытка воссоздать информацию об автомобиле из сохраненного запроса
        const savedRequest = await this.getSavedRequest({ id }, ACTION_CREATE);
        if (savedRequest) {
          return recreateCarResponseFromBody(savedRequest.requestBody);
        }

        return undefined;
      }
    );
  }

  /**
   * Количество обращений
   * 
   * CACHE: При обращении всегда идет запрос к серверу,
   * за исключением когда приложение в оффлайн режиме.
   * 
   * @param id идентификатор автомобиля
   * @returns 
   */
  getHistory(id: string): AsyncCache<CarHistoryResponse> {
    return this.cacheQuery(['car_history', id], async () => {
      const { data } = await this.repositories.car.getHistory(id);
      return data;
    }, 0);
  }

  /**
   * Популярные услуги для автомобиля
   * 
   * CACHE: Данные кэшируются на 1-н час
   * 
   * @param id идентификатор автомобиля
   * @returns 
   */
  getPopularServices(id: string): AsyncCache<CarServicesPopularResponse> {
    const cacheRelevantMS = minutes(60);
    return this.cacheQuery(['car_popular_services', id], async () => {
      const { data } = await this.repositories.car.getPopularServices(id);
      return data;
    }, cacheRelevantMS);
  }

  /**
   * Синхронизировать данные сохраненных запросов с сервером
   */
  async syncSavedRequests() {
    const requests = await this.db.savedCarRequest.toArray();
    const noSends = requests.filter(sr => sr.attemptToSend == 0);

    for (let savedRequest of noSends) {
      // await - здесь специально, чтобы не отсылать все запросы на сервер разом
      await this.handleSavedRequest(savedRequest);
    }
  }

  /**
   * Обработка и отправка отложенного запроса
   * @param request 
   */
  protected async handleSavedRequest(request: SavedCarRequest) {
    try {
      switch (request.action) {
        case ACTION_CREATE:
          await this.repositories.car.create(request.requestBody);
          break;
        case ACTION_UPDATE:
          await this.repositories.car.update(request.carId, request.requestBody);
          break;
        default:
          throw new Error('Неизвестная операция для автомобиля');
      }

      // Запрос считается успешно отправленным и его необходимо удалить из БД
      this.db.savedCarRequest.delete(request._id as number);
    } catch (e: any) {
      const changesFail: Partial<SavedCarRequest> = {
        attemptToSend: request.attemptToSend + 1,
        lastSendDate: new Date(),
        responseError: {
          message: e?.message || 'Неизвестная ошибка'
        }
      };
      this.db.savedCarRequest.update(request._id as number, changesFail);
    }
  }

  /**
   * Создание новго автомобиля
   * 
   * SAVED REQUEST: В режиме оффлайн данные сохраняются во внутренней структуре БД,
   * и при подключении к интернету, будут отправлены на сервер
   * 
   * @param body данные для создание нового автомобиля
   */
  async create(body: CarBodyRequest): Promise<OperationInfo<CarResponse, string>> {
    if (this.isOnline) {
      const response = await this.repositories.car.create(body);
      return {
        entityId: response.data.id,
        sendingDelayed: false,
        response,
      };
    }

    const createCarRequest = await this.getSavedRequest(body, ACTION_CREATE);
    if (createCarRequest) { // Если запрос существует, то его данные нужно объединить с новыми
      const { mergedRequest } = this.mergeSavedRequest(createCarRequest, body);
      await this.db.savedCarRequest.put(mergedRequest);

      return {
        entityId: mergedRequest.carId,
        sendingDelayed: true,
        isMerge: true,
      };
    }

    if (!body.id) { // Сгенерируем Id, если не указан.
      // По умолчанию, если у автомобиля указан number (номер автомобиля),
      // то это значение является и идентификатором автомобиля.
      // Это не совсем корректно, но такой механизм в старом приложении для iPad
      body = { ...body, id: (body.number || uuid.v1()) };
    }

    const carSavedRequest: SavedCarRequest = {
      carId: body.id as string,
      number: body.number,
      action: ACTION_CREATE,
      attemptToSend: 0,
      createdAt: new Date(),
      requestBody: body
    }

    await this.db.savedCarRequest.put(carSavedRequest);

    return {
      entityId: carSavedRequest.carId,
      sendingDelayed: true,
      isMerge: false,
    };
  }

  /**
   * Изменение информации об автомобиле
   * 
   * SAVED REQUEST: В режиме оффлайн данные сохраняются во внутренней структуре БД,
   * и при подключении к интернету, будут отправлены на сервер
   * 
   * ВНИМАНИЕ! Для отложенного запроса в случае изменения идентификатора в теле (body)
   * обращаться нужно будет так-же по старому. Дабы не путаться, актуальный entityId
   * всегда возвращается в значении ответа.
   * 
   * @param id 
   * @param body 
   * @returns 
   */
  async update(id: string, body: CarBodyRequest): Promise<OperationInfo<CarResponse, string>> {
    if (this.isOnline) {
      const response = await this.repositories.car.update(id, body);
      return {
        entityId: response.data.id,
        sendingDelayed: false,
        response,
      };
    }

    let carExistRequest = await this.getSavedRequest({ id, number: body.number }, ACTION_UPDATE);
    if (!carExistRequest) {
      carExistRequest = await this.getSavedRequest({ id, number: body.number }, ACTION_CREATE);
    }

    if (carExistRequest) { // Если запрос существует, то его данные нужно объединить с новыми
      const { mergedRequest } = this.mergeSavedRequest(carExistRequest, body);
      await this.db.savedCarRequest.put(mergedRequest);

      return {
        entityId: mergedRequest.carId,
        sendingDelayed: true,
        isMerge: true,
      };
    }

    const carSavedRequest: SavedCarRequest = {
      carId: id, // body.id здесь использовать нельзя, т.к. он может отличаться от текущего идентификатора
      number: body.number,
      action: ACTION_CREATE,
      attemptToSend: 0,
      createdAt: new Date(),
      requestBody: body,
    }

    await this.db.savedCarRequest.put(carSavedRequest);

    return {
      entityId: carSavedRequest.carId,
      sendingDelayed: true,
      isMerge: false,
    };
  }

  /**
   * Удалить автомобиль
   * 
   * ВНИМАНИЕ! В режиме оффлайн удаление для существующих на сервере записей не работает
   * (только для созданных и сохраненных локально)
   */
  async remove(id: string) {
    if (this.isOnline) {
      await this.repositories.car.remove(id);
    }

    const savedRequest = await this.getSavedRequest({ id });
    if (savedRequest) {
      await this.db.savedCarRequest.delete(savedRequest._id as number);
    }

    // NOTE: C ACTION_UPDATE уже не прокатит =(

    throw new Error('В режиме ОФФЛАЙН невозможно осуществить операцию удаления автомобиля');
  }

  /**
   * Ищет отложенный запрос
   */
  public async getSavedRequest(keys: Pick<CarBodyRequest, "id"|"number">, action?: string): Promise<SavedCarRequest|undefined> {
    if (!keys.id && !keys.number) return undefined;

    let criterias: Record<string, any> = keys.number
        ? { number: keys.number }
        : { carId: keys.id };
    if (action) criterias.action = action;
    
    return await this.db.savedCarRequest.get(criterias);
  }

  /**
   * Объединяет старый запрос с новыми данными
   * 
   * @param request 
   * @param newBody 
   * @returns 
   */
  protected mergeSavedRequest(
    request: SavedCarRequest,
    newBody: CarBodyRequest
  ): {
    mergedRequest: SavedCarRequest;
    oldCarId: string;
  } {
    const oldCarId = request.carId;
    const mergedBody = merge(request.requestBody, newBody);
    const mergedRequest: SavedCarRequest = {
      ...request,
      requestBody: mergedBody,
      carId: mergedBody.id || mergedBody.number || uuid.v1(),
      number: mergedBody.number,
      attemptToSend: 0, // Для повторных попыток отправки
      // 'action' и остальные параметры обновлять не нужно!
    };

    return { mergedRequest, oldCarId };
  }
}
