import { Box } from '@/repositories/Models/Box';
import { ref, DeepReadonly } from 'vue';
import { useStore } from './useApp';

export function useBoxes() {
  const store = useStore();
  const boxes = ref<DeepReadonly<Box[]>>([]);
  
  async function loadBoxes() {
    boxes.value = await store.box.getAll();
  }

  return {
    boxes,
    loadBoxes,
  };
}