import { BaseCollectionResponse, BaseQueryPage } from './Base'
import { PointExt, PointType } from './Point'
import { Category } from './Category'
import { Discount } from './Discount'
import { Car, CarResponse } from './Car'
import { Image } from './Image'
import { DoerCollectionItem, ManagerCollectionItem } from './User'
import { SuspectConfigItem } from './Suspect'
import { ProvidedService, Service } from './Service'
import { CarGroup } from './CarGroup'
import { Box } from './Box'
import { PaymentTypeEnum } from './Pos'

/**
 * Статусы визита
 */
export enum CarVisitStatusEnum {
  New = 'new',
  Processed = 'processed',
  Finished = 'finished',
  Canceled = 'canceled',

  Garbage = 'garbage',
  Archive = 'archive',
  Merge = 'merge',
  Opened = 'opened',

  /** @deprecated Новый статус для предварительной записи */
  Preentry = 'preentry',
}

/**
 * Статус проверки
 */
export enum CarVisitCheckupEnum {
  /** Проверена */
  Verified = 'verified',

  /** Отклонена */
  Rejected = 'rejected',

  /** На проверке */
  Pending = 'pending',

  /** Предварительная запись, ожидающая обработки */
  PendingPreentry = 'pending_preentry',
}

/**
 * Достоверность
 */
export enum CarVisitVeracityEnum {
  /** Надежная */
  Strong = 'strong',

  /** Ненадежная */
  Weak = 'weak',
}

/**
 * Информация о визите автомобиля в автосервис
 */
export interface CarVisit {
  id: string;

  /** Дата/время создания записи */
  creationDate?: string; // datetime

  /** Дата/время начала обработки */
  processedDate?: string; // datetime

  /** Дата/время завершения */
  finishedDate?: string; // datetime

  /** Дата/время выезда машины из бокса */
  exitDate?: string; // datetime

  /** @type {string} Статус визита */
  status?: CarVisitStatusEnum;

  /** Стоимость работ */
  price?: number; // decimal

  /** Сумма, которую заплатил пользователь */
  payed?: number; // decimal

  /** Пользователь оплатил визит (да/нет) */
  isPayed?: boolean;

  /** Оплачено баллами */
  payedByScores?: number;

  /** @type {string} Статус проверки */
  checkup?: CarVisitCheckupEnum;
}

/** Краткая информация о категории для визита */
export type CarVisitCategory = Pick<Category, "id" | "name">;

/**
 * Информация на каких точках обслуживается/обслуживался автомобиль, во время визита
 */
export type CarVisitStation = Pick<PointType, "id" | "name"> & {
  priceCategories: CarVisitCategory;
};

/**
 * Скидки, которые были использваны во время визита
 */
export type CarVisitDiscount = Pick<Discount, "name"> & {
  percent: {
    min: number; // int32
    max: number; // int32
  };
};

export interface CarVisitFrame {
  id: string; // uuid
  image: Image;
}

/**
 * Подробная информация о менеджере обслуживающим визит
 */
export interface CarVisitManager extends ManagerCollectionItem {
  clientPoint: PointExt;
}

/**
 * Подробная информация о кассире.
 * По сути тот-же самый менеджер.
 */
export interface CarVisitCashier extends ManagerCollectionItem {
  clientPoint: PointExt;
}

/**
 * Подозрительные действия
 */
export interface CarVisitSuspect {
  /**
   * Идентификатор записи привязки.
   * НЕ является идентификатором подозрительного действия!
   * 
   * NOTE: Может ввести в заблуждение
   */
  id: number;

  suspiciousConfig: Pick<SuspectConfigItem, "id">;
}

/**
 * Оказанная услуга, в рамках визита
 */
export interface CarVisitProvideService extends ProvidedService {
  /** Подробная информация об услуге */
  service: Service;

  /** Примененная дисконтная компания */
  discountCampaign?: Discount;

  /** Точка обслуживания */
  pointType?: PointType;

  /** Категория в которой находится услуга */
  carCategory?: CarVisitCategory;

  /** Исполнители */
  doers?: DoerCollectionItem[];
}

/**
 * Расширенная информация о визите
 * (включая связанную информацию)
 */
export interface CarVisitExt extends CarVisit {
  /** @type {string} Способ оплаты */
  paymentType?: PaymentTypeEnum;

  /** @type {string} Статус соответствия */
  veracity: CarVisitVeracityEnum;

  /** Максимальная стоимость которую оплачивал клиент (из истории) */
  historyMaxPrice: number; // decimal

  /** Подробная информация об автомобиле */
  car?: CarResponse;

  /** Информация о боксе */
  box?: Box;

  /** Точки обслуживания в которых был автомобиль во время визита */
  stations: CarVisitStation[];

  /** Примененные скидки */
  discounts?: CarVisitDiscount[];

  /** Кадры с камеры (связанные изображения) */
  frames?: CarVisitFrame[];

  /** Награда менеджера (чаевые) */
  managerReward: number; // int32

  /** Список подозрительных действий */
  suspects?: CarVisitSuspect[];

  /**
   * Данный параметр никогда не передается сервером,
   * данное поле добаляется JS на стороне клиента,
   * если данный заказ был создан оффлайн или отредактирован
   * и еще не синхронизирован с сервером.
   */
  isOffline?: boolean;
}

export interface CarVisitBodySuspectItem {
  suspiciousConfig: Pick<SuspectConfigItem, "id">;
}

/**
 * Данные запроса, для добавления/обновления информации о визите
 */
export interface CarVisitBodyRequest extends Partial<CarVisit> {
  car?: Pick<Car, "id" | "number">;

  group?: Pick<CarGroup, "id">;
  
  box?: Pick<Box, "id">;

  providedServices?: ProvideServiceBodyItem[];

  suspects?: CarVisitBodySuspectItem[];
}

export interface ProvideServiceBodyItem {
  id?: string;
  pointType: Pick<PointType, "id">;
  carCategory: Pick<Category, "id">;
  service: Pick<Service, "id">;
  doers: Pick<DoerCollectionItem, "id">[];
  discountPercent?: number|null;
  discountSum?: number|null;
  discountCampaign?: Pick<Discount, "id"|"type">|null;
  qty: number;
  singleItemPrice: number;
  totalPrice: number;
  managerReward?: number;
  subcategoryName?: string;
}

/**
 * Полная информация о визите
 * (включая связанную информацию)
 */
export interface CarVisitResponse extends CarVisitExt {
  /** Информация о менеджере */
  manager?: CarVisitManager;

  /** Информация о кассире */
  cashier?: CarVisitCashier;
  
  /** Предоставленные услуги */
  providedServices?: CarVisitProvideService[];

  /** Визит может быть оплачен (да/нет) */
  isPayable: boolean;

  /** Есть ли подозрительные действия */
  suspicious: boolean;

  /** Контрагент */
  group?: CarGroup;
}

export interface CarVisitProcessingFrame {
  id: string; // uuid
  image: Pick<Image, "imageUrl">;
}

export type CarVisitProcessingProvideService = CarVisitProvideService & {
  service: Pick<Service, "id" | "name" | "fluid" | "repeatable">;
}

export interface CarVisitProcessingResponse extends CarVisit {
  /** Визит может быть оплачен (да/нет) */
  isPayable: boolean;

  /** Подробная информация об автомобиле */
  car: CarResponse;

  /** TODO: Кадры с камеры (связанные изображения) */
  frames: CarVisitProcessingFrame[];

  /** Предоставленные услуги */
  providedServices?: CarVisitProcessingProvideService[];
}

/**
 * Краткая информация о количестве текущих визитов
 */
export interface CarVisitCountersResponse {
  /** Количество новых визитов FIXME: должно быть числом */
  new: string; // int32

  /** Не до конца понятно, как используется (сейчас постоянно == 0) */
  timestamp: number; // int64

  /** Количество визитов в обработке. FIXME: должно быть числом */
  process: string;

  /** Значение true - означает, что значение еще не обновлялось с сервера (сервер данное значение не возвращает) */
  emptyInited?: boolean; 
}

/**
 * Подробная информация о визите
 * (включая связанную информацию)
 */
export interface CarVisitCollectionItem extends CarVisitExt {
  /**
   * Предоставленные услуги
   * 
   * NOTE: На стырых бекендах не всегда есть.
   * Было добавлено по просьбе, для рассчета нормативов
   */
  providedServices?: CarVisitProvideService[];
}

export type CarVisitCollectionResponse = BaseCollectionResponse<CarVisitCollectionItem>;
export type CarVisitCollectionQuery = BaseQueryPage & {
  pane?: 'archive'|'process'|'new';
  [param: string]: any;
};

export type VisitArchiveQuery = BaseQueryPage & {
  /** Фраза для поиска */
  q?: string;

  /** Дата в формате YYYY-MM-dd (2020-11-23) */
  date?: string;
};


// FIXME: ...
export type CarVisitItem = CarVisitCollectionItem & CarVisit & CarVisitExt & CarVisitResponse;