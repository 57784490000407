
import { defineComponent, onMounted, ref, computed } from 'vue'
import { useApp } from '@/composables/useApp'
import { useToast } from '@/composables/useToast'
import { alertController } from '@ionic/core'
import { PosInfoResponse } from '@/repositories/Models/Pos'
import { useRouter } from 'vue-router'
import { useMetrica } from '@/composables/useMetrica'
import { useI18n } from 'vue-i18n';

export default defineComponent({
  setup() {
    const toast = useToast();
    const router = useRouter();
    const { repositories } = useApp();
    const { emitEvent } = useMetrica();
    const { t } = useI18n();

    /** @private */
    function createConfirmAction(header: string, message: string, action: () => Promise<void>) {
      return async () => {
        const alert = await alertController.create({
          header,
          message,
          buttons: [
            {
              text: t('views.pos.alert_confirm_btn_cancel'),
              role: 'cancel',
            },
            {
              text: t('views.pos.alert_confirm_btn_confirm'),
              async handler() {
                try {
                  await action.call(null);
                } catch (e) {
                  toast.error(e, 4000, {
                    header: t('views.pos.header_error_pos')
                  });
                }

                alert.dismiss(); // async
              },
            }
          ],
        });

        alert.present(); // async
      }
    }

    const posInfo = ref<PosInfoResponse|null>(null);
    const sessionOpened = computed(() => !!posInfo.value?.sessionOpened);

    async function loadInfo() {
      try {
        const { data } = await repositories.pos.getInfo();
        posInfo.value = data;
      } catch (e) {
        toast.error(e, 4000, {
          header: t('views.pos.header_error_pos')
        });
      }
    }

    onMounted(() => loadInfo());

    /** Открыть касовую смену */
    const openSession = createConfirmAction(
      t('views.pos.alert_confirm_open_session_header'),
      t('views.pos.alert_confirm_open_session_message'),
      async () => {
        if (sessionOpened.value) return;
        await repositories.pos.openDrawer();
        emitEvent('pos/session/open');
      }
    );

    function toCashAction(action: 'in'|'out') {
      emitEvent(`pos/cach/open-page/${action}`);
      router.push({
        name: 'pos-cash-action',
        params: { action }
      });
    }

    /** Распечатать X-отчет */
    const reportX = createConfirmAction(
      t('views.pos.alert_confirm_report_x_header'),
      t('views.pos.alert_confirm_report_x_message'),
      async () => {
        emitEvent('pos/report/print/x');
        await repositories.pos.reportX();
      },
    );

    /** Распечатать Z-отчет */
    const reportZ = createConfirmAction(
      t('views.pos.alert_confirm_report_z_header'),
      t('views.pos.alert_confirm_report_z_message'),
      async () => {
        emitEvent('pos/report/print/z');
        await repositories.pos.reportZ();
      },
    );

    /** Сверка итогов (эквайринг) */
    async function acquiringRevise() {
      emitEvent('pos/acquiring-revise');

      try {
        await repositories.pos.acquiringRevise();
      } catch (e) {
        toast.error(e, 4000, {
          header: t('views.pos.header_error_pos')
        });
      }
    }

    return {
      acquiringRevise,
      toCashAction,
      reportX,
      reportZ,
      sessionOpened,
      openSession,
    };
  }
});
