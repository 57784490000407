/**
 * NOTE: Практически во всех запроса здесь используется функция assertBodyStatus,
 * которая проверяет ответ на наличие { success: false }. Это костыль, необходимый,
 * для отслеживания ошибок, т.к. в API кассы всегда возвращается статус ответа 200
 */

import { BaseRepository, AsyncResponse} from './BaseRepository'
import { BaseSuccessResponse } from './Models/Base'
import assertBodyStatus from './util/assertBodyStatus'
import {
  PosInfoResponse, PosPayVisitBody,
  PosPayVisitInitResponse, PosPayVisitConfirmBody,
} from './Models/Pos'

export class PosRepository extends BaseRepository {
  /**
   * Получение базовой информации о состоянии кассы
   * 
   * @returns 
   */
  getInfo(): AsyncResponse<PosInfoResponse> {
    return assertBodyStatus(
      this.client.get('pos/info'),
      'Не удалось получить информацию о кассе'
    );
  }

  /**
   * Открыть кассу
   * 
   * @returns 
   */
  openDrawer(): AsyncResponse<BaseSuccessResponse> {
    return assertBodyStatus(
      this.client.post('api/pos/open-drawer', {}),
      'Не удалось открыть кассовую смену'
    );
  }

  /**
   * Возобновить/открыть/обновить кассу
   * TODO: Не до конца понятно, как и где использовать
   * @returns 
   */
  sessionRenew(): AsyncResponse<BaseSuccessResponse> {
    return this.client.post('pos/session/renew');
  }

  /**
   * Внести наличные
   * 
   * TODO: Разобраться, почему баланс не меняется
   * 
   * @param sum сумма в рублях
   * @param comment комментарий
   */
  cashIn(sum: number, comment?: string): AsyncResponse<BaseSuccessResponse> {
    return this.client.post('pos/cash/in', {
      sum,
      comment,
    });
  }

  /**
   * Изъятие наличных
   * 
   * TODO: Разобраться, почему баланс не меняется
   * 
   * @param sum сумма в рублях
   * @param comment комментарий
   */
   cashOut(sum: number, comment?: string): AsyncResponse<BaseSuccessResponse> {
    return this.client.post('pos/cash/out', {
      sum,
      comment,
    });
  }

  /**
   * Создать платеж, для визита
   * 
   * @param visitId идентификатор визита
   * @param body данные для создания платежа
   * @returns 
   */
  payInit(visitId: string, body: PosPayVisitBody): AsyncResponse<PosPayVisitInitResponse> {
    return assertBodyStatus(
      this.client.post(`pos/pay/${visitId}/init`, body),
      'Не удалось создать платеж для визита'
    );
  }

  /**
   * Отмена платежа, для визита
   * 
   * @param visitId идентификатор визита
   * @param body данные для отмены платежа
   * @returns 
   */
  payCancel(visitId: string, body: Partial<PosPayVisitBody>): AsyncResponse<BaseSuccessResponse> {
    return assertBodyStatus(
      this.client.post(`pos/pay/${visitId}/cancel`, body),
      'Не удалось отменить оплату для визита'
    );
  }

  /**
   * Подтвердить платеж, для визита
   * 
   * @param visitId идентификатор визита
   * @param body данные для подтверждения платежа
   * @returns 
   */
  payConfirm(visitId: string, body: PosPayVisitConfirmBody): AsyncResponse<BaseSuccessResponse> {
    return assertBodyStatus(
      this.client.post(`pos/pay/${visitId}/confirm`, body),
      'Не удалось подтвердить платеж'
    );
  }

  /**
   * Создать возврат средств
   * 
   * @todo
   * 
   * @param visitId идентификатор визита
   */
  payReturnInit(visitId: string): AsyncResponse<BaseSuccessResponse> {
    return assertBodyStatus(
      this.client.post(`pos/pay/${visitId}/return/init`),
      'Не удалось создать возврат средств'
    );
  }

  /**
   * Подтвердить возврат средств
   * 
   * @todo
   * 
   * @param visitId идентификатор визита
   */
  payReturnConfirm(visitId: string): AsyncResponse<BaseSuccessResponse> {
    return assertBodyStatus(
      this.client.post(`pos/pay/${visitId}/return/confirm`),
      'Не удалось подтвердить возврат'
    );
  }

  /**
   * Отменить возврат средств
   * 
   * @todo
   * 
   * @param visitId идентификатор визита
   */
  payReturnCancel(visitId: string): AsyncResponse<BaseSuccessResponse> {
    return assertBodyStatus(
      this.client.post(`pos/pay/${visitId}/return/cancel`),
      'Не удалось отменить возврат'
    );
  }

  /**
   * Сверка итогов
   * 
   * @returns 
   */
  acquiringRevise(): AsyncResponse<any> {
    return this.client.post('pos/acquiring/revise');
  }

  /**
   * Отправка X отчета
   * 
   * @returns 
   */
  reportX(): AsyncResponse<BaseSuccessResponse> {
    return assertBodyStatus(
      this.client.post('pos/report/x', {}),
      'Не удалось отправить X отчет'
    );
  }

  /**
   * Отправка Z отчета
   * 
   * @returns 
   */
  reportZ(): AsyncResponse<BaseSuccessResponse> {
    return assertBodyStatus(
      this.client.post('pos/report/z', {}),
      'Не удалось отправить Z отчет'
    );
  }
}