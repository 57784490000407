import { get } from "lodash"
import { createError, AsyncResponse } from "../Clients/AxiosClient"

/**
 * Сгенерирует ошибку запроса в случае если в теле ответа содержиться `{ success: false }`.
 * Этот метод применяется в качестве "костыля", т.к. некоторые end-points API
 * возвращают код ответа 200, даже в случае возникновения ошибок.
 * 
 * @throws {AxiosError|Error}
 * 
 * @param response 
 * @param defaultMessage сообщение по умолчанию (в случае, если в ответе не указано сообщение об ошибке)
 */
export function assertBodyStatus<T = any>(responsePromise: AsyncResponse<T>, defaultMessage?: string): AsyncResponse<T> {
  return new Promise((resolve, reject) => {
    responsePromise.then(response => {
      if (get(response.data, 'success') === false) {
        const error = createError(response, get(response.data, 'error', defaultMessage));
        reject(error);
      } else {
        resolve(response);
      }
    }).catch(reject);
  });
}

export default assertBodyStatus;