/**
 * Ловит и игнорирует исключение.
 * В случае исключения возвращает значение по умолчанию
 * 
 * @param callback 
 * @param def значение возвращаемое в случае если при выполнении callback выскочит исключение
 * @returns 
 */
export async function asyncQueryErrorIgnore<T = any, D = any>(callback: () => Promise<T>, def: D): Promise<T|D> {
  try {
    return await callback();
  } catch {
    return def;
  }
}