/**
 * # Помощники для работы с графиком смен
 */

import { Shift } from "@/repositories/Models/Point";
import { datetimeToTimeNumber, MINUTES_DAY_NUMBER, parseToDate, timeNumberToHM } from './datetime';

export interface ShiftTimeGridItem {
  timeNumber: number;
  minutesBeginDay: number;
  h: number;
  m: number;
  text: string;
}

export interface ShiftInformationExtended {
  id: number;
  name: string;
  timeFrom: Date;
  timeTo: Date;
  timeFromMinutes: number;
  timeToMinutes: number;
  timeFromMinutesEdgeSmoothing: number;
  timeToMinutesEdgeSmoothing: number;
  timeGrid: ShiftTimeGridItem[];
}

/**
 * Сконвертирует все графики смен в расширенный формат
 * 
 * @param shifts 
 * @param stepMinutes 
 * @returns 
 */
export function shiftConvertAllToInformationExtended(shifts: Shift[], stepMinutes = 30): ShiftInformationExtended[] {
  return shifts
    .map(shift => shiftConvertToInformationExtended(shift, stepMinutes))

    // Сортируем смены, как нужно
    .sort((a, b) =>  a.timeFromMinutes - b.timeFromMinutes);
}

/**
 * Сконвертирует информацию о смене, в расширенную информацию
 * с сеткой времени, скорректированную по интервалам
 * 
 * @param shift 
 * @param stepMinutes 
 * @returns 
 */
export function shiftConvertToInformationExtended(shift: Shift, stepMinutes = 30): ShiftInformationExtended {
  const timeFrom = parseToDate(shift.timeFrom);
  const timeTo = parseToDate(shift.timeTo);
  const timeFromMinutes = datetimeToTimeNumber(timeFrom);
  const timeToMinutes = datetimeToTimeNumber(timeTo);
  const [
    timeFromMinutesEdgeSmoothing,
    timeToMinutesEdgeSmoothing
  ] = shiftsSmoothingEdge(timeFromMinutes, timeToMinutes, stepMinutes);

  const timeGridNumbers = shiftsFillArrayMinutes(
    timeFromMinutesEdgeSmoothing,
    timeToMinutesEdgeSmoothing,
    stepMinutes
  );

  const timeGrid = timeGridNumbers.map(numberMinutesToTimeGridItem);

  return {
    id: shift.id,
    name: shift.name,
    timeFrom,
    timeTo,
    timeFromMinutes,
    timeToMinutes,
    timeFromMinutesEdgeSmoothing,
    timeToMinutesEdgeSmoothing,
    timeGrid
  };
}

/**
 * Сконвертирует число обозначающее количество минут с начала для в объект типа
 * ShiftTimeGridItem, предназначенный для отображения в сетке расписния
 * 
 * @param timeNumber 
 * @returns 
 */
export function numberMinutesToTimeGridItem(minutesBeginDay: number): ShiftTimeGridItem {
  const timeNumber = minutesBeginDay % MINUTES_DAY_NUMBER;
  const [h, m] = timeNumberToHM(timeNumber);
  const mm = m > 9 ? `${m}` : `0${m}`;
  const text = `${h}:${mm}`;

  return {
    timeNumber,
    minutesBeginDay,
    h, m,
    text
  };
}

/**
 * Заполнит массив значениями в минутах,
 * в заданном интервале с заданным шагом
 * 
 * @param from время начала в минутах
 * @param to время окончания в минутах
 * @param step интервалы времени
 * @returns 
 */
export function shiftsFillArrayMinutes(from: number, to: number, step: number) {
  let minutesArray: number[] = [];

  if (from >= to) {
    to += MINUTES_DAY_NUMBER;
  }

  for (let curr = from; curr <= to; curr += step) {
    minutesArray.push(curr);
  }

  return minutesArray;
}

/**
 * Сдвигает график (при необходимости), чтобы его можно
 * было наложить на сетку с заданными интервалами времениъ
 * (сдвиг вниз)
 * 
 * @param from время начала в минутах
 * @param to время окончания в минутах
 * @param step интервалы времени
 * @returns 
 */
export function shiftsSmoothingEdge(from: number, to: number, step: number) {
  const sFrom = Math.ceil(from / step) * step;
  const sTo = Math.ceil(to / step) * step;

  return [sFrom, sTo];
}

/**
 * Сдвигает значение вниз в случае если оно не
 * списывается в график с указанным шагом
 * @param value 
 * @param step 
 */
export function shiftsSmoothingValue(value: number, step: number) {
  return Math.ceil(value / step) * step;
}

/**
 * Вернет true, если конечное время текущей смены,
 * равняется начальному времени следующей
 * 
 * @param curr текущая смена
 * @param next следующая смена
 * @returns 
 */
export function isShiftsInfoIntersectionEnd(curr: ShiftTimeGridItem[], next: ShiftTimeGridItem[]): boolean {
  if (curr.length && next.length) {
    const curEnd = curr[curr.length - 1].timeNumber;
    const nextFirst = next[0].timeNumber;

    return curEnd == nextFirst;
  }

  return false;
}
