
import { IonApp } from '@ionic/vue';
import { defineComponent } from 'vue';
import { VueIonicOutlet } from '@/ext/ionic/outlet/VueIonicOutlet';
import { useMetrica } from './composables/useMetrica';
import { RouteLocationNormalized, useRouter } from 'vue-router';
import { get } from 'lodash';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    VueIonicOutlet,
  },
  setup() {
    const { emitEvent } = useMetrica();
    const router = useRouter();

    function getRouteInfoForMetric(route: RouteLocationNormalized) {
      const path = String(get(route.matched, '0.path', 'unknown'));
      const name: string = route.name ? String(route.name) : path;

      return {
        name,
        path,
        query: route.query,
      }
    }
    
    router.afterEach((to, from) => {
      const toInfo = getRouteInfoForMetric(to);
      const fromInfo = getRouteInfoForMetric(from);
      
      emitEvent('page/' + toInfo.name, {
        ...toInfo,
        prev: fromInfo,
      });
    });
  }
});
