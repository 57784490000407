
import { defineComponent, PropType, computed } from 'vue';
import { CarVisitProvideService } from '@/repositories/Models/CarVisit';
import { useCurrency } from '@/composables/currency';

export default defineComponent({
  props: {
    provideService: {
      type: Object as PropType<CarVisitProvideService>,
      required: true,
    },
    showDiscount: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const { formatCurrency } = useCurrency();

    const discountViewBadges = computed<string[]>(() => {
      let badges: string[] = [];

      let staticDiscountPercent = props.provideService.discountPercent || props.provideService.discountCampaign?.percent || 0;
      if (staticDiscountPercent) {
        badges.push(`-${staticDiscountPercent}%`);
      }

      if (props.provideService.discountSum) {
        const sumFormat = formatCurrency(props.provideService.discountSum);
        badges.push('-' + sumFormat);
      }

      return badges;
    });

    const doersChips = computed(() => {
      return (props.provideService.doers || [])
        .map(doer => doer.name)
        .filter(name => !!name);
    });

    return {
      discountViewBadges,
      doersChips,
    };
  }
});
