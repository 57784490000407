
import { useStore } from '@/composables/useApp';
import { defineComponent, inject, ref } from 'vue';
import { ProvidedServicesInformation } from '@/store/ServiceStore';
import ServiceProvideDoersItem from './ServiceProvideDoersItem.vue';
import { useDoers } from '@/composables/useDoers';
import { intersectionWith, isEqual } from 'lodash';
import { NewVisitSatate } from '@/store/NewVisitState';
import { useMetrica } from '@/composables/useMetrica';

export default defineComponent({
  components: {
    ServiceProvideDoersItem,
  },
  props: {
    stateInjectKey: {
      type: String,
      required: true,
    }
  },
  setup(props) {
    const store = useStore();
    const { doers, loadDoers } = useDoers();
    const { emitEvent } = useMetrica();

    const visitAction = inject<string>('visitAction', 'new');
    const visitState = inject<NewVisitSatate>(props.stateInjectKey, null as unknown as NewVisitSatate);
    if (!visitState) {
      throw new Error('Не найдено состояние с данными заказа');
    }

    const collectionServicesInfo = ref<ProvidedServicesInformation[]>([]);
    async function loadServicesByProvidedItems() {
      collectionServicesInfo.value = await store.service.getProvidedServicesInformation(
        visitState.body.providedServices || [],
        visitState.body.group?.id,
        visitState.body.car?.id,
        false
      );
    }

    async function load() {
      await Promise.all([
        loadServicesByProvidedItems(),
        loadDoers(),
      ]);
    }

    function setDefaultDoers(doers: Array<{ id: number; }>) {
      emitEvent(`visit/${visitAction}/doers/set-default`, { doers: [ ...doers ] });
      visitState.defaultDoers = doers;

      // При выборе исполнителя по умолчанию, устанавливаем
      // всех исполнителей, согдасно этому правилу
      collectionServicesInfo.value.forEach(({ provide }) => {
        provide.doers = [ ...doers ];
      });
    }

    function setItemDoers(item: ProvidedServicesInformation, doers: Array<{ id: number; }>) {
      emitEvent(`visit/${visitAction}/doers/set-service`, { doers: [ ...doers ] });
      item.provide.doers = doers;

      // При выборе исполнителей для элемента правильно корректируем
      // значение по-умолчанию, для выбранных исполнителей
      const doersItems = collectionServicesInfo.value.map(item => item.provide.doers);
      const doersExistsAll = intersectionWith(...doersItems, isEqual);

      visitState.defaultDoers = doersExistsAll;
    }

    return {
      collectionServicesInfo,
      visitState,
      load,
      doers,
      setDefaultDoers,
      setItemDoers,
    };
  },
});
