import { BaseRepository, AsyncResponse} from './BaseRepository';
import {
  DiscountCollectionResponse,
  DiscountCollectionQuery,
  StaticDiscountCampaignCollectionQuery,
  StaticDiscountCampaignCollectionResponse,
  GiftCampaignCollectionQuery,
  // GiftCampaignCollectionResponse,
  LeveledCampaignCollectionQuery,
  // LeveledCampaignCollectionResponse,
} from './Models/Discount';

export class DiscountRepository extends BaseRepository {
  /**
   * Получить список доступных скидок
   * 
   * @param params 
   * @returns 
   */
  getCollection(params: DiscountCollectionQuery = {}): AsyncResponse<DiscountCollectionResponse> {
    return this.client.get('discounts', { params });
  }

  /**
   * Получает список статических акций, которые применяют определенный
   * процент скидки в заданное время и по заданным дням
   * 
   * @param params 
   * @returns 
   */
  getStaticDiscountCampaigns(params: StaticDiscountCampaignCollectionQuery = {}): AsyncResponse<StaticDiscountCampaignCollectionResponse> {
    return this.client.get('static-discount-campaigns', { params });
  }

  /**
   * Получает список подарочных акций для конкретного клиента
   * 
   * FIXME: Возвращаемые типы данных не соответствуют описанию интерфейса,
   * в будущем нужно сделать чтобы енкодер на стороне бэка, нормально кодировал данные в JSON.
   * 
   * @param params 
   * @returns 
   */
  getGiftCampaignsByCar(params: GiftCampaignCollectionQuery): AsyncResponse<any /*GiftCampaignCollectionResponse*/> {
    return this.client.get('get-gift-by-car-id', { params });
  }

  /**
   * Получает список накопительных кампаний
   * 
   * FIXME: Возвращаемые типы данных не соответствуют описанию интерфейса,
   * в будущем нужно сделать чтобы енкодер на стороне бэка, нормально кодировал данные в JSON.
   * 
   * @param params 
   * @returns 
   */
  getLeveledCampaignsByCar(params: LeveledCampaignCollectionQuery): AsyncResponse<any /*LeveledCampaignCollectionResponse*/> {
    return this.client.get('leveled-discount-by-client', { params });
  }
}