import { ClientInstance } from './Clients/AxiosClient'
export * from './Clients/AxiosClient'

import { UserRepository } from './UserRepository'
import { CarRepository } from './CarRepository'
import { PointRepository } from './PointRepository'
import { VisitRepository } from './VisitRepository'
import { DiscountRepository } from './DiscountRepository'
import { CarGroupRepository } from './CarGroupRepository'
import { ServiceRepository } from './ServiceRepository'
import { PosRepository } from './PosRepository'
import { BoxRepository } from './BoxRepository'
import { SuspectRepository } from './SuspectRepository'
import { PricePolicyRepository } from './PricePolicy'
import { ReportRepository } from './ReportRepository'

export type { ClientInstance };

export type Repositories = ReturnType<typeof createRepositories>;

export function createRepositories(client: ClientInstance) {
  return {
    user: new UserRepository(client),
    car: new CarRepository(client),
    point: new PointRepository(client),
    visit: new VisitRepository(client),
    discount: new DiscountRepository(client),
    group: new CarGroupRepository(client),
    service: new ServiceRepository(client),
    pos: new PosRepository(client),
    box: new BoxRepository(client),
    suspect: new SuspectRepository(client),
    pricePolicy: new PricePolicyRepository(client),
    report: new ReportRepository(client),
  };
}

export {
  UserRepository,
  CarRepository,
  PointRepository,
  VisitRepository,
  DiscountRepository,
  CarGroupRepository,
  ServiceRepository,
  PosRepository,
  BoxRepository,
}
