import { reactive, readonly, UnwrapRef, DeepReadonly } from "vue"
import { CarGroupCollectionItem } from "@/repositories/Models/CarGroup"
import { BaseStore, BaseStoreContext } from "./BaseStore"

export interface CarGroupStoreState {
  items: CarGroupCollectionItem[]|null;
}

export class CarGroupStore extends BaseStore {
  protected state: UnwrapRef<CarGroupStoreState>;

  constructor(ctx: BaseStoreContext) {
    super(ctx);

    this.state = reactive({
      items: null,
    });
  }

  /**
   * Получить полный список групп-партнеров
   * 
   * CACHE: Данные автоматически кэшируются, и запрашиваются по
   * требованию только первый раз после запуска приложения
   * 
   * @returns 
   */
  async getAll(): Promise<DeepReadonly<CarGroupCollectionItem[]>> {
    if (this.state.items) {
      return readonly(this.state.items);
    }

    const cache = await this.cacheQuery(['car_groups_all'], async () => {
      const { data } = await this.repositories.group.getCollection({ limit: 1000 });
      return data.items;
    }, 0);

    this.state.items = cache.data;
    return readonly(this.state.items);
  }

  /**
   * Загрузить список и вернуть контрагента по его ID
   * 
   * @param id идентификатор партнера (контрагента)
   * @returns 
   */
  async filterById(id: number) {
    const list = await this.getAll();
    const index = list.findIndex(item => item.id == id);
    return index !== -1 ? list[index] : null;
  }
}