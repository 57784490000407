
import { defineComponent, ref, watch, computed } from 'vue';
import { useApp, useStore } from '@/composables/useApp';
import { BaseCollectionResponse, BaseQueryPage } from '@/repositories/Models/Base'
import VisitsArchiveGroupsByDate from '@/components/visit/VisitsArchiveGroupsByDate.vue';
import { getCarNumberPreparedAndType } from '@/utils/car';
import { CarNumberTypeEnum } from '@/repositories/Models/Car';

export default defineComponent({
  components: {
    VisitsArchiveGroupsByDate,
  },

  props: {
    search: {
      type: String,
      required: false,
    },
  },

  setup(props) {
    const { repositories } = useApp();
    const store = useStore();

    const cInfiniteLoadingRef = ref<any>(null);
    const numberConfig = computed(() => store.config.cidConfigPrepared.value.inputCarNumber);

    watch(() => props.search, () => {
      cInfiniteLoadingRef.value?.resetAndLoad();
    });

    /**
     * Обрабатывает слова запроса для поиска
     * 
     * @param words поисковая фраза
     * @param onlyCarNumber преобразовывать только по алгоритму автомобильных номеров
     * @returns обработанное или преобразованное значение
     */
    function processQueryWords(words: string|undefined, onlyCarNumber: boolean): string {
      if (!words) return '';

      if (words.length > 6 || onlyCarNumber) { // Не обязательно, но лишний раз циклы с регулярками гонять не хочется
        const { number, numberType } = getCarNumberPreparedAndType(words, {
          translitChars: numberConfig.value.translitChars,
          typesRecognition: numberConfig.value.typesRecognition,
        });

        // Если предусмотрен поиск только по автомобильным номерам,
        // то всегда возвращаем преобразованный номер
        if (onlyCarNumber) {
          return number;
        }

        // Если то, что ввел пользователя воспринимается как номер автомобиля,
        // то это и есть номер автомобиля :), мы возвращаем его обработанную версию,
        // где номер был уже транслитерирован в латиницу.
        // Решает проблему, когда при вводе номера кириллицей, машина неищется, потому, как она в латинице.
        if (numberType !== CarNumberTypeEnum.Other && numberType !== CarNumberTypeEnum.Unknown) {
          return number;
        }
      }

      return words;
    }

    async function loadVisitsSearch(params: BaseQueryPage): Promise<BaseCollectionResponse> {
      const { data } = await repositories.visit.getArchive({
        ...params,
        q: processQueryWords(props.search, true)
      });

      return data;
    }

    return {
      loadVisitsSearch,
      cInfiniteLoadingRef,
    };
  }
});
