import { Category } from './Category';
import { BaseQueryPage, BaseCollectionResponse } from './Base';

export enum PointTypeStyleEnum {
  /** Сервис */
  Service = 'service',
  /** Магазин */
  Market = 'market',
}

/**
 * Информация о точке обслуживания.
 * (Имеет связанные с ней категории услуг)
 */
export interface PointType {
  id: number;

  /** Наименование (например: Автомойка, Core-Cafe, Детейлинг) */
  name: string;

  /**
   * Тип точки (сервис, магазин)
   * @type {string}
   */
  style: PointTypeStyleEnum;

  /** Имеется ли возможность мультивыбора */
  categoryMultichoice: boolean;
}

export type PointTypeCategoryItem = Pick<Category, "id" | "name">;

/**
 * Расширенная информация о типе точки обслуживания
 */
export interface ThisClientPointTypeItem extends PointType {
  carCategories?: PointTypeCategoryItem[];
}

/**
 * Информация о текущем автосервисе
 * Сам автосервисможет иметь несколько
 * точек обслуживания: Шиномонтаж, Автомойка, Магазин и т.п.
 */
export interface ThisClientPointResponse {
  id: number;

  /** Точка имеет шиномонтаж */
  tireKeeping: boolean;

  /** Точка имеет магазин */
  hasMarket: boolean;

  /** Список точек обслуживани */
  pointTypes: ThisClientPointTypeItem[];
}

/**
 * Здание автосервиса (заведение)
 */
export interface Point {
  id: number; // int64

  /** Название города в котором находится */
  city: string;

  /** Адрес заведения */
  street: string;

  /** Количество боксов в автосервисе */
  boxes: number; // int32

  /** Ссылка на изображение (https://...) */
  photoUrl: string;

  /** Сохраняется вся история о визитах (архив визитов включен) */
  enableVisitArchive: boolean;

  /** Счет с НДС (да/нет) */
  invoiceWithVat: boolean;
}

/**
 * Расширенная информация об автосервисе
 */
export interface PointExt extends Point {
  settings: PointSettings;
}

/**
 * Дополнительные настройки заведения
 * В тексте могут присутсвовать шаблонные значения:
 * {avto}, {qty}, {percent}, {balance}, {link}
 * 
 * NOTE: В данной версии мобильного приложения надобности в данных настройках - нет
 */
export interface PointSettings {
  welcome: string;
  feedback: Record<string, string>;
  gift: Record<string, string>;
  leveledSms: Record<string, string>;
  bonusSms: Record<string, string>;
}

export type ThisClientQuery = {
  softdelForall?: boolean|number;
  [param: string]: any;
}


export interface Shift {
  id: number;
  name: string;
  timeFrom: string; // datetime
  timeTo: string;   // datetime
  clientPoint: Pick<Point, "id">;
}

export type ShiftsPointQuery = BaseQueryPage & {
  clientPointId?: number;
  [param: string]: any;
};

export type ShiftsPointResponse = BaseCollectionResponse<Shift>;