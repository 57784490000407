import { createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
    'c-modal-step': true,
    [`c-modal-step--${_ctx.lazyValue}`]: true,
  })
  }, [
    _createElementVNode("div", {
      class: "c-modal-step__backgroup",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click-backgroup', $event)))
    }),
    _createElementVNode("div", {
      class: "c-modal-step__modal-container",
      ref: "modalContainer",
      style: _normalizeStyle({
        transform: `translateY(${_ctx.translateYUnit})`,
        transition: _ctx.isMove ? 'none' : null,
      })
    }, [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps({
        setState: _ctx.setState,
        open: _ctx.open,
        close: _ctx.close,
        preview: _ctx.preview,
      })))
    ], 4)
  ], 2))
}