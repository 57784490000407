import { ref, DeepReadonly, computed } from 'vue'
import { PointTypeStyleEnum, ThisClientPointTypeItem } from '@/repositories/Models/Point'
import { useStore } from './useApp'

export function usePointTypes() {
  const store = useStore();

  const pointTypes = ref<DeepReadonly<ThisClientPointTypeItem[]>>([]);
  const storeAllTypes = computed(() => {
    return pointTypes.value
      .filter(pointType => pointType.style === PointTypeStyleEnum.Market);
  });

  async function loadPointTypes() {
    const data = await store.point.getThisClient();
    pointTypes.value = data.pointTypes;
  }

  return {
    pointTypes,
    loadPointTypes,
    storeAllTypes,
  };
}