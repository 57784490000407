import { BaseStore, BaseStoreContext } from './BaseStore'
import { PricePolicyListItem, isRuleTypeCounterparty } from '@/repositories/Models/PricePolicy'
import { UnwrapRef, DeepReadonly, reactive, readonly } from 'vue'
import { keyBy, find } from 'lodash'

export interface ServiceStoreState {
  list: PricePolicyListItem[]|null;
}

export class PricePolicyStore extends BaseStore {
  readonly state: UnwrapRef<ServiceStoreState>;

  constructor(ctx: BaseStoreContext) {
    super(ctx);

    this.state = reactive({
      list: null,
    });
  }

  /**
   * Вернет полный список с 
   * 
   * @returns 
   */
  async getAll(): Promise<DeepReadonly<PricePolicyListItem[]>> {
    if (this.state.list) {
      return readonly(this.state.list);
    }

    const cache = await this.cacheQueryWithBloking(['prices_polices_all'], async () => {
      const { data } = await this.repositories.pricePolicy.getList();
      return data;
    }, 0);

    this.state.list = cache.data;
    return readonly(this.state.list);
  }

  /**
   * Вернет проиндексированные политики по их ID,
   * в условиях которых есть указанный контрагент
   * Если для контрагента политики не назначены, то вернет null
   * 
   * @param groupId ID контрагента
   * @returns 
   */
  async getIndexByGroupId(groupId: string|number) {
    const all = await this.getAll();
    const inGroup = all.filter(pricePolicy => {
      return !!find(pricePolicy.rules, r => {
        return (
          isRuleTypeCounterparty(r)
          && find(r.counterparties, g => g.id == groupId)
        );
      })
    });

    return inGroup.length ? keyBy(inGroup, 'id') : null;
  }
}