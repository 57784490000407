
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import AppHeaderNewVisit from '@/components/layout/AppHeaderNewVisit.vue';
import ClientInfoCard from '@/components/car/ClientInfoCard.vue';
import { Box } from '@/repositories/Models/Box';
import { isTabletDown } from '@/helpers/adaptive';
import { useCreateVisitStep, useNewVisitPhoto } from '@/composables/visit';
import { useBoxes } from '@/composables/useBoxes';
import { useMetrica } from '@/composables/useMetrica';

export default defineComponent({
  components: {
    AppHeaderNewVisit,
    ClientInfoCard,
  },

  setup() {
    const router = useRouter();
    const { boxes, loadBoxes } = useBoxes();
    const { visitState, isPreentry, carId, visitId, visitType } = useCreateVisitStep();
    const { emitEvent } = useMetrica();
    const { visitImageCover } = useNewVisitPhoto({ visitState, onlyMeta: isPreentry });

    function selectBox(box: Box) {
      // Сохраняем информацию во временном состоянии
      visitState.body.box = { id: box.id };

      emitEvent(`visit/${visitType}/box`, box);

      router.push({
        name: isPreentry ? 'preentry-service-type' : 'visit-new-service-type',
        params: { visitId },
      });
    }
    
    return {
      boxes,
      loadBoxes,
      selectBox,
      carId,
      isTabletDown,
      visitImageCover,
    };
  }
});
