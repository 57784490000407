
import { ServiceByContextItem } from '@/repositories/Models/Service'
import { DoerCollectionItem } from '@/repositories/Models/User'
import { get, isEmpty, isEqual, isNumber, keyBy, toNumber, values } from 'lodash'
import { modalController } from '@ionic/vue'
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import CModalViewSelect from '@/components/core/CModalViewSelect.vue'

export default defineComponent({
  props: {
    service: {
      type: Object as PropType<ServiceByContextItem>,
      required: false
    },
    doersCollection: {
      type: Array as PropType<DoerCollectionItem[]>,
      required: true
    },
    doersValue: {
      type: [Array, null] as PropType<Pick<DoerCollectionItem, "id">[]|any>,
      default: () => []
    },
    selectBtnText: {
      type: String,
      default: 'Выбрать для услуги'
    },
    changeBtnText: {
      type: String,
      default: 'Изменить'
    },
    multiselect: {
      type: Boolean,
      default: true,
    }
  },
  setup(props, { emit }) {
    const serviceName = computed(() => props.service ? props.service.name : '- Неизвестная услуга -');
    const doersIndex = computed(() => keyBy(props.doersCollection, 'id'));
    const innerValue = ref(prepareValue(props.doersValue));
    const isSelected = computed(() => !isEmpty(innerValue.value));
    const buttonText = computed(() => isSelected.value ? props.changeBtnText : props.selectBtnText);

    watch(() => props.doersValue, newValue => {
      if (false === isEqual(newValue, innerValue.value)) {
        innerValue.value = prepareValue(newValue);
      }
    });

    const viewDoersSelected = computed(() => {
      return innerValue.value
        .map(doer => doersIndex.value[doer.id]?.name || '<Неизвестный>')
        .join(', ');
    });

    function prepareValue(value: any) {
      return values(value)
        .map(doer => ({id: toNumber(doer?.id)}));
    }

    async function openModalDoers() {
      const multiselect = props.multiselect;
      const modalTitle = multiselect ? 'Выберите исполнителей' : 'Выберите исполнителя';
      const value = multiselect ? innerValue.value.map(doer => doer.id) : get(innerValue.value, '0.id');

      const modal = await modalController.create({
        cssClass: 'core-modal-actions',
        component: CModalViewSelect,
        swipeToClose: true,
        componentProps: {
          options: props.doersCollection,
          propValue: 'id',
          propText: 'name',
          multiselect,
          modalTitle,
          value,
          setValue(doerIds: number[]|number) {
            if (!Array.isArray(doerIds)) {
              doerIds = isNumber(doerIds) ? [ doerIds ] : [];
            }
            
            innerValue.value = doerIds.map(id => ({ id }));
            emit('update:doersValue', innerValue.value);
          }
        }
      });

      await modal.present();
      return modal;
    }

    return {
      serviceName,
      viewDoersSelected,
      openModalDoers,
      buttonText,
    }
  }
});
