
import { Component, defineComponent, provide, inject, shallowRef } from 'vue'
import SelectPointTypeView from './SelectPointTypeView.vue'
import { IonNav } from '@ionic/vue'

export interface ViewInfo {
  component: Component;
  params?: Record<string, any>;
}

export interface ModalNav {
  pushView(component: Component, params?: Record<string, any>): void;
  backView(defaultView?: ViewInfo): void;
}

export function useModalNav(): ModalNav {
  const nav = inject<ModalNav>('modalNav');
  if (!nav) throw new Error('Not set to provide for navigation control in a modal window');
  return nav;
}

export default defineComponent({
  inheritAttrs: false,
  components: {
    IonNav,
  },
  setup() {
    const startedView: ViewInfo = {
      component: SelectPointTypeView,
      params: {},
    };

    const stackViews: ViewInfo[] = [startedView];
    const currentView = shallowRef<ViewInfo>(startedView);

    const nav: ModalNav = {
      pushView(component: Component, params?: Record<string, any>) {
        const newView: ViewInfo =  { component, params };
        currentView.value = newView;
        stackViews.push(newView);
      },

      backView(defaultView?: ViewInfo) {
        if (stackViews.length <= 1) {
          if (defaultView) {
            stackViews.length = 0;
            stackViews.push(defaultView);
            currentView.value = defaultView;
          } else {
            console.log('Нет представления для того, чтобы вернуться назад');
          }
        } else {
          stackViews.pop();
          currentView.value = stackViews[stackViews.length - 1];
        }
      }
    };

    provide('modalNav', nav);

    return {
      currentView,
    };
  }
});
