import { BaseRepository, AsyncResponse } from './BaseRepository';
import {
  ServiceByContextQuery,
  ServiceByContextResponse,
  ServiceCollectionQuery,
  ServiceCollectionResponse,
  ServiceCategoriesCollectionQuery,
  ServiceCategoriesCollectionResponse,
  NomenclatureCollectionQuery,
  NomenclatureCollectionResponse
} from './Models/Service';

export class ServiceRepository extends BaseRepository {
  /**
   * Получает коллекцию с информацией о услугах
   * 
   * @param params 
   * @returns 
   */
  getCollection(params: ServiceCollectionQuery = {}): AsyncResponse<ServiceCollectionResponse> {
    return this.client.get('services', { params });
  }

  /**
   * Получить список услуг в категориях по заданным критериям
   * 
   * @param params 
   * @returns 
   */
  getByContext(params: ServiceByContextQuery): AsyncResponse<ServiceByContextResponse> {
    return this.client.get('services-by-context', { params });
  }

  /**
   * Получить список товаров в категориях по заданным критериям
   * 
   * @param params 
   * @returns 
   */
  getProductsByContext(): AsyncResponse<ServiceByContextResponse> {
    return this.client.get('products-by-context');
  }

  /**
   * Получить список категорий со всеми услугами
   * @param params 
   * @returns 
   */
  getServiceCategories(params: ServiceCategoriesCollectionQuery = {}): AsyncResponse<ServiceCategoriesCollectionResponse> {
    return this.client.get('service-categories', { params });
  }

  /**
   * Получить список номенклатур
   * 
   * @param params 
   * @returns 
   */
  getNomenclature(params: NomenclatureCollectionQuery = {}): AsyncResponse<NomenclatureCollectionResponse> {
    return this.client.get('nomenclature', { params })
  }
}
