import { ref, Ref, ComputedRef, computed } from 'vue';
import { map } from 'lodash';
import { mapSerchWords } from '@/helpers/filterEntities';
import { useStore } from './useApp';
import {
  ServiceCategoriesForForm,
  ServiceForForm,
  ServicesCategoryByContextParams
} from "@/store/ServiceStore";

export interface ExtendServiceItem {
  service: ServiceForForm;
  show: boolean;
}

export interface ServicesCategoriesFiltered extends Omit<ServiceCategoriesForForm, "services"> {
  services: ExtendServiceItem[];
}

export function useServicesCategoriesContext(search: Ref<string|undefined>) {
  const store = useStore();
  const servicesCategories = ref<ServiceCategoriesForForm[]|null>(null);
  
  async function loadServicesCategoriesByContext(ctx: ServicesCategoryByContextParams) {
    servicesCategories.value = await store.service.filterServicesCategoryByContext(ctx);
  }

  const servicesCategoriesFiltered: ComputedRef<ServicesCategoriesFiltered[]> = computed(() => {
    const s = (search.value || '').trim();

    return map(servicesCategories.value || [], serviceCategory => {
      const servicesFiltered = mapSerchWords(serviceCategory.services || [], 'name', s, item => {
        return {
          service: item.item,
          show: s ? (item.weight >= 0) : true,
        } as ExtendServiceItem;
      });

      return {
        ...serviceCategory,
        services: servicesFiltered,
      };
    });
  });
  
  return {
    servicesCategories,
    loadServicesCategoriesByContext,
    servicesCategoriesFiltered,
  };
}