import { AsyncCache, prepareParams } from '@/helpers/cache';
import { BaseStore, BaseStoreContext, OperationInfo } from './BaseStore';
import { SavedVisitRequest } from '@/database/Tables/SavedVisitRequest';
import { cloneDeep, isEmpty, merge, pick } from 'lodash';
import uuid from 'uuid';
import { BaseSuccessResponse } from '@/repositories/Models/Base';
import { UnwrapRef, Ref, ref, readonly } from 'vue';
import { recreateVisitResponseFromBody } from '@/helpers/restore';
import { StoreInstance } from './index';
import { prepareProvidedServicesBodyItems } from '@/helpers/visit';
import { dateToServerDatetimeString } from '@/helpers/datetime';
import moment from 'moment';

import {
  NewVisitSatate,
  createNewVisitState,
  clearNewVisitState
} from './NewVisitState';

import { 
  CarVisitCollectionQuery,
  CarVisitCollectionResponse,
  CarVisitResponse,
  CarVisitCountersResponse,
  CarVisitBodyRequest,
  CarVisitStatusEnum,
  CarVisitCheckupEnum,
  CarVisitCollectionItem
} from '@/repositories/Models/CarVisit';

export interface VisitStoreContext extends BaseStoreContext {
  store: StoreInstance;
}

export interface CarVisitCreateOrUpdateOptions {
  /** Нужно ли создавать заказ в режиме оффлайн (по умолчанию - да) */
  createOffline?: boolean;
}

// ## NOTE
// Запросы которые не могут быть кэшированы или отложено отправлены:
// * getShopVisit - Получить полную информацию о визите в магазин
// * addPhoto - Загрузить одну или несколько фотографий
// * merge - Объединит несколько визитов

// Не реализованно, т.к. пока не понятно где применяется и как именно:
// * getCarVisitProcessing - Получить подробную информацию о визите который находится в процессе

export const ACTION_CREATE = 'create';
export const ACTION_UPDATE = 'update';

let timerCheckVisitCounters: number|null = null;

const SYNC_ATTEMPT_SEND_COUNT = 3; // Количество попыток синхронизации для одного заказа

export class VisitStore extends BaseStore<VisitStoreContext> {
  public newVisitState: UnwrapRef<NewVisitSatate>;
  public newStoreVisitState: UnwrapRef<NewVisitSatate>;
  public preentryVisitState: UnwrapRef<NewVisitSatate>;

  protected countersInfo: Ref<CarVisitCountersResponse>;

  constructor(ctx: VisitStoreContext) {
    super(ctx);

    this.newVisitState = createNewVisitState('new');
    this.newStoreVisitState = createNewVisitState('store');
    this.preentryVisitState = createNewVisitState('preentry');

    this.countersInfo = ref({
      new: '0',
      process: '0',
      timestamp: this.isOnline ? 0 : 1,
      emptyInited: true,
    });

    this.initCheckVisitCounters();
  }

  /**
   * Получения данных о текущем кол-ве визитов в работе и дате последнего измения
   */
  private initCheckVisitCounters() {
    if (timerCheckVisitCounters) { // Чтобы при пересоздании хранилищь обновление пересоздавалось
      clearInterval(timerCheckVisitCounters);
    }

    timerCheckVisitCounters = setInterval(() => {
      if (false === this.isOnline) return;              // В оффлайне не пингуется
      if (false === this.ctx.store.user.isAuth) return; // Если пользователь не аутентифицирован, то тоже

      this.repositories.visit.getCounters().then(res => {
        Object.assign(this.countersInfo.value, res.data);

        if (this.countersInfo.value.emptyInited) {
          delete this.countersInfo.value.emptyInited;
        }
      });
    }, 6000);
  }

  /**
   * Информация о счетчиках кол-ва визитов
   * (обновляется переодически с каким-то промежутком)
   * @returns 
   */
  getCountersRef() {
    return readonly(this.countersInfo);
  }

  //#region New/Store/Preentry states
  public clearNewVisitState() {
    clearNewVisitState(this.newVisitState);
  }

  public clearNewStoreVisitState() {
    clearNewVisitState(this.newStoreVisitState);
  }

  public clearPreentryVisitState() {
    clearNewVisitState(this.preentryVisitState);
  }

  /**
   * Сгенерирует тело запроса на основе текущих данных, для нового визита
   * 
   * @returns 
   */
  public async generateBodyNewVisit(): Promise<CarVisitBodyRequest> {
    let body = cloneDeep(this.newVisitState.body);
    let providedServices = body.providedServices || [];

    // Задаем исполнителей по умолчанию
    const defaultDoers = this.newVisitState.defaultDoers || [];
    if (!isEmpty(defaultDoers)) {
      for (let provideService of providedServices) {
        if (isEmpty(provideService.doers)) {
          provideService.doers = [ ...defaultDoers ];
        }
      }
    }

    // Задаем скидку по умолчанию
    if (this.newVisitState.defaultDiscount) {
      const defaultDiscount = await this.discount.findById(this.newVisitState.defaultDiscount.id);

      if (defaultDiscount) {
        for (let provideService of providedServices) {
          if (!provideService.discountCampaign && !provideService.discountPercent) {
            provideService.discountCampaign = {
              id: defaultDiscount.id,
              type: defaultDiscount.type,
            };
            provideService.discountPercent = defaultDiscount.percent;
          }
        }
      }
    }

    // Перерасчитываем стоимость всех оказываемых услуг,
    // чтобы учесть добавленую скидку по умолчанию
    body.providedServices = providedServices = prepareProvidedServicesBodyItems(providedServices);

    // Новые заказы не должны попадать во вкладку "Новые", а сразу уходить "В работу"
    body.status = CarVisitStatusEnum.Processed;
    body.checkup = CarVisitCheckupEnum.Verified;

    return body;
  }

  /**
   * Сгенерирует тело запроса на основе текущих данных, для нового визита в МАГАЗИН
   * 
   * @returns 
   */
  public async generateBodyNewStoreVisit(): Promise<CarVisitBodyRequest> {
    let body = cloneDeep(this.newStoreVisitState.body);

    const totalOrderDiscount = this.newStoreVisitState.totalOrderDiscount;
    if (totalOrderDiscount) {
      let providedServices = body.providedServices || [];

      // Задаем скидку установленную для всего заказа
      // P.S. это не я придумал, а так устанавливается скидка в старом приложении
      for (let provideService of providedServices) {
        provideService.discountCampaign = {
          id: totalOrderDiscount.id,
          type: totalOrderDiscount.type,
        };
        
        provideService.discountPercent = totalOrderDiscount.percent;
      }

      // Перерасчитываем стоимость всех оказываемых услуг,
      // чтобы учесть добавленую скидку по умолчанию
      body.providedServices = providedServices = prepareProvidedServicesBodyItems(providedServices);
    }

    // При заказе товаров в магазине, статус выполнения не требуется (всегда должен быть Finished)
    body.status = CarVisitStatusEnum.Finished;

    return body;
  }

  /**
   * Сгенерирует тело запроса на основе текущих данных, для предварительной записи
   * 
   * @returns 
   */
  public async generateBodyPreentryVisit(): Promise<CarVisitBodyRequest> {
    let body = cloneDeep(this.preentryVisitState.body);

    if (!body.creationDate) {
      throw new Error('Для предварительной записи дата создания обязательна');
    }

    body.status = CarVisitStatusEnum.New;
    body.checkup = CarVisitCheckupEnum.PendingPreentry;

    return body;
  }
  //#endregion New/Store/Preentry states

  protected get car() {
    return this.ctx.store.car;
  }

  protected get suspect() {
    return this.ctx.store.suspect;
  }

  protected get discount() {
    return this.ctx.store.discount;
  }

  /**
   * Получить список визитов в автосервис
   * 
   * CACHE: При обращении всегда идет запрос к серверу,
   * за исключением когда приложение в оффлайн режиме.
   * 
   * @param params 
   * @returns 
   */
  getCarVisitCollection(params: CarVisitCollectionQuery = {}): AsyncCache<CarVisitCollectionResponse> {
    const key = ['car_visit_collection', ...prepareParams(params)];
    return this.cacheQuery(key, async () => {
      const { data } = await this.repositories.visit.getCarVisitCollection(params);
      return data;
    }, 0);
  }

  /**
   * Визиты в работе. Подгружает визиты из вкладок new и process,
   * а также добавляет заказы созданные в оффлайн режиме
   * 
   * @returns 
   */
  async getWorkVisits(): Promise<CarVisitCollectionItem[]> {
    const newFromDate = moment().add(-15, 'minute').toDate();
    const newToDate = moment().add(1, 'hour').toDate();

    // fix: В оффлайн режиме из-за того, что время постоянно меняется,
    // данные нельзя корректно закэшировать. + Забивается таблица запросов.
    const visitsNewPromise = this.isOnline
      ? this.repositories.visit.getCarVisitCollection({
          limit: 1000,
          pane: 'new',
          fromDate: dateToServerDatetimeString(newFromDate),
          toDate: dateToServerDatetimeString(newToDate),
        })
      : Promise.resolve({ data: { items: [], } })
    ;

    /**
     * Визиты со статусом new (pane: 'new') в данном разделе более не отображаются
     * т.к. был реализован механизм предварительной записи, где как-раз используется статус status='new' и chekup='pending'
     */
    const [ visitsNewRes, visitsProcessRes, visitsNewOffline ] = await Promise.all([
      visitsNewPromise,
      this.getCarVisitCollection({
        limit: 1000,
        pane: 'process',
      }),
      this.getNewOfflineVisits()
    ]);

    if (false === this.isOnline) {
      const visitsCache = [
        ...visitsProcessRes.data.items,
      ];

      // Объединим с данными сохраненных запросов {action: "update"}
      let visitsCacheMergedOfflineData: CarVisitCollectionItem[] = [];
      for (const visit of visitsCache) {
        visitsCacheMergedOfflineData.push({
          ...visit,
          ...await this.getUpdateSavedRequestMergedData(visit.id)
        });
      }

      return [
        ...visitsNewOffline,
        ...visitsNewRes.data.items,
        ...visitsCacheMergedOfflineData,
      ]
    }

    return [
      ...visitsNewOffline,
      ...visitsNewRes.data.items,
      ...visitsProcessRes.data.items,
    ];
  }

  /**
   * Вернет визиты созданные в режиме оффлайн
   * @returns 
   */
  async getNewOfflineVisits() {
    const requests = await this.db.savedVisitRequest.where({ action: ACTION_CREATE }).toArray();
    let offlineVisitsRestore: CarVisitCollectionItem[] = [];

    for (const req of requests) {
      const visit = await recreateVisitResponseFromBody(req.requestBody, this.ctx.store);
      offlineVisitsRestore.push(visit);
    }

    return offlineVisitsRestore;
  }

  /**
   * Получить полную информацию о визите
   * 
   * CACHE: При обращении всегда идет запрос к серверу,
   * за исключением когда приложение в оффлайн режиме.
   * 
   * @param id идентификатор визита
   * @returns 
   */
  async getCarVisit(id: string): AsyncCache<CarVisitResponse> {
    let visit = await this.cacheQuery(
      ['car_visit_single', id],
      async () => {
        const { data } = await this.repositories.visit.getCarVisit(id);
        return data;
      },
      0,
      async () => {
        // Попытка воссоздать информацию о заказе из сохраненного запроса
        const savedRequest = await this.getSavedRequest({ id });

        if (savedRequest) {
          return await recreateVisitResponseFromBody(savedRequest.requestBody, this.ctx.store);
        }

        return undefined;
      }
    );

    if (false === this.isOnline) {
      visit.data = {
        ...visit.data,
        ...await this.getUpdateSavedRequestMergedData(id),
      };
    }

    return visit;
  }

  /**
   * Вернет данные о заказе для слияния, полученные
   * в результате обновления заказа в режиме оффлайн
   * 
   * @param id 
   * @returns 
   */
  private async getUpdateSavedRequestMergedData(id: string): Promise<Partial<CarVisitResponse>> {
    const visitOfflineBody = await this.getSavedRequest({ id }, ACTION_UPDATE);

    if (!visitOfflineBody) return {};

    let mergedVisitData: Partial<CarVisitResponse> = {
      isOffline: true,

      // Те немногочислинные данные которые могут (наверное) меняться
      // в режиме оффлайн из-за действий с заказом
      ...pick(visitOfflineBody.requestBody, [
        'processedDate',
        'finishedDate',
        'status',
        'isPayed',
        'payed',
      ])
    };

    // NOTE: В данной версии приложения по сути кроме как список услуг менять и ничего нельзя
    if (visitOfflineBody.requestBody.providedServices) {
      const restoredVisit = await recreateVisitResponseFromBody(
        { providedServices: visitOfflineBody.requestBody.providedServices },
        this.ctx.store
      );

      mergedVisitData.providedServices = restoredVisit.providedServices;
      mergedVisitData.price = restoredVisit.price;
    }

    return mergedVisitData;
  }

  /**
   * Получить список визитов в магазин
   * 
   * CACHE: При обращении всегда идет запрос к серверу,
   * за исключением когда приложение в оффлайн режиме.
   * 
   * @param params 
   * @returns 
   */
  getShopVisitCollection(params: CarVisitCollectionQuery = {}): AsyncCache<CarVisitCollectionResponse> {
    const key = ['car_shop_visit_collection', ...prepareParams(params)];
    return this.cacheQuery(key, async () => {
      const { data } = await this.repositories.visit.getShopVisitCollection(params);
      return data;
    }, 0);
  }

  /**
   * Получить полную информацию о визите в магазин
   * 
   * @param id идентификатор визита в магазин
   * @returns 
   */
  getShopVisit(id: string): AsyncCache<CarVisitResponse> {
    return this.cacheQuery(['car_shop_visit_single', id], async () => {
      const { data } = await this.repositories.visit.getShopVisit(id);
      return data;
    }, 0);
  }

  /**
   * Краткая информация о количестве текущих визитов
   * 
   * CACHE: При обращении всегда идет запрос к серверу,
   * за исключением когда приложение в оффлайн режиме.
   * 
   * @returns 
   */
  getCounters(): AsyncCache<CarVisitCountersResponse> {
    return this.cacheQuery(['car_visit_counters'], async () => {
      const { data } = await this.repositories.visit.getCounters();
      return data;
    }, 0);
  }

  /**
   * Синхронизировать данные сохраненных запросов с сервером
   */
  async syncSavedRequests() {
    const requests = await this.db.savedVisitRequest.toArray();
    const noSends = requests.filter(sr => sr.attemptToSend < SYNC_ATTEMPT_SEND_COUNT);

    for (let savedRequest of noSends) {
      // await - здесь специально, чтобы не отсылать все запросы на сервер разом
      await this.handleSavedRequest(savedRequest);
    }
  }

  /**
   * Обработка и отправка отложенного запроса
   * 
   * @param request 
   */
  protected async handleSavedRequest(request: SavedVisitRequest) {
    try {
      switch (request.action) {
        case ACTION_CREATE:
          await this.repositories.visit.create(request.requestBody);
          break;
        case ACTION_UPDATE:
          await this.repositories.visit.update(request.visitId, request.requestBody);
          break;
        default:
          throw new Error('Неизвестная операция для визита');
      }

      // Запрос считается успешно отправленным и его необходимо удалить из БД
      this.db.savedVisitRequest.delete(request._id as number);
    } catch (e: any) {
      const changesFail: Partial<SavedVisitRequest> = {
        attemptToSend: request.attemptToSend + 1,
        lastSendDate: new Date(),
        responseError: {
          message: e?.message || 'Неизвестная ошибка'
        }
      };
      this.db.savedVisitRequest.update(request._id as number, changesFail);
    }
  }

  /**
   * Создание нового визита
   * 
   * SAVED REQUEST: В режиме оффлайн данные сохраняются во внутренней структуре БД,
   * и при подключении к интернету, будут отправлены на сервер
   * 
   * @param body 
   * @returns 
   */
  async create(body: CarVisitBodyRequest, options: CarVisitCreateOrUpdateOptions = {}): Promise<OperationInfo<CarVisitResponse, string>> {
    if (this.isOnline) {
      const response = await this.repositories.visit.create(body);
      return {
        entityId: response.data.id,
        sendingDelayed: false,
        response,
      }
    } else if (false === options.createOffline) {
      throw new Error('Заказ можно создать только в онлайн режиме');
    }

    const createVisitRequest = await this.getSavedRequest(body, ACTION_CREATE);
    if (createVisitRequest) { // Если запрос существует, то его данные нужно объединить с новыми
      const { mergedRequest } = this.mergeSavedRequest(createVisitRequest, body);
      await this.db.savedVisitRequest.put(mergedRequest);

      return {
        entityId: mergedRequest.visitId,
        sendingDelayed: true,
        isMerge: true,
      };
    }

    if (!body.id) { // Сгенерируем Id, если не указан.
      body = { ...body, id: uuid.v1() };
    }

    if (!body.creationDate) {
      body.creationDate = moment().format(moment.defaultFormat);
    }

    const carVisitSavedRequest: SavedVisitRequest = {
      visitId: body.id as string,
      action: ACTION_CREATE,
      attemptToSend: 0,
      createdAt: new Date(),
      requestBody: body
    }

    await this.db.savedVisitRequest.put(carVisitSavedRequest);

    return {
      entityId: carVisitSavedRequest.visitId,
      sendingDelayed: true,
      isMerge: false,
    };
  }

  /**
   * Обновление информации о визите
   * 
   * SAVED REQUEST: В режиме оффлайн данные сохраняются во внутренней структуре БД,
   * и при подключении к интернету, будут отправлены на сервер
   * 
   * ВНИМАНИЕ! Для отложенного запроса в случае изменения идентификатора в теле (body)
   * обращаться нужно будет так-же по старому. Дабы не путаться, актуальный entityId
   * всегда возвращается в значении ответа.
   * 
   * @param id идентификатор визита
   * @param body обновляемые данные
   * 
   * @returns 
   */
  async update(id: string, body: CarVisitBodyRequest, options: CarVisitCreateOrUpdateOptions = {}): Promise<OperationInfo<CarVisitResponse, string>> {
    if (this.isOnline) {
      const response = await this.repositories.visit.update(id, body);
      return {
        entityId: response.data.id,
        sendingDelayed: false,
        response,
      };
    } else if (false === options.createOffline) {
      throw new Error('Заказ можно обновить только в онлайн режиме');
    }

    let carVisitExistRequest = await this.getSavedRequest({ id }, ACTION_UPDATE);
    if (!carVisitExistRequest) {
      carVisitExistRequest = await this.getSavedRequest({ id }, ACTION_CREATE);
    }

    if (carVisitExistRequest) { // Если запрос существует, то его данные нужно объединить с новыми
      const { mergedRequest } = this.mergeSavedRequest(carVisitExistRequest, body);
      await this.db.savedVisitRequest.put(mergedRequest);

      return {
        entityId: mergedRequest.visitId,
        sendingDelayed: true,
        isMerge: true,
      };
    }

    if (!body.id) {
      body = { ...body, id };
    }

    const carVisitSavedRequest: SavedVisitRequest = {
      visitId: id, // body.id здесь использовать нельзя, т.к. он может отличаться от текущего идентификатора
      action: ACTION_UPDATE,
      attemptToSend: 0,
      createdAt: new Date(),
      requestBody: body,
    }

    await this.db.savedVisitRequest.put(carVisitSavedRequest);

    return {
      entityId: carVisitSavedRequest.visitId,
      sendingDelayed: true,
      isMerge: false,
    };
  }

  /**
   * Пометить визит, как завершенный
   * 
   * SAVED REQUEST: В офлайн режиме сохраняется как запрос на изменение
   * 
   * @param id идентификатор визита
   * @returns 
   */
  async finish(id: string): Promise<OperationInfo<BaseSuccessResponse, string>> {
    if (this.isOnline) {
      return {
        entityId: id,
        sendingDelayed: false,
        response: await this.repositories.visit.finish(id),
      };
    }

    await this.update(id, {
      finishedDate: moment().format(moment.defaultFormat),
      status: CarVisitStatusEnum.Finished,
    });

    return {
      entityId: id,
      sendingDelayed: true,
    };
  }

  /**
   * Вернуть в работу
   * 
   * SAVED REQUEST: В офлайн режиме сохраняется как запрос на изменение
   * 
   * @param id идентификатор визита
   * @returns 
   */
  async rollback(id: string): Promise<OperationInfo<BaseSuccessResponse, string>> {
    if (this.isOnline) {
      return {
        entityId: id,
        sendingDelayed: false,
        response: await this.repositories.visit.rollback(id),
      };
    }

    let updateData: CarVisitBodyRequest = {
      finishedDate: moment().format(moment.defaultFormat),
      status: CarVisitStatusEnum.Processed,
    };

    try { // В онлайн режиме эта задача лежит на плечах сервера
      const suspectCarReturnedId = await this.suspect.getSuspectId('carReturned');
      if (suspectCarReturnedId) {
        updateData.suspects = [{
          suspiciousConfig: { id: suspectCarReturnedId }
        }];
      }
    } catch (e) {
      // TODO: Наверное надо отображать пользователю WARNING
      console.warn('Не удалось устаносить подозрительное действие, при возврате визита в работу');
    }

    await this.update(id, updateData);

    return {
      entityId: id,
      sendingDelayed: true,
    };
  }

  /**
   * Отклонить визит
   * 
   * SAVED REQUEST: В офлайн режиме сохраняется как запрос на изменение
   * 
   * @param id идентификатор визита
   * @returns 
   */
  async rejected(id: string): Promise<OperationInfo<CarVisitResponse, string>> {
    if (this.isOnline) {
      const response = await this.repositories.visit.rejected(id);
      return {
        entityId: response.data.id,
        sendingDelayed: false,
        response,
      };
    }

    return await this.update(id, {
      checkup: CarVisitCheckupEnum.Rejected
    });
  }

  /**
   * Удалить визит
   * 
   * ВНИМАНИЕ! В режиме оффлайн удаление для существующих на сервере записей не работает
   * (только для созданных и сохраненных локально)
   */
  async removeHard(id: string) {
    if (this.isOnline) {
      await this.repositories.visit.removeHard(id);
    }

    const savedRequest = await this.getSavedRequest({ id }, ACTION_CREATE);
    if (savedRequest) {
      await this.db.savedVisitRequest.delete(savedRequest._id as number);
    }

    // NOTE: C ACTION_UPDATE уже не прокатит =(

    throw new Error('В режиме ОФФЛАЙН невозможно осуществить операцию удаления визита');
  }

  /**
   * Выставить счет
   * 
   * ВНИМАНИЕ! не работает в оффлайн режиме
   * 
   * @param id идентификатор визита
   */
  async invoice(id: string) {
    if (this.isOnline) {
      return await this.repositories.visit.invoice(id);
    }

    throw new Error('Выставление счета не работает в оффлайн режиме');
  }

  /**
   * Выставить фискальный счет
   * 
   * @param id идентификатор визита
   */
  async invoiceFiscal(id: string) {
    if (this.isOnline) {
      return await this.repositories.visit.invoiceFiscal(id);
    }

    throw new Error('Выставление фискального счета не работает в оффлайн режиме');
  }

  /**
   * Ищет отложенный запрос
   */
  protected async getSavedRequest(keys: Pick<CarVisitBodyRequest, "id">, action?: string): Promise<SavedVisitRequest|undefined> {
    if (!keys.id) return undefined;

    let criterias: Record<string, any> = { visitId: keys.id };
    if (action) criterias.action = action;

    return await this.db.savedVisitRequest.get(criterias);
  }

  /**
   * Объединяет старый запрос с новыми данными
   * 
   * @param request 
   * @param newBody 
   * @returns 
   */
  protected mergeSavedRequest(
    request: SavedVisitRequest,
    newBody: CarVisitBodyRequest
  ): {
    mergedRequest: SavedVisitRequest;
    oldVisitId: string;
  } {
    const oldVisitId = request.visitId;

    // TODO: А точно ли сдесь нужно делать глубокое рекурсивное объединение?
    const mergedBody = merge(request.requestBody, newBody);
    const mergedRequest: SavedVisitRequest = {
      ...request,
      requestBody: mergedBody,
      visitId: mergedBody.id || uuid.v1(),
      attemptToSend: 0, // Для повторных попыток отправки
      // 'action' и остальные параметры обновлять не нужно!
    };

    return { mergedRequest, oldVisitId };
  }
}
