import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "service-view-item__addition-info",
  slot: "end"
}
const _hoisted_2 = {
  key: 0,
  class: "service-view-item__doers"
}
const _hoisted_3 = { class: "service-view-item__price-info" }
const _hoisted_4 = {
  key: 0,
  class: "text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_cc = _resolveComponent("cc")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, { class: "service-view-item ion-align-items-start" }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.provideService.service?.name), 1),
      _createElementVNode("div", _hoisted_1, [
        (_ctx.doersChips.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.doersChips, (name, i) => {
                return (_openBlock(), _createBlock(_component_ion_chip, {
                  key: i,
                  color: "secondary",
                  class: "service-view-item__doer-chip chip-filled"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(name), 1)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.discountViewBadges, (badge) => {
            return (_openBlock(), _createBlock(_component_ion_chip, {
              key: badge,
              color: "success",
              class: "chip-size-small chip-filled"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(badge), 1)
              ]),
              _: 2
            }, 1024))
          }), 128)),
          (_ctx.provideService.qty > 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_cc, {
                  price: _ctx.provideService.singleItemPrice
                }, {
                  default: _withCtx(({ priceFormatted }) => [
                    _createTextVNode(_toDisplayString(priceFormatted) + " X" + _toDisplayString(_ctx.provideService.qty), 1)
                  ]),
                  _: 1
                }, 8, ["price"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            _createVNode(_component_cc, {
              price: _ctx.provideService.totalPrice
            }, null, 8, ["price"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}