
import { computed, defineComponent, nextTick, ref, toRef, Ref, watch } from 'vue';
import AppHeaderNewVisit from '@/components/layout/AppHeaderNewVisit.vue';
import { useStore } from '@/composables/useApp';
import { ServiceByContextQuery } from '@/repositories/Models/Service';
import { ProvideServiceBodyItem } from '@/repositories/Models/CarVisit';
import { cloneDeep, groupBy, pullAll, remove, debounce, pick } from 'lodash';
import { alertController, modalController } from '@ionic/vue';
import { PointTypeCategoryItem } from '@/repositories/Models/Point';
import CarSideInfo from '@/components/car/CarSideInfo.vue';
import { useCreateVisitStep, useNewVisitPhoto } from '@/composables/visit';
import { isTabletAndUp } from '@/helpers/adaptive';
import { scrollHide } from '@/directives/scrollHide';
import { useRouter } from 'vue-router';
import toast from '@/ext/toast';
import ServicesAddCategoryGroupModal from '@/components/service/add-category-modal/ServicesAddCategoryGroupModal.vue';
import ServicesProvideVisitState from '@/components/service/provide-form/ServicesProvideVisitState.vue';
import { ProvideServicesBodyItemsGroup, generateKeyProvideService } from '@/helpers/visit';
import { useI18n } from 'vue-i18n';

// fix: error  'HTMLIonModalElement' is not defined  no-undef
import type { Components } from '@ionic/core/dist/types/components'
import { useMetrica } from '@/composables/useMetrica';
type IonModalComponent = Components.IonModal;

export default defineComponent({
  components: {
    ServicesProvideVisitState,
    AppHeaderNewVisit,
    CarSideInfo,
  },
  directives: { scrollHide },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { emitEvent } = useMetrica();
    const { t } = useI18n();
    const { visitState, carId, visitId, isPreentry, visitType, prefixNamePage } = useCreateVisitStep({
      stateProvideKey: 'visitState'
    });
    const { visitImageCover } = useNewVisitPhoto({ visitState, onlyMeta: isPreentry });

    // Предварительная прогрузка информации о точках обслуживания и категориях.
    // Необходимо, для корректной работы заголовков у групп (иначе не будут работать
    // вычисляемые значения, полученные методом getCategoryBreadcrumbsComputed).
    store.point.getThisClient(); // async

    //#region Search & Filter
    const searchWords = ref('');
    const mobileSearchbarContainer = ref<HTMLDivElement|null>(null);
    const emitEventSearchDebounce = debounce(() => {
      if (searchWords.value) {
        emitEvent(`visit/${visitType}/services/search`, {
          text: searchWords.value
        });
      }
    }, 1400);
    watch(searchWords, emitEventSearchDebounce);
    //#endregion

    const categoryServicesGroups = computed<ServiceByContextQuery[]>(() => {
      const groupPartnerId = visitState.body.group?.id;

      return visitState.selectedTypeAndCategories.map(servicesGroup => {
        return {
          typeId: servicesGroup.typeId,
          categoryId: servicesGroup.catId,
          groupId: groupPartnerId || undefined,
          discountAccountId: carId.value,
        };
      });
    });

    //#region Add category
    async function openAddCategory() {
      const selectedTypeAndCategoriesCRef = computed(() => cloneDeep(visitState.selectedTypeAndCategories));

      const modal = await modalController.create({
        cssClass: 'core-modal-actions',
        component: ServicesAddCategoryGroupModal,
        swipeToClose: true,
        componentProps: {
          selectedTypeAndCategoriesCRef,
          setCategory(cat: PointTypeCategoryItem, typeId: number) {
            toggleCategoryGroup(cat, typeId, modal);
          }
        },
      });

      await modal.present();
      return modal;
    }

    const servicesProvideComponent = ref<InstanceType<typeof ServicesProvideVisitState>|null>(null);
    const groupsContainer = ref<HTMLElement|null>(null);

    /** Добавляет/Сменяет или удаляет категорию */
    async function toggleCategoryGroup(cat: PointTypeCategoryItem, typeId: number, modal?: IonModalComponent) {
      if (!servicesProvideComponent.value) {
        return toast.error('Component error, contact your application administrator!');
      }

      const catId = cat.id;
      const selectedTypeAndCategories = visitState.selectedTypeAndCategories;
      const providedServicesGroupValue = toRef(servicesProvideComponent.value, 'providedServicesGroupValue');

      const availableInSelected = !!selectedTypeAndCategories.find(item => {
        return item.typeId == typeId && item.catId == cat.id;
      });

      if (availableInSelected) {
        openAlertConfirmRemoveCategory(providedServicesGroupValue, cat, typeId, modal);
      }
      // FIXME: В будущем нужно переключиться на другой способ индексации оказываемых услуг,
      // и тогда не потребуется сложный алгоритм, для смены категории с сохранением
      // выбранных услуг в случае отсутствия мультивыбора
      else {
        const pointTypeInfo = await store.point.filterTypePoint(typeId);
        const categoryMultichoice = !!pointTypeInfo?.categoryMultichoice;
        const groupByTypes = groupBy(selectedTypeAndCategories, 'typeId');

        // В типе категории нет мультивыбора, => категорию нужно сменить,
        // вместе с выбранными данными
        if (groupByTypes[`${typeId}`] && false === categoryMultichoice) {
          emitEvent(`visit/${visitType}/services/category/replace`, pick(cat, ['id', 'name']));

          let savedProvidedServices: ProvideServiceBodyItem[]|null = null;

          // Удалим всю информацию об оказываемых услугах,
          // предварительно сохранив ее, для последующей замены
          groupByTypes[`${typeId}`].forEach(item => {
            const valueKey = generateKeyProvideService(item.typeId, item.catId);
            savedProvidedServices = cloneDeep(providedServicesGroupValue.value[valueKey]) || null;
            delete providedServicesGroupValue.value[valueKey];
          });

          pullAll(selectedTypeAndCategories, groupByTypes[`${typeId}`]);
          selectedTypeAndCategories.push({ typeId, catId });

          if (savedProvidedServices) {
            const replaceValueKey = generateKeyProvideService(typeId, catId);
            providedServicesGroupValue.value[replaceValueKey] = savedProvidedServices;
          }
        } else {
          emitEvent(`visit/${visitType}/services/category/add`, pick(cat, ['id', 'name']));
          selectedTypeAndCategories.push({ typeId, catId });
        }

        nextTick(() => {
          setTimeout(() => { // Задержка, чтобы дать время прогрузиться пунктам внутри
            (groupsContainer.value
              ?.querySelector(`[data-cat-id="${catId}"]`)
              ?.scrollIntoView({
                block: "start",
                inline: "nearest",
                behavior: "smooth",
              })
            );
          }, 500);
        });

        modal?.dismiss();
      }
    }

    async function openAlertConfirmRemoveCategory(
      providedServicesGroupValue: Ref<ProvideServicesBodyItemsGroup>,
      cat: PointTypeCategoryItem,
      typeId: number,
      modal?: IonModalComponent
    ) {      
      const alert = await alertController.create({
        header: t('views.visit_new_services.alert_remove_cateory_header'),
        message: t('views.visit_new_services.alert_remove_cateory_message'),
        buttons: [
          {
            text: 'Отмена',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: t('views.visit_new_services.alert_remove_cateory_btn_confirm'),
            handler() {
              emitEvent(`visit/${visitType}/services/category/remove`, pick(cat, ['id', 'name']));

              // Удалим все услуги из группы
              const valueKey = generateKeyProvideService(typeId, cat.id);
              delete providedServicesGroupValue.value[valueKey];

              // Удалим группу из отображения
              remove(
                visitState.selectedTypeAndCategories,
                group => group.typeId == typeId && group.catId == cat.id
              );

              alert.dismiss();
              modal?.dismiss();
            }
          }
        ]
      });

      await alert.present();
      return alert;
    }
    //#endregion

    function confirmAndNext() {
      if (!visitState.body.providedServices?.length) {
        return toast.error(t('views.visit_new_services.error_empty_services'));
      }

      router.push({
        name: isPreentry ? 'preentry-datetime' : prefixNamePage('doers'),
        params: { visitId },
      });
    }

    return {
      searchWords,
      mobileSearchbarContainer,
      carId,
      servicesProvideComponent,
      categoryServicesGroups,
      openAddCategory,
      confirmAndNext,
      generateKeyProvideService,
      visitImageCover,
      isTabletAndUp,
      groupsContainer,
    };
  }
});
