
import { computed, defineComponent, PropType } from 'vue';
import { CarVisitProvideService } from '@/repositories/Models/CarVisit';
import { groupBy, map } from 'lodash';
import { useStore } from '@/composables/useApp';
import ServicesProvidedViewGroup, { ViewGroup } from './ServicesProvidedViewGroup.vue';

export default defineComponent({
  components: {
    ServicesProvidedViewGroup,
  },
  
  props: {
    providedServices: {
      type: Array as PropType<CarVisitProvideService[]>,
      required: true,
    },
    total: {
      type: [String, Number] as PropType<string|number>,
      required: true,
    },
    shortGroupName: {
      type: Boolean,
      default: false,
    }
  },

  setup(props) {
    const store = useStore();

    // Прогрузим список категорий (необходимо для корректной работы getCategoryBreadcrumbsComputed)
    store.point.getThisClient(); // async

    const groups = computed<ViewGroup[]>(() => {
      const groupCategories = groupBy(
        props.providedServices,
        item => item.carCategory?.id
      );

      return map(groupCategories, (value, key) => {
        return {
          catId: +key,
          providedServices: value,
        }
      });
    });

    return {
      groups,
    };
  }
});
