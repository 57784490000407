
/**
 * NOTE: Несмотря на то, что комопнент дублирует часть кода из ServiceProvideDoersItem.vue
 * объединять компоненты не стоит, т.к. в будущем возможно появятся дополнительные настройки скидок.
 */

import { defineComponent, PropType, computed, ref, watch } from 'vue';
import { ServiceByContextItem } from '@/repositories/Models/Service';
import { Discount, DiscountTypeEnum } from '@/repositories/Models/Discount';
import { modalController } from '@ionic/vue';
import { keyBy, get } from 'lodash';
import CModalViewSelect from '@/components/core/CModalViewSelect.vue';
import { ProvideServiceMeta } from '@/store/ServiceStore';
import DiscountInnerSelectOption from '@/components/discount/DiscountInnerSelectOption.vue';
import { useI18n } from 'vue-i18n';
import toast from '@/ext/toast';

export default defineComponent({
  props: {
    service: {
      type: Object as PropType<ServiceByContextItem>,
      required: false
    },
    provideMeta: {
      type: Object as PropType<ProvideServiceMeta>,
      required: false
    },
    discountsCollection: {
      type: Array as PropType<Discount[]>,
      required: true
    },
    discountValue: {
      type: Object as PropType<Pick<Discount, "id"|"type">>,
      required: false
    },
    discountPercent: {
      type: null,
      required: false
    },
    selectBtnText: {
      type: String,
      default: 'Выбрать для услуги'
    },
    changeBtnText: {
      type: String,
      default: 'Изменить'
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const serviceName = computed(() => props.service ? props.service.name : '- Неизвестная услуга -');

    // Полный список скидок с учетом статических скидок на конкретные услуги
    // FIXME: Переписать логику с нужными типами Partial<Discount, "id"|"type">
    const discountsAllForService = computed<Discount[]>(() => {
      let discounts = [...props.discountsCollection];

      const staticDiscount: Discount|null = get(props.provideMeta, 'staticDiscount', null);
      if (staticDiscount) {
        discounts.unshift(staticDiscount);
      }

      const leveledDiscount = get(props.provideMeta, 'leveledDiscount', null) as Discount|null;
      if (leveledDiscount) {
        discounts.unshift(leveledDiscount);
      }

      let giftDiscount = get(props.provideMeta, 'giftDiscount', null) as Discount|null;
      if (giftDiscount) {
        giftDiscount.percent = 100; // fix: но в теории нигде виден не будет
        discounts.unshift(giftDiscount);
      }

      return discounts;
    });
    const discountIndex = computed(() => keyBy(discountsAllForService.value, 'id'));

    const innerValue = ref(prepareValue(props.discountValue));
    const isSelected = computed(() => !!innerValue.value);
    const buttonText = computed(() => isSelected.value ? props.changeBtnText : props.selectBtnText);

    watch(() => props.discountValue, newValue => {
      if (newValue?.id != innerValue.value) {
        innerValue.value = prepareValue(newValue);
      }
    });

    const viewDiscountSelected = computed(() => {
      return innerValue.value
        ? (discountIndex.value[innerValue.value]?.name || '<Неизвестная скидка>')
        : String();
    });

    function prepareValue(value: any) {
      return get(value, 'id', null) as number|null;
    }

    // FIXME: Переписать с использованием useDiscounts
    async function openModalDiscount() {
      // FIXME: Static & Leveled в теории тоже менять не стоит
      if (get(props.discountValue, 'type') === DiscountTypeEnum.Gift) {
        toast.error('Нельзя изменить скидку, для услуги в подарок', 3500, {
          // header: 'Неверное действие'
        });
        
        return;
      }

      const modal = await modalController.create({
        cssClass: 'core-modal-actions',
        component: CModalViewSelect,
        swipeToClose: true,
        componentProps: {
          options: discountsAllForService.value,
          propValue: 'id',
          propText: 'name',
          multiselect: false,
          value: innerValue.value,
          modalTitle: t('components.service_provide_discount_item.modal_title'),
          innerItemComponent: DiscountInnerSelectOption,
          setValue(discountId: number|null, discount?: Discount) {
            innerValue.value = discountId;

            const type = discount?.type || DiscountTypeEnum.ManualDiscount;
            const emitValue = discountId ? { id: discountId, type } : null;
            const emitPercent = discountId ? (discount?.percent || 0) : null;

            emit('update:discountValue', emitValue, discount);
            emit('update:discountPercent', emitPercent);
          },
        }
      });

      await modal.present();
      return modal;
    }

    return {
      openModalDiscount,
      serviceName,
      viewDiscountSelected,
      buttonText,
    };
  },
});
