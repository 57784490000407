import { Dictionary, invert, toUpper, toLower } from 'lodash';
import { CarNumberTypeEnum } from '@/repositories/Models/Car';
import { CarNumberTranslitDictionary } from '@/repositories/Models/Config';

export const CAR_NUMBER_REGEXP_CITIZEN = /^[abekmhopctyx]{1}[\d]{3}[abekmhopctyx]{2}[\d]{2,3}$/;
export const CAR_NUMBER_REGEXP_MILITARY = /^[\d]{4}[abekmhopctyx]{2}[\d]{2,3}$/;
export const CAR_NUMBER_REGEXP_POLICE = /^[abekmhopctyx]{1}[\d]{4}[\d]{2,3}$/;
export const CAR_NUMBER_REGEXP_TAXI = /^[abekmhopctyx]{2}[\d]{3}[\d]{2,3}$/;
export const CAR_NUMBER_REGEXP_DIPLOMATICAL_CD = /^[\d]{3}cd[\d][\d]{2,3}$/;
export const CAR_NUMBER_REGEXP_DIPLOMATICAL_D = /^[\d]{3}d[\d]{3}[\d]{2,3}$/;
export const CAR_NUMBER_REGEXP_DIPLOMATICAL_T = /^[\d]{3}t[\d]{3}[\d]{2,3}$/;

export interface CarNumberTypeRecognition {
  regexp: RegExp;
  type: CarNumberTypeEnum;
}

export interface CarNumberPreparedAndTypeRecognitionOptions {
  translitChars?: CarNumberTranslitDictionary;
  typesRecognition?: CarNumberTypeRecognition[];
}

/** Регулярные выражения для опредеелния типа номера */
export const CAR_NUMBER_REGEXP_MAP: CarNumberTypeRecognition[] = [
  {
    regexp: CAR_NUMBER_REGEXP_CITIZEN,
    type: CarNumberTypeEnum.Citizen,
  },
  {
    regexp: CAR_NUMBER_REGEXP_MILITARY,
    type: CarNumberTypeEnum.Military,
  },
  {
    regexp: CAR_NUMBER_REGEXP_POLICE,
    type: CarNumberTypeEnum.Police,
  },
  {
    regexp: CAR_NUMBER_REGEXP_TAXI,
    type: CarNumberTypeEnum.Taxi,
  },
  {
    regexp: CAR_NUMBER_REGEXP_DIPLOMATICAL_CD,
    type: CarNumberTypeEnum.Diplomatical,
  },
  {
    regexp: CAR_NUMBER_REGEXP_DIPLOMATICAL_D,
    type: CarNumberTypeEnum.Diplomatical,
  },
  {
    regexp: CAR_NUMBER_REGEXP_DIPLOMATICAL_T,
    type: CarNumberTypeEnum.Diplomatical,
  },
];

/** Описани типа номера */
export const CAR_NUMBER_TYPE_TITLES: Record<CarNumberTypeEnum, string> = {
  'citizen': 'Гражданские',
  'military': 'Военные',
  'police': 'Полицейские',
  'taxi': 'Такси',
  'diplomatical': 'Дипломатические',
  'other': 'Другой формат',
  'unknown': 'Не определен',
};

/**
 * Специальный словарь для транслитерации номеров автомобией (ru -> en)
 */
export const CAR_NUMBER_TRANSLIT_CHARS: Dictionary<string> = {
  // '<ru>': '<en>'
  'а': 'a',
  'в': 'b',
  'е': 'e',
  'к': 'k',
  'м': 'm',
  'н': 'h',
  'о': 'o',
  'р': 'p',
  'с': 'c',
  'т': 't',
  'у': 'y',
  'х': 'x',
};

/**
 * Специальный словарь для транслитерации номеров автомобией (en -> ru)
 */
export const CAR_NUMBER_TRANSLIT_INVERT = invert(CAR_NUMBER_TRANSLIT_CHARS);

/**
 * Обработка номера автомобиля
 * 
 * Алгоритм:
 *  1. в нижний регистр ->
 *  2. транслитерация русских символов в английские ->
 *  3. удаление символов, которые не могут присутствовать в номере.
 */
export function prepareCarNumber(carNumber: string, translitChars?: CarNumberTranslitDictionary) {
  let preparedNumber = toLower(carNumber);

  translitChars = translitChars || CAR_NUMBER_TRANSLIT_CHARS;

  // Транслитерация русских букв в английские
  let translitNumber = '';
  for (let i = 0; i < preparedNumber.length; ++i) {
    translitNumber += translitChars[preparedNumber[i]] !== undefined
      ? translitChars[preparedNumber[i]]
      : preparedNumber[i];
  }

  // Удадение лишних символов
  preparedNumber = translitNumber.replace(/[^a-z\d]/g, '');
  
  return preparedNumber;
}

/**
 * Определяет тип номера автомобиля
 * NOTE: Предварительно обработает номер
 * 
 * @param carNumber номер автомобиля
 * @returns 
 */
export function getCarNumberType(carNumber: string, options: CarNumberPreparedAndTypeRecognitionOptions = {}): CarNumberTypeEnum {
  const { numberType } = getCarNumberPreparedAndType(carNumber, options);
  return numberType;
}

/**
 * Вернет обработанный номер и тип номера
 * 
 * @param carNumber номер автомобиля
 * @returns 
 */
export function getCarNumberPreparedAndType(carNumber: string, options: CarNumberPreparedAndTypeRecognitionOptions = {}) {
  let number = prepareCarNumber(carNumber, options.translitChars);
  let numberType = CarNumberTypeEnum.Other;

  const typesRecognition = options.typesRecognition || CAR_NUMBER_REGEXP_MAP;

  for (let typeInfo of typesRecognition) {
    if (typeInfo.regexp.test(number)) {
      numberType = typeInfo.type;
      break;
    }
  }

  // fix: Для совместимости со старым приложением
  // номера должны передаваться в верхнем регистре
  number = toUpper(number);

  return {
    number,
    numberType
  };
}

/**
 * Возаращает наименование типа номера автомобиля
 * 
 * @deprecated
 * @param type тип номера автомобиля
 * @returns 
 */
export function getCarNumberTypeTitle(type: CarNumberTypeEnum): string {
  return CAR_NUMBER_TYPE_TITLES[type] || 'Не определен';
}

/**
 * Заменяет в номере латинкские символы на кирилические
 * 
 * @deprecated
 * 
 * @param carNumber 
 * @returns 
 */
export function carNumberTranslitEnToRu(carNumber: any): string {
  if (typeof carNumber !== 'string') return '';

  carNumber = carNumber.toLowerCase();

  let translitNumber = '';
  for (let i = 0; i < carNumber.length; ++i) {
    translitNumber += CAR_NUMBER_TRANSLIT_INVERT[carNumber[i]] !== undefined
      ? CAR_NUMBER_TRANSLIT_INVERT[carNumber[i]]
      : carNumber[i];
  }

  return translitNumber;
}

/**
 * Транслитерация номера из латиницы
 * 
 * @param carNumber 
 * @param translitCharsInvert словарь для транслитерации
 * @returns 
 */
export function carNumberTranslitLatinToLang(carNumber: any, translitCharsInvert: CarNumberTranslitDictionary): string {
  if (typeof carNumber !== 'string') return '';

  carNumber = toLower(carNumber);

  let translitNumber = '';
  for (let i = 0; i < carNumber.length; ++i) {
    translitNumber += translitCharsInvert[carNumber[i]] !== undefined
      ? translitCharsInvert[carNumber[i]]
      : carNumber[i];
  }

  return translitNumber;
}