
import { defineComponent, PropType, provide } from 'vue';
import { useModalNav } from './ServicesAddCategoryGroupModal.vue';
import { ModalServicesContext } from '@/views/visits/VisitSingle.vue';
import SelectDiscountsView from './SelectDiscountsView.vue';
import ServicesProvideChoiceDoers from '../provide-form/ServicesProvideChoiceDoers.vue';

export default defineComponent({
  inheritAttrs: false,

  components: {
    ServicesProvideChoiceDoers,
  },

  props: {
    // HACK: Не передаем значения напрямую,
    // т.к. это вызовет проблемы с обновлением props значений
    useModalServicesContext: {
      type: Function as PropType<() => ModalServicesContext>,
      required: true,
    },
  },

  setup(props) {
    const nav = useModalNav();
    const ctx = props.useModalServicesContext();

    provide('visitState', ctx.visitState);

    return {
      back: () => nav.backView(),
      next: () => nav.pushView(SelectDiscountsView),
    };
  }
});
