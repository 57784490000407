import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_service_provide_doers_item = _resolveComponent("service-provide-doers-item")!
  const _component_c_content_loading = _resolveComponent("c-content-loading")!

  return (_openBlock(), _createBlock(_component_c_content_loading, { action: _ctx.load }, {
    default: _withCtx(() => [
      _createVNode(_component_service_provide_doers_item, {
        "select-btn-text": "Выбрать для всего заказа",
        "doers-collection": _ctx.doers,
        multiselect: true,
        "doers-value": _ctx.visitState.defaultDoers,
        "onUpdate:doersValue": _ctx.setDefaultDoers
      }, null, 8, ["doers-collection", "doers-value", "onUpdate:doersValue"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.collectionServicesInfo, (item) => {
        return (_openBlock(), _createBlock(_component_service_provide_doers_item, {
          key: item.id,
          service: item.service,
          "doers-collection": _ctx.doers,
          "doers-value": item.provide.doers,
          "onUpdate:doersValue": value => _ctx.setItemDoers(item, value)
        }, null, 8, ["service", "doers-collection", "doers-value", "onUpdate:doersValue"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["action"]))
}