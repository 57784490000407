import { BaseStore, BaseStoreContext } from './BaseStore';
import { Discount } from '@/repositories/Models/Discount';
import { readonly, UnwrapRef, DeepReadonly } from 'vue';
import { StaticDiscountCampaign } from '@/repositories/Models/Discount';

export interface DiscountStoreState {
  items: Discount[]|null;
  staticCampain: StaticDiscountCampaign[]|null;
}

export class DiscountStore extends BaseStore {
  protected state: UnwrapRef<DiscountStoreState>;

  constructor(ctx: BaseStoreContext) {
    super(ctx);

    this.state = ({
      items: null,
      staticCampain: null,
    });
  }

  /**
   * Получить список доступных скидок
   * 
   * CACHE: Данные автоматически кэшируются, и запрашиваются по
   * требованию только первый раз после запуска приложения
   * 
   * @returns 
   */
  async getAll(): Promise<DeepReadonly<Discount[]>> {
    if (this.state.items) {
      return readonly(this.state.items);
    }

    const cacheRelevantMS = 0;
    const key = ['discount_collection', 1000];
    const cache = await this.cacheQuery(key, async () => {
      const { data } = await this.repositories.discount.getCollection({ limit: 1000 });
      return data.items;
    }, cacheRelevantMS);

    this.state.items = cache.data;
    return readonly(this.state.items);
  }

  /**
   * Загрузить список и вернуть скидку по ее ID
   * 
   * @param id идентификатор скидки
   * @returns 
   */
  async findById(id: number): Promise<DeepReadonly<Discount>|null> {
    const list = await this.getAll();
    const index = list.findIndex(item => item.id == id);
    return index !== -1 ? list[index] : null;
  }

  /**
   * Получить список статических дисконтных кампаний
   * 
   * CACHE: Данные автоматически кэшируются, и запрашиваются по
   * требованию только первый раз после запуска приложения
   * 
   * @returns 
   */
  async getStaticAllCampain(): Promise<StaticDiscountCampaign[]> {
    if (!this.state.staticCampain) {
      const cache = await this.cacheQuery(['static_discount_all'], async () => {
        const { data } = await this.repositories.discount.getStaticDiscountCampaigns({ limit: 1000 });
        return data.items;
      }, 0);
      
      this.state.staticCampain = cache.data;
      // TODO: reindex
    }

    return this.cloneData(this.state.staticCampain);
  }
}
