
import { defineComponent, DeepReadonly } from 'vue';
import { useRouter } from 'vue-router';
import AppHeaderNewVisit from '@/components/layout/AppHeaderNewVisit.vue';
import { ThisClientPointTypeItem } from '@/repositories/Models/Point';
import ClientInfoCard from '@/components/car/ClientInfoCard.vue';
import { isTabletDown } from '@/helpers/adaptive';
import { usePointTypes } from '@/composables/usePointTypes';
import { useCreateVisitStep, useNewVisitPhoto } from '@/composables/visit';
import { useMetrica } from '@/composables/useMetrica';
import { pick } from 'lodash';

export default defineComponent({
  components: {
    AppHeaderNewVisit,
    ClientInfoCard,
  },

  setup() {
    const router = useRouter();
    const { pointTypes, loadPointTypes } = usePointTypes();
    const { isPreentry, visitId, carId, visitType, visitState, prefixNamePage } = useCreateVisitStep();
    const { emitEvent } = useMetrica();
    const { visitImageCover } = useNewVisitPhoto({ visitState, onlyMeta: isPreentry });

    function selectPointType(type: DeepReadonly<ThisClientPointTypeItem>) {
      emitEvent(`visit/${visitType}/category-type`, pick(type, ['id', 'name', 'style']));

      router.push({
        name: prefixNamePage('service-cat'),
        params: {
          typeId: type.id,
          visitId
        },
        query: {
          title: type.name,
        }
      });
    }

    const lastTypeCategoryId = visitState.meta.lastTypeCategoryId;

    return {
      loadPointTypes,
      pointTypes,
      selectPointType,
      carId,
      visitImageCover,
      isTabletDown,
      lastTypeCategoryId,
    };
  }
});
