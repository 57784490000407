import { CarVisitExt, CarVisitProvideService, ProvideServiceBodyItem } from '@/repositories/Models/CarVisit'
import { PaymentTypeEnum } from '@/repositories/Models/Pos'
import { pick, get, flatten, groupBy, Dictionary, cloneDeep, remove } from 'lodash'
import { CarVisitCollectionItem } from '@/repositories/Models/CarVisit'
import moment from 'moment'

//#region Payment Type
export const PAYMENT_TYPES_ASSOC: Record<PaymentTypeEnum, string> = {
  [PaymentTypeEnum.Card]: 'Карта',
  [PaymentTypeEnum.Cash]: 'Наличные',
  [PaymentTypeEnum.Contractor]: 'Контрагент',
};

export const PAYMENT_TYPES_LIST = Object.keys(PAYMENT_TYPES_ASSOC) as PaymentTypeEnum[];

export interface PaymentTypeOption {
  value: PaymentTypeEnum;
  text: string;
}

/**
 * Вернет массив опций для списка выбора (select) способов оплаты
 * 
 * @param hasGroup есть группа (доступна оплата как контрагент) [default: true]
 * @param hasPoints имеются бонусы для оплаты [default: false]
 * @returns 
 */
export function getPaymentTypesOptions(hasGroup = true) {
  let options: PaymentTypeOption[] = PAYMENT_TYPES_LIST.map(type => ({
    value: type,
    text: getPaymentTypeTitle(type)
  }));

  if (!hasGroup) { // Исключим контрегента, если он не выбран
    remove(options, option => option.value === PaymentTypeEnum.Contractor);
  }

  return options;
}

/**
 * Вернет текстовое представления для способа оплаты
 * 
 * @param type значение способа оплаты
 * @returns 
 */
export function getPaymentTypeTitle(type: PaymentTypeEnum, customTitles?: Record<PaymentTypeEnum|string, string>) {
  if (customTitles && customTitles[type]) {
    return customTitles[type];
  }

  return PAYMENT_TYPES_ASSOC[type] || `Неизвестный способ ${type}`;
}
//#endregion


//#region Provided Services
export type ProvideServicesBodyItemsGroup = Dictionary<ProvideServiceBodyItem[]>;

export interface VisitTypeAndCategory {
  typeId: number;
  catId: number;
}

/**
 * Преобразует уже существующие услуги в услуги для запроса на изменение
 * 
 * @param viewProvidedServices 
 * @returns 
 */
export function convertProvidedServicesToBodyItems(viewProvidedServices: CarVisitProvideService[]): ProvideServiceBodyItem[] {
  return viewProvidedServices.map(provideService => {
    const service = pick(provideService.service, 'id');
    const doers = (provideService.doers || []).map(doer => pick(doer, 'id'));
    const discountCampaign = provideService.discountCampaign
      ? pick(provideService.discountCampaign, 'id', 'type')
      : undefined;
    const pointType = { id: get(provideService.pointType, 'id') || 0 };
    const carCategory = { id: get(provideService.carCategory, 'id') || 0 };
    const discountPercent = getProvideServiceDiscountPercent(provideService);
    
    return {
      ...pick(provideService, [
        'id',
        'qty',
        'singleItemPrice',
        'totalPrice',
        'managerReward',
      ]),

      service,
      doers,
      discountCampaign,
      pointType,
      carCategory,
      discountPercent,
    };
  });
}

/**
 * Вернет процент скидки для предоставляемой услуги
 * 
 * @param provideService 
 * @param defaultPercent 
 * @returns 
 */
export function getProvideServiceDiscountPercent(provideService: ProvideServiceBodyItem|CarVisitProvideService, defaultPercent?: number): number|undefined {
  let percent = provideService.discountPercent
    || get(provideService, 'discountСampaign.percent', defaultPercent);

  if (percent) {
    percent = Math.max(0, Math.min(100, percent));
  }

  return percent;
}

/**
 * Объединяет предоставляемые услуги убирая дублирование услуг
 * 
 * @deprecated более в данном методе нет необходимости
 * 
 * @param providedServices 
 * @returns 
 */
export function mergeProvidedServicesBodyItems(...providedServices: ProvideServiceBodyItem[][]) {
  let mergedProvidedServices: ProvideServiceBodyItem[] = [];

  // { <service.id> => <body_item>, ... }
  let providedServicesMap: Record<string, ProvideServiceBodyItem> = {};

  flatten(providedServices).forEach(provideService => {
    // Уникальными услуги считаются только если по мимо идентификатора
    // у них указана и одинаковая стоимость за единицу услуги
    const serviceKey = `${provideService.service.id}_${provideService.singleItemPrice}`;

    // Услуга уже существует и ее необходимо объединить
    if (providedServicesMap[serviceKey]) {
      let existingProvideService = providedServicesMap[serviceKey];

      if (!existingProvideService.id && provideService.id) {
        existingProvideService.id = provideService.id;
      }

      existingProvideService.qty = (existingProvideService.qty || 1) + (provideService.qty || 1);
      if (!existingProvideService.discountCampaign && provideService.discountCampaign) {
        existingProvideService.discountCampaign = { ...provideService.discountCampaign };
      }

      if (!existingProvideService.discountPercent) {
        existingProvideService.discountPercent = getProvideServiceDiscountPercent(provideService);
      }

      const coofDiscount = existingProvideService.discountPercent ? (1 - existingProvideService.discountPercent / 100) : 1;
      existingProvideService.totalPrice = (existingProvideService.singleItemPrice * existingProvideService.qty * coofDiscount);
    }
    
    // Новую услугу просто добавляем в список и индексируем для быстрого доступа
    else {
      const newProvideService = { ...provideService };
      mergedProvidedServices.push(newProvideService);
      providedServicesMap[serviceKey] = newProvideService;
    }
  });

  return mergedProvidedServices;
}

/**
 * Обработка оказанных услуг перед добавлением или обновлением
 * 
 * @param providedServices 
 * @returns 
 */
export function prepareProvidedServicesBodyItems(providedServices: ProvideServiceBodyItem[]): ProvideServiceBodyItem[] {
  return providedServices.map(provideServiceItem => {
    let prepareProvideService = cloneDeep(provideServiceItem);

    // fix: При реадактиировании если нет передать discountCampaign: null, то она не удалиться
    if (prepareProvideService.discountCampaign === undefined) {
      prepareProvideService.discountCampaign = null;
    }
    
    // Процент скидки, без скидочной кампании необходимо удалить
    if (!prepareProvideService.discountCampaign) {
      delete prepareProvideService.discountPercent;
    }

    const discountPercent = getProvideServiceDiscountPercent(prepareProvideService);
    const discountCoof = discountPercent ? (1 - discountPercent / 100) : 1;

    prepareProvideService.discountPercent = discountPercent || null; // NULL - обязательно, чтобы удалить скидку при редактировании
    prepareProvideService.totalPrice = provideServiceItem.singleItemPrice * provideServiceItem.qty * discountCoof;
    
    return prepareProvideService;
  });
}

/**
 * Объединит оказываемые услуги в группы по [{типу} → {категории}] => [ оказываемые услуги, ... ]
 * 
 * @param bodyItems 
 * @returns 
 */
export function mapProvideServicesBodyItemsForGroup(bodyItems: ProvideServiceBodyItem[]): ProvideServicesBodyItemsGroup {
  return groupBy(bodyItems, item => generateKeyProvideService(item.pointType.id, item.carCategory.id));
}

/**
 * Преобразует группы в общий список
 * 
 * @param groups 
 * @returns 
 */
export function provideServicesBodyGroupToList(groups: ProvideServicesBodyItemsGroup): ProvideServiceBodyItem[] {
  return flatten(Object.values(groups));
}

/**
 * Создаст ключь для оказываемых услуг
 * 
 * NOTE: Мало что делает, нужен просто, чтобы не запустаться
 * в последовательности при формировании индексов для групп оказываемых услуг.
 * 
 * @param pointTypeId идентификатор типа
 * @param categoryId идентификатор категории
 * @param serviceId идентификатор услуги, не нужно указывать, если формируется
 *                  ключ для группы с оказываемыми услугами (ProvideServicesBodyItemsGroup)
 * @returns 
 */
export function generateKeyProvideService(pointTypeId: string|number, categoryId: string|number, serviceId?: string|number|null) {
  return serviceId
    ? `${pointTypeId}_${categoryId}_${serviceId}`
    : `${pointTypeId}_${categoryId}`;
}

/**
 * Вернет объект, только с указанными {типами и категориями}
 * 
 * @param groups группы оказываемых услуг
 * @param values значения которые будут осталвены
 * @returns
 */
export function filterByIssetTypesAndCategories(groups: ProvideServicesBodyItemsGroup, values: VisitTypeAndCategory[]): ProvideServicesBodyItemsGroup {
  let filteredGroups: ProvideServicesBodyItemsGroup = {};

  for (let item of values) {
    const groupKey = generateKeyProvideService(item.typeId, item.catId);
    if (groups[groupKey]) {
      filteredGroups[groupKey] = groups[groupKey];
    }
  }

  return filteredGroups;
}

export interface VisitServiceAndCategory {
  serviceId: number;
  categoryId: number;
}

export interface VisitTimingsForServiceAndCategory {
  serviceId: number;
  categoryId: number;
  time: number;
}

export function getServiceAndCategoryCollection(providedServices?: CarVisitProvideService[]): VisitServiceAndCategory[] {
  if (!providedServices) return [];

  return providedServices.map(provideServiceItem => {
    return {
      categoryId: provideServiceItem.carCategory?.id || 0,
      serviceId: provideServiceItem.service.id || 0,
    };
  });
}
//#endregion

//#region Group
export interface VisitsArchiveGroupInfo {
  datetime?: Date;
  title: string;
  items: CarVisitCollectionItem[];
  weight: number;
}

export function visitsGroupByDate(visits: CarVisitCollectionItem[]): VisitsArchiveGroupInfo[] {
  const groupsAssoc = groupBy(visits, visit => {
    const date = visit.finishedDate || visit.creationDate;
    return date ? moment(date).format('D MMMM YYYY') : '???';
  });

  const groups: VisitsArchiveGroupInfo[] = Object.entries(groupsAssoc).map(([title, items]) => {
    const firstDatetimeRaw = items[0]?.finishedDate || items[0]?.creationDate;
    const datetime = firstDatetimeRaw ? new Date() : undefined;
    const weight = datetime ? datetime.getTime() : 0;

    return { title, items, datetime, weight };
  });

  return groups.sort((a, b) => a.weight - b.weight);
}
//#endregion

/**
 * Вернет последнее фото визита
 */
export function getLastFrameImage(visit: CarVisitExt, def?: string) {
  if (visit.frames?.length) {
    const frame = visit.frames[visit.frames.length - 1]; // Последний
    return frame.image.thumbUrl || def;
  }

  return def;
}
