
import { useStore } from '@/composables/useApp';
import { ProvideServiceBodyItem } from '@/repositories/Models/CarVisit';
import { isEmpty } from 'lodash';
import ServiceProvideItem from './ServiceProvideItem.vue';
import CollapseTransition from '@/components/core/CollapseTransition.vue';
import { useServicesCategoriesContext } from '@/composables/useServicesCategoriesContext';
import { ServiceByContextQuery } from '@/repositories/Models/Service';
import { ProvideServiceItemPart } from '@/composables/provideServices';

import {
  defineComponent,
  PropType,
  ref,
  watch,
  toRaw,
  toRef,
} from 'vue';

export default defineComponent({
  components: {
    ServiceProvideItem,
    CollapseTransition,
  },

  emits: ['update:showContent', 'update:provided', 'toggle-services-group'],

  props: {
    provided: null,
    context: {
      type: Object as PropType<ServiceByContextQuery>,
      required: true,
    },
    searchWords: {
      type: String,
      required: false,
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    shortTitle: {
      type: Boolean,
      default: false,
    },
    isCollapsedContent: {
      type: Boolean,
      default: false,
    },
    showContent: {
      type: Boolean,
      default: true,
    }
  },

  setup(props, { emit }) {
    const store = useStore();

    //#region Load, search and view list
    const titleGroup = store.point.getCategoryBreadcrumbsComputed(props.context.categoryId, props.shortTitle);

    const {
      servicesCategoriesFiltered,
      loadServicesCategoriesByContext,
    } = useServicesCategoriesContext(toRef(props, 'searchWords'));

    const loadAction = () => loadServicesCategoriesByContext({
      categoryId: props.context.categoryId,
      groupId: props.context.groupId || 0,
      carId: props.context.discountAccountId,
    });
    
    const showContentItems = ref(props.isCollapsedContent ? props.showContent : true);
    watch(() => props.showContent, val => showContentItems.value = !!val);
    watch(() => props.isCollapsedContent, () => {
      if (props.isCollapsedContent === false && showContentItems.value === false) {
        showContentItems.value = true;
        emit('update:showContent', showContentItems.value);
      }
    });

    function toggleContentItems() {
      if (!props.isCollapsedContent) return;

      showContentItems.value = !showContentItems.value;
      emit('update:showContent', showContentItems.value);
    }
    //#endregion Load, search and view list

    //#region Value
    // Ключем является идентификатор услуги
    const servicesSelected = ref<Record<string, ProvideServiceItemPart|undefined>>({});

    // Данное значение используется для блокировки циклического обновления
    let lastProvidedServices: ProvideServiceBodyItem[] = [];

    function mergeProvided() {
      if (isEmpty(props.provided) || !Array.isArray(props.provided)) {
        if (!isEmpty(servicesSelected.value)) {
          servicesSelected.value = {};
        }
        
        return;
      }

      if (lastProvidedServices === toRaw(props.provided)) {
        return; // stop recursion
      }

      let servicesMap: Record<string, ProvideServiceItemPart> = {};
      for (let provideService of props.provided as ProvideServiceItemPart[]) {
        servicesMap[`${provideService?.service?.id}`] = provideService;
      }

      servicesSelected.value = servicesMap;
    }

    function emitProvided() {
      const servicesMap = servicesSelected.value;
      let services: ProvideServiceBodyItem[] = [];

      for (let serviceId in servicesMap) {
        if (!servicesMap[serviceId]) continue;

        const provideService: ProvideServiceBodyItem = {
          doers: [], // Будут добавлены на след шаге

          ...servicesMap[serviceId] as ProvideServiceItemPart,
          
          carCategory: {
            id: props.context.categoryId,
          },
          pointType: {
            id: props.context.typeId
          },
        };

        services.push(provideService);
      }

      lastProvidedServices = services;
      emit('update:provided', services);
    }

    watch(() => props.provided, mergeProvided, { deep: true, immediate: true });
    watch(servicesSelected, emitProvided, { deep: true });
    //#endregion Value
    
    return {
      loadAction,
      servicesCategoriesFiltered,
      servicesSelected,
      titleGroup,
      showContentItems,
      toggleContentItems,
    };
  },
});
