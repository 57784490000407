import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ion-padding-horizontal ion-padding-top"
}
const _hoisted_2 = {
  key: 0,
  class: "ion-text-center text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header = _resolveComponent("app-header")!
  const _component_c_card_header = _resolveComponent("c-card-header")!
  const _component_services_popular_card = _resolveComponent("services-popular-card")!
  const _component_visits_archive_groups_by_date = _resolveComponent("visits-archive-groups-by-date")!
  const _component_c_content_loading = _resolveComponent("c-content-loading")!
  const _component_tablet_side_layout = _resolveComponent("tablet-side-layout")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_app_footer = _resolveComponent("app-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_app_header, { title: _ctx.pageTitle }, null, 8, ["title"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_tablet_side_layout, null, {
            side: _withCtx(() => [
              (_ctx.carId)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_c_card_header, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('views.visits_car_history.popular_services_card_title')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_services_popular_card, { "car-id": _ctx.carId }, null, 8, ["car-id"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_c_card_header, { class: "ion-padding-top ion-padding-horizontal" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('views.visits_car_history.section_visits_title')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_c_content_loading, { action: _ctx.loadVisitsHistory }, {
                default: _withCtx(({ loading }) => [
                  (!loading && _ctx.visits.length === 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('views.visits_car_history.visits_not_found')), 1))
                    : _createCommentVNode("", true),
                  _createVNode(_component_visits_archive_groups_by_date, {
                    visits: _ctx.visits,
                    "col-sizes": {
              sizeXl: 4,
              sizeLg: 6,
              sizeSm: 12,
            }
                  }, null, 8, ["visits"])
                ]),
                _: 1
              }, 8, ["action"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_app_footer)
    ]),
    _: 1
  }))
}