
import { useStore } from '@/composables/useApp';
import { PointTypeCategoryItem, ThisClientPointTypeItem } from '@/repositories/Models/Point';
import { defineComponent, ref, DeepReadonly, PropType, computed, ComputedRef } from 'vue';
import { VisitTypeAndCategory } from '@/helpers/visit';
import { keyBy, pick } from 'lodash';
import { useModalNav } from './ServicesAddCategoryGroupModal.vue';
import SelectProvidedServicesView from './SelectProvidedServicesView.vue';
import { useMetrica } from '@/composables/useMetrica';

export default defineComponent({
  inheritAttrs: false,

  props: {
    typeId: {
      type: [String, Number] as PropType<string|number>,
      required: true,
    },
    carId: {
      type: String,
      required: false,
    },
    setCategory: {
      type: Function as PropType<(cat: PointTypeCategoryItem, typeId: number) => void>,
      required: false,
    },
    selectedTypeAndCategoriesCRef: {
      type: Object as PropType<ComputedRef<VisitTypeAndCategory[]>>,
      default: () => computed(() => [] as VisitTypeAndCategory[]),
    },
    action: {
      type: String,
      required: false,
    }
  },

  setup(props) {
    const store = useStore();
    const nav = useModalNav();
    const { emitEvent } = useMetrica();

    const categoriesSelectedList = computed(() => keyBy(props.selectedTypeAndCategoriesCRef.value, 'catId'));
    const pointType = ref<DeepReadonly<ThisClientPointTypeItem|null>>(null);
    const categories = computed<DeepReadonly<PointTypeCategoryItem[]>>(() => {
      return pointType.value?.carCategories || [];
    });
    const categorySelected = ref<DeepReadonly<PointTypeCategoryItem>|null>(null);

    function selectCategory(category: DeepReadonly<PointTypeCategoryItem>) {
      emitEvent('visit/edit/category', pick(category, ['id', 'name']));

      categorySelected.value = category;
      props.setCategory?.call(null, category, +props.typeId);

      if (props.action === 'selectServices') {
        nav.pushView(SelectProvidedServicesView, {
          catId: category.id,
          typeId: props.typeId,
          carId: props.carId,
        });
      }
    }

    async function loadCatagory() {
      pointType.value = await store.point.filterTypePoint(+props.typeId) || null;
    }

    return {
      selectCategory,
      loadCatagory,
      categorySelected,
      categories,
      categoriesSelectedList,
      back: () => nav.backView(),
    };
  }
});
