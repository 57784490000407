
import { defineComponent, PropType, computed, ref } from 'vue';

export default defineComponent({
  props: {
    cols: {
      type: Array as PropType<any[]>,
      required: true,
    },
    rows: {
      type: Array as PropType<any[]>,
      required: true,
    }
  },

  setup(props) {
    const headerScrollRef = ref<HTMLDivElement|null>(null);
    const sheduleStyles = computed(() => ({
      '--shedule-grid-cols-count': props.cols.length,
      '--shedule-grid-rows-count': props.rows.length,
    }));

    function onGridScroll(ev: Event) {
      if (headerScrollRef.value && ev.target) {
        headerScrollRef.value.scrollLeft = (ev.target as HTMLDivElement).scrollLeft;
      }
    }

    return {
      onGridScroll,
      headerScrollRef,
      sheduleStyles,
    };
  }
});
