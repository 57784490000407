import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ion-padding-horizontal ion-padding-end" }
const _hoisted_2 = { class: "c-select-provided-services__footer-total" }
const _hoisted_3 = { class: "c-select-provided-services__footer-total-text" }
const _hoisted_4 = { class: "c-select-provided-services__footer-total-price" }
const _hoisted_5 = { class: "core-modal-footer-toolbal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_c_modal_action_header = _resolveComponent("c-modal-action-header")!
  const _component_services_by_category_group = _resolveComponent("services-by-category-group")!
  const _component_c_modal_content = _resolveComponent("c-modal-content")!
  const _component_cc = _resolveComponent("cc")!
  const _component_ion_footer = _resolveComponent("ion-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_c_modal_action_header, { tag: "ion-header" }, {
      start: _withCtx(() => [
        _createVNode(_component_ion_button, {
          class: "c-modal-header-back-button",
          color: "transparent-text",
          onClick: _ctx.back
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, { name: "chevron-back-outline" })
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createTextVNode(" " + _toDisplayString(_ctx.title || _ctx.$t('components.add_category_modal.view_provided_services_title_default')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_c_modal_content, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_services_by_category_group, {
            "show-title": false,
            context: {
          categoryId: _ctx.catId,
          typeId: _ctx.typeId,
          groupId: _ctx.groupId,
          discountAccountId: _ctx.carId,
        },
            provided: _ctx.currentGroupValues,
            "onUpdate:provided": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentGroupValues) = $event))
          }, null, 8, ["context", "provided"])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_ion_footer, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('components.add_category_modal.view_provided_services_label_total')), 1),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_cc, { price: _ctx.total }, null, 8, ["price"])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_ion_button, {
            color: "primary",
            expand: "block",
            shape: "round",
            onClick: _ctx.apply
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('components.add_category_modal.view_provided_services_btn_next')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    })
  ], 64))
}